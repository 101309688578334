<template>
  <div
    ref="konvaDiv"
    class="konva-container"
    style="overflow: hidden; position: relative"
  ></div>
  <div
    role="toolbar"
    class="btn-group"
    style="position: absolute; top: 1rem; right: 1rem; z-index: 2"
  >
    <button class="btn btn-outline-secondary" @click="zoomOut">
      <b-icon-dash />
    </button>
    <button class="btn btn-outline-secondary" @click="zoomIn">
      <b-icon-plus />
    </button>
  </div>
</template>

<script setup lang="ts">
import { BIconDash, BIconPlus } from "bootstrap-icons-vue";
import Konva from "konva";
import {
  computed,
  onMounted,
  Ref,
  ref,
  toRefs,
  watch,
  WatchStopHandle,
} from "vue";

import { diagramLayer, roomsLayer, stageFromDiagram, zoom } from "./canvas";
import { AbodeFloorDiagram } from "./types";

interface Props {
  floorPlan: AbodeFloorDiagram;
  modelValue?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
});

interface Emits {
  (e: "update:modelValue", payload: number | null): void;
}

const emit = defineEmits<Emits>();

const selected = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});
const konvaDiv = ref(null) as unknown as Ref<HTMLDivElement>;
let endWatch: WatchStopHandle;
let stage: Konva.Stage;

const drawFloorPlan = (stage: Konva.Stage) => {
  const bgLayer = diagramLayer(props.floorPlan);
  const { layer: rLayer, endWatch: eWatch } = roomsLayer(
    props.floorPlan,
    selected
  );
  stage.add(bgLayer).add(rLayer).draw();
  return eWatch;
};

onMounted(() => {
  stage = stageFromDiagram(konvaDiv.value, props.floorPlan);
  endWatch = drawFloorPlan(stage);
});

watch(toRefs(props).floorPlan, () => {
  endWatch();
  stage.destroyChildren();

  endWatch = drawFloorPlan(stage);
  selected.value = null;
});

const zoomIn = () => {
  zoom(stage, 1);
};

const zoomOut = () => {
  zoom(stage, -1);
};
</script>
