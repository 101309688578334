<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :optional="optional"
    :style="divStyle"
  >
    <p-dropdown
      ref="innerDropdown"
      style="width: 100%"
      :class="{
        'p-invalid is-invalid': showError,
      }"
      v-bind="$attrs"
      :input-id="innerId"
      :model-value="model"
      @update:model-value="onChange"
      @blur="onBlur"
    />
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import PDropdown from "primevue/dropdown";
import { computed, nextTick, Ref, ref, toRefs, watch } from "vue";

import { computedModel } from "@/utilities";
import { useField, Validators } from "@/utilities/validations";

type ModelValue = NonNullable<unknown> | null;

interface Props {
  name: string;
  id?: string;
  modelValue: ModelValue;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;
  divStyle?: string | object;

  // Validation Props
  rules?: Validators<ModelValue>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  rules: () => [],
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();

const innerDropdown = ref();
const openDropdown = () => {
  innerDropdown.value.show();
};

defineExpose({ openDropdown });

const innerId = computed(() => props.id || props.name);

const { modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const rules = toRefs(props).rules as Ref<Validators<ModelValue>>;
const { touched, errorMessage, validate, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

watch(rules, () => {
  validate();
});

const onChange = (value: unknown) => {
  model.value = value as ModelValue;
  nextTick(() => {
    touched.value = true;
    validate();
  });
};

const onBlur = () => {
  touched.value = true;
};
</script>
