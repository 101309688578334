import { DateTime } from "luxon";
import { isRef, Ref } from "vue";

export interface FuturePlansSurvey {
  name: string;
  statementText: string;
  earliestCheckoutDt: string;
  latestCheckoutDt: string;
  type: "End of Year" | "End of Term";
}

export interface FuturePlanOption {
  [name: string]: unknown;
  name: string;
  value: number;
  feedback: string | null;
  promptForDetails: boolean;
  planCategory: "Resident" | "Commuter" | "Departing";
}

export namespace FuturePlans {
  export interface EndOfTerm {
    responseType: "End of Term";
    stayingInRoom: boolean | null;
    changeHousing: boolean | null;
    housingChangeRequests: string;
    expectedCheckoutDt: DateTime | null;
    futurePlanOption: FuturePlanOption | null;
    forwardingAddress: {
      addressType: "Home" | "Summer" | "Other" | null;
      address1: string;
      address2?: string;
      city: string;
      state: string;
      postal: string;
      phone: string;
      email: string;
    };
    planDetail?: string;
  }

  export interface EndOfYear {
    responseType: "End of Year";
    expectedCheckoutDt: DateTime | null;
    futurePlanOption: FuturePlanOption | null;
    summerPlans: boolean | null;
    summerStartDt: DateTime | null;
    summerEndDt: DateTime | null;
    summerReason: string | null;
    forwardingAddress: {
      addressType: "Summer";
      address1: string;
      address2?: string;
      city: string;
      state: string | null;
      postal: string;
      phone: string;
      email: string;
    };
    planDetail?: string;
  }

  export type Form = EndOfTerm | EndOfYear;
}

export function isEndOfTerm(
  form: Ref<FuturePlans.Form>
): form is Ref<FuturePlans.EndOfTerm>;
export function isEndOfTerm(
  form: FuturePlans.Form
): form is FuturePlans.EndOfTerm;
export function isEndOfTerm(
  form: Ref<FuturePlans.Form> | FuturePlans.Form
): form is Ref<FuturePlans.EndOfTerm> | FuturePlans.EndOfTerm {
  if (isRef(form)) {
    return form.value.responseType === "End of Term";
  }

  return form.responseType === "End of Term";
}

export function isEndOfYear(
  form: Ref<FuturePlans.Form>
): form is Ref<FuturePlans.EndOfYear>;
export function isEndOfYear(
  form: FuturePlans.Form
): form is FuturePlans.EndOfYear;
export function isEndOfYear(
  form: Ref<FuturePlans.Form> | FuturePlans.Form
): form is Ref<FuturePlans.EndOfYear> | FuturePlans.EndOfYear {
  if (isRef(form)) {
    return form.value.responseType === "End of Year";
  }

  return form.responseType === "End of Year";
}

export function newEndOfTermForm(): FuturePlans.EndOfTerm {
  return {
    responseType: "End of Term",
    stayingInRoom: null,
    changeHousing: null,
    housingChangeRequests: "",
    expectedCheckoutDt: null,
    futurePlanOption: null,
    forwardingAddress: {
      addressType: null,
      address1: "",
      city: "",
      state: "",
      postal: "",
      phone: "",
      email: "",
    },
  };
}

export function newEndOfYearForm(): FuturePlans.EndOfYear {
  return {
    responseType: "End of Year",
    expectedCheckoutDt: null,
    futurePlanOption: null,
    summerPlans: null,
    forwardingAddress: {
      addressType: "Summer",
      address1: "",
      address2: "",
      city: "",
      state: null,
      postal: "",
      phone: "",
      email: "",
    },
  } as FuturePlans.EndOfYear;
}
