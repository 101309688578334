<template>
  <card-to-mobile-layout title="Destination">
    <div class="row">
      <base-textfield
        v-model="destination"
        name="destination"
        label="Name of Host"
        div-class="p-md-6 p-col-12 mb-3"
        :rules="rules.destination"
        :form-name="formName"
      />
      <base-textfield
        v-model="hostRelationship"
        name="hostRelationship"
        label="Relationship of Host"
        div-class="col-md-6 col-12 mb-3"
        :rules="rules.hostRelationship"
        :form-name="formName"
      />
      <base-textfield
        v-model="hostphone"
        name="hostphone"
        label="Host Phone"
        div-class="col-12 col-md-6 mb-3"
        :rules="rules.hostphone"
        :form-name="formName"
      />
      <base-textfield
        v-model="otherphone"
        name="otherphone"
        label="Other Phone"
        optional
        div-class="col-12 col-md-6 mb-3"
        :rules="rules.otherphone"
        :form-name="formName"
      />
      <base-textfield
        v-model="address"
        name="address"
        label="Address"
        div-class="col-12 mb-3"
        :rules="rules.address"
        :form-name="formName"
      />
      <base-textfield
        v-model="city"
        div-class="col-12 col-md-6 mb-3"
        label="City"
        name="city"
        :rules="rules.city"
        :form-name="formName"
      />
      <base-dropdown
        v-model="state"
        v-test="'state'"
        optional
        div-class="col-12 col-md-3  mb-3"
        label="State"
        name="state"
        style="width: 100%"
        option-label="label"
        option-value="value"
        :options="states"
        show-clear
      />
      <base-textfield
        v-model="postal"
        div-class="col-md-3 mb-3"
        optional
        label="ZIP"
        name="postal"
      />
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { toRefs } from "vue";

import { computedModels } from "@/utilities";

import * as HolidayLeave from "../types/holidayLeave";
import { Rules } from "../useValidations";

interface Props {
  modelValue: HolidayLeave.Other;
  states: { label: string; value: string }[];
  rules: Rules;
  formName: string;
}

interface Emits {
  (e: "update:modelValue", value: HolidayLeave.Other): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const modelValue = toRefs(props).modelValue;
const {
  destination,
  hostRelationship,
  hostphone,
  otherphone,
  address,
  city,
  state,
  postal,
} = computedModels(modelValue, emit);
</script>
