<template>
  <div class="card mb-4">
    <div class="card-body">
      <h2 class="card-title">Emergency Contact Information</h2>
      <form @submit.prevent="submit">
        <contact-info-form
          v-model="form"
          :rules="rules"
          :form-name="formName"
          :states="states"
        />
        <div class="d-flex justify-content-center">
          <base-submit-button
            class="btn btn-primary"
            text="Submit Updated Contact Information"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: "ContactInfoEdit",
};
</script>

<script setup lang="ts">
import { omit, pipe, update } from "lodash/fp";
import { computed, ref } from "vue";

import { contactInfo } from "@/api";
import { stripPhoneNumber } from "@/utilities";
import { useForm } from "@/utilities/validations";

import {
  ContactInfoForm,
  ContactInformation,
  EmergencyContact,
  rules as rulesFn,
} from "../components/ContactInfoForm";

interface Props {
  contactInfo: ContactInformation;
  states: { label: string; value: string }[];
}

const props = defineProps<Props>();

function defaultContact(): EmergencyContact {
  return {
    contactName: "",
    contactRelation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postal: "",
    phone1Type: null,
    phone1: "",
    phone2Type: null,
    phone2: "",
    phone3Type: null,
    phone3: "",
  };
}

const form = ref(props.contactInfo as ContactInformation);

if (props.contactInfo.contact1 == null) {
  props.contactInfo.contact1 = defaultContact();
}

if (props.contactInfo.contact2 == null) {
  props.contactInfo.contact2 = defaultContact();
}

const rules = computed(() => rulesFn(form.value));

const formName = "contact-form";
const { submit: submitFn } = useForm(formName);

const sanatize = pipe(
  (data) => (data.contact2.contactName ? data : omit(["contact2"], data)),
  update("contact1.phone1", stripPhoneNumber),
  update("contact1.phone2", stripPhoneNumber),
  update("contact1.phone3", stripPhoneNumber),
  (data) =>
    data.contact2
      ? pipe(
          update("contact2.phone1", stripPhoneNumber),
          update("contact2.phone2", stripPhoneNumber),
          update("contact2.phone3", stripPhoneNumber)
        )(data)
      : data
);

const submit = submitFn(async () => {
  await contactInfo.update({
    params: { id: form.value.id },
    data: sanatize(form.value),
  });

  window.location.assign("/students");
});
</script>
