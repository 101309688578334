import { mapValues } from "lodash";
import { DateTime } from "luxon";

import { fromRailsDateTime } from "@/utilities";

import { Props } from "./Index.vue";
import { Timeslot } from "./types";

const mapObject = <K extends string | number | symbol, V, NV>(
  obj: Record<K, V>,
  fn: (val: V) => NV
): Record<K, NV> => {
  const mapped = Object.entries<V>(obj).map(([k, v]) => [k, fn(v)]);

  return Object.fromEntries(mapped);
};

/**
 *  Normalize input from props, such as converting date strings to luxon dates
 */
export function useFromRails(props: Props) {
  const timeslots: Record<number, Record<number, Timeslot>> = mapObject(
    props.timeslots,
    (slots) =>
      mapValues(slots, (slot) => ({
        ...slot,
        startDt: fromRailsDateTime(slot.startDt),
        endDt: fromRailsDateTime(slot.endDt),
      }))
  );

  const initialDate: DateTime = fromRailsDateTime(props.initialDate);

  return { timeslots, initialDate };
}
