// JsFromRoutes CacheKey f96d8d109b342c001ce903bd5fd7df08
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const applications = {
  list: definePathHelper("get", "/applications"),
  create: definePathHelper("post", "/applications"),
  new: definePathHelper("get", "/applications/new"),
  edit: definePathHelper("get", "/applications/:id/edit"),
  update: definePathHelper("patch", "/applications/:id"),
};
