<template>
  <div class="d-flex flex-column-reverse flex-md-row align-items-md-center">
    <div class="progress">
      <div
        class="progress-bar"
        role="progressbar"
        :aria-valuenow="progress"
        aria-valuemin="0"
        aria-valuemax="100"
        :style="{ width: `${progress}%` }"
      ></div>
    </div>
    <div class="mb-2 ms-md-2 mb-md-0">
      <span class="tw-bold">{{ name }}: </span>
      {{ progressText }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  name: string;
  total: number;
  required: number;
  progress: number;
}

const props = defineProps<Props>();

const progressText = computed(
  () => `${props.total} / ${props.required} (${props.progress}%)`
);
</script>
<style scoped lang="scss">
.progress {
  max-width: 300px;
  width: 100%;
  height: 28px;
}
</style>
