<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :style="divStyle"
    :optional="optional"
  >
    <div
      v-if="append"
      key="append"
      :class="{ 'is-invalid': showError, 'input-group': true }"
    >
      <input
        :id="innerId"
        :value="model || ''"
        :name="name"
        v-bind="$attrs"
        :class="{ 'is-invalid': showError, 'form-control': true }"
        autocomplete="off"
        @blur="onBlur"
        @change="onChange"
        @input="
          updateModel($event);
          onInput();
        "
      />
      <slot :errors="[errorMessage]" name="append"></slot>
    </div>
    <template v-else>
      <input
        :id="innerId"
        key="normal-input"
        :value="model || ''"
        :name="name"
        v-bind="$attrs"
        :class="{ 'is-invalid': showError, 'form-control': true }"
        autocomplete="off"
        @blur="onBlur"
        @change="onChange"
        @input="
          updateModel($event);
          onInput();
        "
      />
    </template>
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { debounce } from "lodash";
import {
  computed,
  ComputedRef,
  onBeforeUpdate,
  onUnmounted,
  Ref,
  toRefs,
  useSlots,
  watch,
} from "vue";

import { computedModel } from "@/utilities";
import { useField, Validators } from "@/utilities/validations";

type ModelValue = string | null;

interface Props {
  name: string;
  id?: string;
  modelValue?: ModelValue;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;
  divStyle?: string | object;

  // Validation Props
  rules?: ComputedRef<Validators<ModelValue>> | Validators<ModelValue>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  modelValue: null,
  rules: () => [],
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();
const slots = useSlots();

const innerId = computed(() => props.id || props.name);
const append = computed(() => slots.append !== undefined);

const { modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const rules = toRefs(props).rules as Ref<Validators<ModelValue>>;
const { touched, errorMessage, validate, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

watch(rules, () => {
  validate();
});

const onInput = debounce(() => {
  if (showError.value) {
    validate();
  }
}, 100);

const updateModel = (ev: Event) => {
  model.value = (ev.target as HTMLInputElement).value;
};

const onChange = () => {
  touched.value = true;
  validate();
};

const onBlur = () => {
  touched.value = true;
};
</script>
