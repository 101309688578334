<template>
  <div v-if="label" class="v-field">
    <label class="form-label">
      {{ label }}
    </label>
    <slot />
  </div>
  <slot v-else />
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VField",
  props: {
    label: { default: "", type: String },
  },
});
</script>

<style lang="scss">
div.v-field.optional {
  label::after {
    content: " optional";
    color: #777;
    font-size: smaller;
  }
}
</style>
