import { mapValues } from "lodash";
import { provide, Ref, ref, UnwrapRef } from "vue";

import { Field } from "./useField";

export interface Form {
  reset: () => void;
  validate: () => Promise<boolean>;
  setFieldErrorMessage: (fieldName: string, errorMessage: string) => void;
  submit: (
    successFn: () => Promise<void> | void,
    errorFn?: (errors: Record<string, string>) => Promise<void> | void
  ) => () => void;
  validateField: (fieldName: string) => Promise<boolean>;
  formName: string;
}

export const useForm = (name: string): Form => {
  const form: Ref<Record<string, UnwrapRef<Field>>> = ref({});

  provide(name, form);

  const reset = () => {
    Object.keys(form.value).forEach((field) => {
      form.value[field].touched = false;
    });
  };

  const validate = async () => {
    Object.keys(form.value).forEach((field) => {
      form.value[field].touched = true;
    });

    let valid = true;
    for (const field of Object.values(form.value)) {
      if (await field.validate()) {
        valid = valid && true;
        continue;
      }

      valid = valid && false;
    }

    return valid;
  };

  const validateField = async (fieldName: string) => {
    form.value[fieldName].touched = true;
    return await form.value[fieldName].validate();
  };

  const setFieldErrorMessage = (fieldName: string, errorMessage: string) => {
    const field = form.value[fieldName];

    if (field) {
      field.errorMessage = errorMessage;
    }
  };

  const submit: Form["submit"] = (successFn, errorFn) => async () => {
    if (await validate()) {
      await successFn();
    } else {
      if (errorFn) {
        await errorFn(mapValues(form.value, (v) => v.errorMessage));
      }
    }
  };

  return {
    reset,
    validate,
    setFieldErrorMessage,
    submit,
    validateField,
    formName: name,
  };
};
