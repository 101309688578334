import { omit, pick, pipe, update } from "lodash/fp";
import { useToast } from "primevue/usetoast";
import { Ref } from "vue";

import { contactInfo, moveInWizard, students } from "@/api";
import { stripPhoneNumber } from "@/utilities";
import { useForm } from "@/utilities/validations";

import { MoveInWizardForm, Step } from "./types";

export function useSubmission(
  studentId: number,
  form: Ref<MoveInWizardForm>,
  step: Ref<Step>,
  next: () => void,
  loading: { general: boolean; initial: boolean },
  fetchData: (step: Step) => Promise<void>
) {
  const toast = useToast();
  const formName = "move-in-form";
  const { submit: handleSubmit } = useForm(formName);

  const santatizeContactInfo = pipe(
    pick(["medicalNotes", "contact1", "contact2"]),
    (data) =>
      data.contact2.contactName
        ? pipe(
            update("contact2.phone1", stripPhoneNumber),
            update("contact2.phone2", stripPhoneNumber),
            update("contact2.phone3", stripPhoneNumber)
          )(data)
        : omit(["contact2"])(data),
    update("contact1.phone1", stripPhoneNumber),
    update("contact1.phone2", stripPhoneNumber),
    update("contact1.phone3", stripPhoneNumber)
  );

  const sanatizeStudentInfo = pipe(
    pick(["email2", "cellphone", "smsPermission"]),
    update("cellphone", stripPhoneNumber)
  );

  const submit = handleSubmit(async () => {
    try {
      switch (step.value.name) {
        case "Contact":
          Promise.all([
            contactInfo.update({
              params: {
                id: studentId,
              },
              data: santatizeContactInfo(form.value),
            }),
            students.update({
              params: {
                id: studentId,
              },
              data: sanatizeStudentInfo(form.value),
            }),
          ]);
          form.value = null;
          break;
        case "MoveIn":
          await moveInWizard.create({
            data: {
              moveInForm: form.value,
            },
          });
          break;
      }
      step.value.completed = true;
      loading.general = true;
      next();
      await fetchData(step.value);
    } catch (e) {
      toast.add({
        severity: "error",
        summary: "Error",
        detail: "Something seems to have gone wrong, please contact your dean",
        life: 3000,
      });
    } finally {
      loading.general = false;
    }
  });

  return { submit, formName, form };
}
