import axios from "axios";
import { useToast } from "primevue/usetoast";
import { Ref, ref } from "vue";

import {
  ApiInjectionKey,
  safeInject,
  stripPhoneNumber,
  toRailsDateTime,
} from "@/utilities";
import { useForm } from "@/utilities/validations";

import { HolidayLeave } from "./types/holidayLeave";

export function useSubmission(
  form: Ref<HolidayLeave>,
  submitForm: ReturnType<typeof useForm>["submit"],
  setFieldErrorMessage: ReturnType<typeof useForm>["setFieldErrorMessage"]
) {
  const api = safeInject(ApiInjectionKey);

  const loading = ref(false);
  const toast = useToast();

  function showError() {
    toast.add({
      severity: "error",
      summary: "Errors in form",
      detail: "You seem to have a few errors in your form",
      life: 5000,
    });
  }

  const submit = submitForm(
    async () => {
      try {
        loading.value = true;

        const data = await api.holidayLeaveResponses.create({
          data: sanatize(form.value),
        });

        window.location = data.location;
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          const { data } = err.response;

          Object.entries(data).forEach(([key, val]) => {
            if (["leave.leaveDepart", "leave.leaveReturn"].includes(key)) {
              setFieldErrorMessage("leaveDates", (val as string[]).join(", "));
            } else {
              setFieldErrorMessage(
                key.replace("leave.", "") as any,
                (val as string[]).join(", ")
              );
            }
          });

          showError();
        }
      } finally {
        loading.value = false;
      }
    },
    () => {
      showError();
    }
  );

  return { submit, loading };
}

function sanatize(form: HolidayLeave) {
  let holidayLeaveResponse;

  switch (form.holidayPlans) {
    case "S":
      holidayLeaveResponse = {
        ...form,
      };
      break;
    case "H":
      holidayLeaveResponse = {
        holidayLeaveId: form.holidayLeaveId,
        holidayPlans: form.holidayPlans,
        leaveAttributes: {
          leaveDepart: toRailsDateTime(form.leaveDepart!),
          leaveReturn: toRailsDateTime(form.leaveReturn!),
        },
      };
      break;
    case "E":
      holidayLeaveResponse = {
        holidayLeaveId: form.holidayLeaveId,
        holidayPlans: form.holidayPlans,
        leaveAttributes: {
          leaveDepart: toRailsDateTime(form.leaveDepart!),
          leaveReturn: toRailsDateTime(form.leaveReturn!),
          destination: form.destination,
          hostRelationship: form.hostRelationship,
          address: form.address,
          city: form.city,
          state: form.state,
          postal: form.postal,
          driver: form.driver,
          transport: form.transport,
          companions: form.companions,
          reason: form.reason,
          carOwner: form.carOwner,
          ccParent: form.ccParent,
          hostphone: stripPhoneNumber(form.hostphone),
          cellphone: stripPhoneNumber(form.cellphone),
          otherphone: stripPhoneNumber(form.otherphone),
        },
      };
      break;
  }

  return holidayLeaveResponse;
}
