<template>
  <form class="leave-form" :disabled="isLoading" @submit.prevent="submit">
    <toast />
    <button class="btn btn-secondary mb-3" @click="populateHome">
      Going Home
    </button>

    <card-to-mobile-layout class="mb-4" title="Leave Dates">
      <div class="row">
        <base-date-time-picker
          key="leaveDepart"
          v-model="form.leaveDepart"
          div-class="col-12 col-sm-6"
          name="leaveDepart"
          label="Depart"
          :form-name="formName"
          :min-date="minDate"
          :max-date="maxDate"
          :rules="rules.leaveDepart as any"
          show-time
        />
        <base-date-time-picker
          key="leaveReturn"
          v-model="form.leaveReturn"
          div-class="col-12 col-sm-6"
          label="Return"
          name="leaveReturn"
          :form-name="formName"
          :min-date="form.leaveDepart"
          :max-date="maxDate"
          :rules="rules.leaveReturn as any"
          show-time
          :disabled="form.leaveDepart == null"
        />
      </div>
    </card-to-mobile-layout>
    <card-to-mobile-layout class="mb-4" title="Destination">
      <div class="row">
        <base-textfield
          v-model="form.destination"
          name="destination"
          div-class="col-sm-6 col-12 mb-3"
          label="Name of Host"
          :rules="rules.destination"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.hostRelationship"
          name="hostRelationship"
          div-class="col-sm-6 col-12 mb-3"
          label="Relationship of Host"
          :rules="rules.hostRelationship"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.hostphone"
          name="hostphone"
          div-class="col-sm-6 col-12 mb-3"
          label="Host Phone"
          :rules="rules.hostphone"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.address"
          name="address"
          div-class="col-12 mb-3"
          label="Address"
          :rules="rules.address"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.city"
          name="city"
          div-class="col-sm-6 col-12 mb-3"
          label="City"
          :rules="rules.city"
          :form-name="formName"
        />
        <base-dropdown
          v-model="form.state"
          label="State"
          name="state"
          div-class="col-sm-3 col-12 mb-3"
          option-label="label"
          option-value="value"
          :options="formOptions.states"
          show-clear
          :rules="rules.state"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.postal"
          name="postal"
          div-class="col-sm-3 col-12 optional mb-3"
          label="ZIP"
          optional
        />
      </div>
    </card-to-mobile-layout>
    <card-to-mobile-layout class="mb-4" title="Transportation">
      <div class="row">
        <base-textfield
          v-model="form.transport"
          name="transport"
          div-class="col-sm-6 col-12 mb-3"
          label="Method of transportation"
          :rules="rules.transport"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.driver"
          name="driver"
          div-class="col-sm-6 col-12 mb-3"
          label="Driver"
          optional
        />
        <base-textfield
          v-model="form.companions"
          name="companions"
          div-class="col-sm-6 col-12 mb-3"
          label="Companions"
          optional
        />
      </div>
    </card-to-mobile-layout>
    <card-to-mobile-layout class="mb-4" title="Other">
      <div class="row">
        <base-textfield
          v-model="form.cellphone"
          name="cellphone"
          div-class="col-sm-6 col-12 mb-3"
          label="Your Cell Phone"
          :rules="rules.cellphone"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.reason"
          div-class="col-sm-6 col-12 optional mb-3"
          label="Reason"
          name="reason"
          :rules="rules.reason"
          :form-name="formName"
        />
        <base-textfield
          v-model="form.ccParent"
          name="ccParent"
          div-class="col-sm-6 col-12 optional mb-3"
          label="Parent Email"
          :rules="rules.ccParent"
          :form-name="formName"
          optional
        />
      </div>
    </card-to-mobile-layout>
    <div class="d-flex justify-content-md-center mb-4">
      <base-submit-button
        :loading="isLoading"
        class="btn btn-primary flex-grow-1 flex-md-grow-0"
      />
    </div>
  </form>
</template>

<script lang="ts">
export default {
  name: "LeaveForm",
};
</script>

<script setup lang="ts">
import Toast from "primevue/toast";
import { computed, reactive, ref, watch } from "vue";

import { fromRailsDateTime } from "@/utilities";

import { FormOptions, FormType, Leave, PreviousLeave } from "./types";
import { useSubmission } from "./useSubmission";
import { useValidations } from "./useValidations";

export interface Props {
  formOptions: FormOptions;
  prevLeave?: PreviousLeave | null;
  formType: FormType;
}

const props = withDefaults(defineProps<Props>(), {
  prevLeave: null,
});

const maxDate = computed(() => fromRailsDateTime(props.formOptions.maxDate));
const minDate = computed(() => fromRailsDateTime(props.formOptions.minDate));

const form: Leave = reactive(
  props.formType === "edit" && props.prevLeave !== null
    ? {
        ...props.prevLeave,
        leaveDepart: fromRailsDateTime(props.prevLeave.leaveDepart),
        leaveReturn: fromRailsDateTime(props.prevLeave.leaveReturn),
      }
    : {
        destination: "",
        hostRelationship: "",
        address: "",
        city: "",
        state: null,
        postal: "",
        transport: "",
        ccParent: "",
        hostphone: "",
        cellphone: "",
        leaveDepart: null,
        leaveReturn: null,
        driver: "",
        companions: "",
        reason: "",
      }
);

const { rules } = useValidations(form);
const { formName, isLoading, submit } = useSubmission(form, props);

watch(
  [() => form.leaveDepart, () => form.leaveReturn],
  ([leaveDepart, leaveReturn]) => {
    if (leaveDepart && leaveReturn) {
      if (leaveDepart > leaveReturn) {
        form.leaveReturn = null;
      }
    }
  }
);

const populateHome = () => {
  form.destination = "Home";
  form.hostRelationship = "Home";
  form.address = props.formOptions.homeAddress.address;
  form.city = props.formOptions.homeAddress.city;
  form.postal = props.formOptions.homeAddress.postal;
  form.state = props.formOptions.homeAddress.state;
  form.hostphone = props.formOptions.homeAddress.hostphone;
  form.cellphone = props.formOptions.homeAddress.cellphone;
};
</script>
