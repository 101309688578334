<template>
  <section class="container-fluid">
    <Toast />
    <div class="page-header">
      <h1>Roommate Finder</h1>
      <h2 class="text-muted">{{ pool.name }}</h2>
      <hr />
    </div>
    <v-dialog
      v-model:visible="confirmation.show"
      modal
      :show-header="false"
      :style="{ overflow: 'hidden' }"
    >
      <section v-if="confirmation.student" class="card__body">
        <h1>Are you sure?</h1>
        <p style="font-size: 14px">
          Do you want to invite {{ confirmation.student.firstName }}
          {{ confirmation.student.lastName }} to be your roommate?
        </p>
      </section>
      <template #footer>
        <button
          v-if="confirmation.student"
          class="btn btn-primary"
          @click="inviteToRoommateGroup(confirmation.student)"
        >
          Yes
        </button>
        <button class="btn btn-primary" @click="confirmation.close">No</button>
      </template>
    </v-dialog>
    <button
      class="btn btn-primary d-block d-md-none"
      style="margin-bottom: 1rem"
      @click="showFullControls = true"
    >
      Filter And Sort
    </button>
    <student-more-info-dialog
      :visible="showMore.visible.value"
      :student="selectedStudent"
      @close="showMore.close"
    />
    <div class="row">
      <div class="col-xs-12">
        <div class="student-list">
          <student-card
            v-for="student in studentList"
            :key="student.id"
            :student="student"
            @show-more="showMore.open"
            @invite="confirmation.open"
          />
        </div>
      </div>
    </div>
    <aside class="finder-sidebar d-md-block d-none card">
      <div class="card-body">
        <student-controls
          v-model:selected-sort="selectedSort"
          v-model:filters="filters"
          :classification-options="classificationOptions"
          :classload-options="classloadOptions"
          :lifestyle-options="lifestyleOptions"
          :neatness-options="neatnessOptions"
          :sorting-options="sortingOptions"
        />
      </div>
    </aside>
    <sidebar
      v-model:visible="showFullControls"
      :show-close-icon="false"
      position="full"
    >
      <student-controls
        v-model:selected-sort="selectedSort"
        v-model:filters="filters"
        :classification-options="classificationOptions"
        :classload-options="classloadOptions"
        :lifestyle-options="lifestyleOptions"
        :neatness-options="neatnessOptions"
        :sorting-options="sortingOptions"
      />
      <button class="btn btn-primary" @click="showFullControls = false">
        close
      </button>
    </sidebar>
  </section>
</template>

<script lang="ts">
export default {
  name: "RoommateFinder",
};
</script>

<script setup lang="ts">
import axios from "axios";
import VDialog from "primevue/dialog";
import Sidebar from "primevue/sidebar";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { reactive, ref, watchEffect } from "vue";

import { roommateFinder } from "@/api";
import { useBreakpoints } from "@/utilities/breakpoints";

import StudentCard from "./components/StudentCard.vue";
import StudentControls from "./components/StudentControls.vue";
import StudentMoreInfoDialog from "./components/StudentMoreInfoDialog.vue";
import { Student } from "./types";
import { useFiltering } from "./useFiltering";
import { useShowMore } from "./useShowMore";

const props = withDefaults(
  defineProps<{
    pool: { name: string; id: number };
    students?: Student[];
    neatnessOptions?: string[];
    lifestyleOptions?: string[];
    classloadOptions?: string[];
    classificationOptions?: string[];
  }>(),
  {
    students: () => [],
    neatnessOptions: () => [],
    lifestyleOptions: () => [],
    classloadOptions: () => [],
    classificationOptions: () => [],
  }
);

const toast = useToast();

const selectedStudent = ref(null as Student | null);

const showFullControls = ref(false);

const useConfirmation = () => {
  const show = ref(false);
  const student = ref(null as Student | null);

  return reactive({
    student,
    show,
    open(s: Student) {
      show.value = true;
      student.value = s;
    },
    close() {
      show.value = false;
    },
  });
};
const confirmation = useConfirmation();

const { smaller } = useBreakpoints();
const isMobile = smaller("md")

watchEffect(() => {
  if (isMobile.value) {
    showFullControls.value = false;
  }
});

const inviteToRoommateGroup = async (student: Student) => {
  try {
    const data = await roommateFinder.invite({
      params: {
        roommate_finder_id: props.pool.id,
      },
      data: {
        invitee: student.id,
      },
    });

    window.location.assign(data.location);
  } catch (e) {
    console.log(e);
    showError(e);
  }
};

const showError = (e: Error) => {
  if (axios.isAxiosError(e) && e.response) {
    if (e.response.data.errors)
      toast.add({
        detail: e.response.data.errors.join(", "),
        severity: "error",
        summary: "Error",
        life: 3000,
      });

    if (e.response.data.location) {
      window.location.assign(e.response.data.location);
    }
  }
};

const showMore = useShowMore(selectedStudent);
const { sortingOptions, selectedSort, studentList, filters } =
  useFiltering(props);
</script>

<style lang="scss">
.student-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 768px) {
  body.roommate_finder.show {
    section.container-fluid {
      padding-right: 300px !important;
    }
  }
}

.finder-sidebar {
  background-color: white;
  width: 300px;
  right: 0;
  top: 51px;
  height: 100%;
  position: fixed;

  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.4),
    0 2px 4px -1px rgba(0, 0, 0, 0.24);
}

main {
  overflow-y: auto;
}
</style>
