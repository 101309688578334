<template>
  <article class="card">
    <header class="card-header">
      <span :class="badgeType(request.status)">{{ request.status }}</span>
    </header>
    <section class="px-3">
      <dl>
        <template v-for="(item, i) in descriptionList" :key="item.name">
          <div
            :class="[
              { 'border-bottom': i !== descriptionList.length - 1 },
              'row py-3 ',
            ]"
          >
            <dt
              style="font-weight: normal; vertical-align: middle"
              class="col-12 col-sm-4 text-muted"
            >
              {{ item.name }}
            </dt>
            <dd class="col-12 col-sm-8 m-0">{{ item.value }}</dd>
          </div>
        </template>
      </dl>
    </section>
  </article>
</template>

<script setup lang="ts">
import { MaintenanceRequest } from "../types";

interface Props {
  request: MaintenanceRequest;
}

const props = defineProps<Props>();

const descriptionList = [
  {
    name: "Building/Room",
    value: `${props.request.building} ${props.request.room}`,
  },
  {
    name: "Date Submitted",
    value: props.request.submittedDt,
  },
  {
    name: "Submitted By",
    value: props.request.submittedBy,
  },
  {
    name: "Category",
    value: props.request.category,
  },
  {
    name: "Summary of Issue",
    value: props.request.summary,
  },
  {
    name: "Open Date",
    value: props.request.openDt,
  },
  {
    name: "Close Date",
    value: props.request.closeDt,
  },
  {
    name: "Comments",
    value: props.request.comments,
  },
].filter((item) => item.value);

const badgeType = (status: MaintenanceRequest["status"]) => {
  let bType;
  switch (status) {
    case "Submitted":
      bType = "bg-secondary";
      break;
    case "Open":
      bType = "bg-success";
      break;
    case "Closed":
      bType = "bg-danger";
      break;
  }

  return `badge ${bType}`;
};
</script>
