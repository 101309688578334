<template>
  <v-dialog
    v-model:visible="model"
    modal
    :show-header="false"
    position="top"
    :closable="false"
    style="max-width: 65ch; width: 65ch"
  >
    <form @submit="submit">
      <base-textfield
        label="Building/Room"
        name="building-room-name"
        div-class="mb-3"
        :model-value="`${building} ${room}`"
        disabled
      />
      <base-dropdown
        v-model="form.categoryId"
        :rules="rules.categoryId"
        :form-name="formName"
        name="categoryId"
        label="Type of Maintenance"
        div-class="mb-3"
        :options="categories"
        option-label="name"
        option-value="id"
      />
      <base-textfield
        v-model="form.summary"
        :rules="rules.summary"
        :form-name="formName"
        name="summary"
        label="Summary of issue"
        div-class="mb-3"
      />
      <base-text-area
        v-model="form.comments"
        :rules="rules.comments"
        :form-name="formName"
        name="comments"
        label="Comments"
        div-class="mb-3"
      />
    </form>
    <template #footer>
      <button class="btn btn-secondary" @click="model = false">Cancel</button>
      <button class="btn btn-primary" @click="submit">Submit</button>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import VDialog from "primevue/dialog";
import { computed, Ref, ref, watch } from "vue";
import { useForm } from "@/utilities/validations";
import { MaintenanceRequestCategory, MaintenanceRequestForm } from "../types";
import { useValidation } from "../useValidation";

interface Props {
  modelValue: boolean;
  building: string;
  categories?: MaintenanceRequestCategory[];
  room?: string;
}

interface Emits {
  (e: "update:modelValue", value: boolean): void;
  (e: "submit", value: MaintenanceRequestForm): void;
}

const props = withDefaults(defineProps<Props>(), {
  categories: () => [],
  room: "",
});

const emit = defineEmits<Emits>();

const form: Ref<{
  categoryId: number | null;
  summary: string;
  comments: string;
}> = ref({
  categoryId: null,
  summary: "",
  comments: "",
});

const { rules } = useValidation();
const formName = "maintenance-form";

const { submit: handleSubmit, reset: resetForm } = useForm(formName);

const submit = handleSubmit(() => {
  emit("submit", form.value as MaintenanceRequestForm);
});

const model = computed<boolean>({
  get() {
    return props.modelValue;
  },
  set(v) {
    emit("update:modelValue", v);
  },
});

const reset = () => {
  form.value = {
    categoryId: null,
    summary: "",
    comments: "",
  };
  resetForm();
};

watch(model, () => reset());
</script>
