<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :optional="optional"
  >
    <textarea
      :id="innerId"
      :value="model || ''"
      :name="name"
      v-bind="$attrs"
      :class="{ 'is-invalid': showError, 'form-control': true }"
      style="max-width: 70ch"
      @blur="onBlur"
      @change="onChange"
      @input="
        updateModel($event);
        onInput();
      "
    />
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { debounce } from "lodash";
import { computed, Ref, toRefs } from "vue";

import { computedModel } from "@/utilities";
import { useField, Validators } from "@/utilities/validations";

type ModelValue = string | null;

interface Props {
  name: string;
  id?: string;
  modelValue?: ModelValue;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;

  // Validation Props
  rules?: Validators<string | null>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  modelValue: null,
  rules: () => [],
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();

const innerId = computed(() => props.id || props.name);

const { modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const rules = toRefs(props).rules as Ref<Validators<ModelValue>>;
const { errorMessage, touched, validate, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

const onInput = debounce(() => {
  if (touched.value && errorMessage.value) {
    validate();
  }
}, 100);

const updateModel = (ev: Event) => {
  model.value = (ev.target as HTMLTextAreaElement).value;
};

const onChange = () => {
  touched.value = true;
  validate();
};

const onBlur = () => {
  touched.value = true;
};
</script>
