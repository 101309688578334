<template>
  <base-text-area
    v-model="medicalNotes"
    div-class="mb-3"
    label="Allergies or other health concerns that your deans should be aware of"
    name="medicalNotes"
    rows="10"
    cols=""
    style="width: 100%"
    :rules="rules.medicalNotes"
    :form-name="formName"
  />
  <emergency-contact-form
    v-model="contact1"
    :form-name="formName"
    title="Primary Emergency Contact"
    name="emergencyContact1"
    :rules="rules.contact1"
    :states="states"
  />
  <emergency-contact-form
    v-model="contact2"
    :form-name="formName"
    title="Secondary Emergency Contact"
    optional
    name="emergencyContact2"
    :rules="rules.contact2"
    :states="states"
  />
</template>

<script setup lang="ts">
import { toRefs } from "vue";

import { computedModels } from "@/utilities";

import EmergencyContactForm from "./components/EmergencyContactForm.vue";
import { ContactInformation, ContactInformationRules } from "./types";

interface Props {
  modelValue: ContactInformation;
  formName: string;
  rules: ContactInformationRules;
  states: { label: string; value: string }[];
}

interface Emits {
  (e: "update:modelValue", payload: ContactInformation): void;
}

const props = defineProps<Props>();
const { modelValue } = toRefs(props);

const emit = defineEmits<Emits>();

const { medicalNotes, contact1, contact2 } = computedModels(modelValue, emit);
</script>
