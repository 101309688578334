<template>
  <div id="applications_list">
    <h2>Status</h2>
    <table class="table">
      <thead>
        <tr>
          <th>Application</th>
          <th>Submitted At</th>
          <th>Status</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="app in applications" :key="app.id">
          <td>{{ app.housingPoolName }}</td>
          <td>{{ app.displayDate }}</td>
          <td>
            {{ app.displayState }}
          </td>
          <td class="hidden-xs">
            <a v-if="app.editable" :href="app.editUrl"> Edit</a>
          </td>
        </tr>
        <tr v-show="applications.length === 0">
          <td colspan="4" style="text-align: center">
            You have not yet submitted any applications.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Application } from "../types";

export default defineComponent({
  name: "StatusList",
  props: {
    applications: {
      type: Array as PropType<Array<Application>>,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
});
</script>
