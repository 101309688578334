<template>
  <v-dialog
    v-model:visible="store.chooseRoommate.dialogs.joinGroup"
    modal
    :show-header="false"
    position="top"
  >
    <form @submit.prevent="submit">
      <base-textfield
        v-model="form.groupCode"
        :rules="rules.groupCode"
        name="groupCode"
        label="Roommate Code"
        type="text"
        :form-name="formName"
      />
    </form>
    <template #footer>
      <button type="button" class="btn btn-primary" @click="submit">
        Join Group
      </button>
      <button type="button" class="btn btn-secondary" @click="cancel">
        Cancel
      </button>
    </template>
  </v-dialog>
</template>

<script lang="ts">
export default {
  name: "JoinGroupDialog",
};
</script>

<script setup lang="ts">
import VDialog from "primevue/dialog";
import { reactive, watch } from "vue";
import { string } from "yup";

import { useForm } from "@/utilities/validations";

import { useStore } from "../../../store";

const store = useStore();

const form = reactive({
  groupCode: "",
});

const rules = {
  groupCode: string().required("required"),
};

const formName = "join-group-form";
const { submit: submitFn, reset } = useForm(formName);

const submit = submitFn(() => {
  store.joinGroup(form.groupCode);
});

const cancel = () => {
  store.chooseRoommate.dialogs.joinGroup = false;
};

watch(
  () => store.chooseRoommate.dialogs.joinGroup,
  () => reset()
);
</script>
