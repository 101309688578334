<template>
  <div>
    <template v-if="invitations.length">
      <view-group-dialog
        v-model="showSidebar"
        :roommate-group="currentGroup"
        @accept-invitation="
          store.acceptInvitation($event);
          showSidebar = false;
        "
        @decline-invitation="
          store.declineInvitation($event);
          showSidebar = false;
        "
      />
      <invitation-list
        :invitations="invitations"
        :include-actions="true"
        @accept-invitation="store.acceptInvitation($event)"
        @decline-invitation="store.declineInvitation($event)"
        @view-group="viewGroup($event)"
      />
    </template>
    <h3 v-else style="text-align: center" class="text-muted my-3">
      No Pending Invitations
    </h3>
  </div>
</template>

<script setup lang="ts">
import { Ref, ref } from "vue";

import { useStore } from "@/views/RoommateGroups/New/store";
import { RoommateGroupType } from "@/views/RoommateGroups/New/types";

import InvitationList from "../InvitationList.vue";
import ViewGroupDialog from "./components/ViewGroupDialog.vue";

interface Props {
  invitations: (RoommateGroupType & { invitedBy: string })[];
}

interface Emits {
  (e: "accept-invitation", value: string): void;
  (e: "decline-invitation", value: string): void;
}

withDefaults(defineProps<Props>(), { invitations: () => [] });

defineEmits<Emits>();

const store = useStore();

const showSidebar = ref(false);
const currentGroup: Ref<(RoommateGroupType & { invitedBy: string }) | null> =
  ref(null);

const viewGroup = (group: RoommateGroupType & { invitedBy: string }) => {
  currentGroup.value = group;
  showSidebar.value = true;
};
</script>
