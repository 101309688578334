<template>
  <div class="alert alert-info">
    <div class="form-check">
      <input
        id="note-confirmation"
        v-model="model"
        name="note-confirmation"
        type="checkbox"
        class="form-check-input"
      />
      <label
        for="note-confirmation"
        class="form-check-label"
        v-html="confirmationText"
      ></label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computedModel } from "@/utilities";
import { toRefs } from "vue";

interface Props {
  modelValue: boolean;
  confirmationText: string;
}

interface Emits {
  (e: "update:modelValue", value: boolean): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const modelValue = toRefs(props).modelValue;
const model = computedModel(modelValue, emit);
</script>
