<template>
  <base-button-group-checkbox
    v-model="form.stayingInRoom"
    name="stayingInRoom"
    :label="`Are you planning to continue staying in ${currentBuilding} next term?`"
    div-class="mb-3"
    option-label="name"
    :options="[
      { name: 'Yes', value: true },
      { name: 'No', value: false },
    ]"
    :form-name="formName"
    :rules="rules.stayingInRoom"
  />
  <fade-transition mode="out-in">
    <div v-if="form.stayingInRoom" key="other-options">
      <base-button-group-checkbox
        v-model="form.changeHousing"
        name="changeHousing"
        label="Do you wish to make any changes to your housing situation?"
        div-class="mb-3"
        option-label="name"
        :options="[
          { name: 'Yes', value: true },
          { name: 'No', value: false },
        ]"
        :form-name="formName"
        :rules="rules.changeHousing"
      />
      <fade-transition mode="out-in">
        <base-text-area
          v-if="form.changeHousing"
          v-model="form.housingChangeRequests"
          name="housingChangeRequests"
          label="Requested Changes"
          div-class="mb-3"
          :form-name="formName"
          :rules="rules.housingChangeRequests"
        />
      </fade-transition>
    </div>
    <div
      v-else-if="form.stayingInRoom === false"
      key="plan-options"
      class="row"
    >
      <div class="col-12 col-sm-6 pb-3">
        <plan-options
          :show-summer-plans="false"
          :survey="survey"
          :survey-options="surveyOptions"
          :form-name="formName"
          :rules="rules"
        />
      </div>
      <fade-transition mode="out-in">
        <div v-if="show.forwardingAddress" class="col-12 col-sm-6 pb-3">
          <forwarding-address
            :states="states"
            :survey="survey"
            :form-name="formName"
            :rules="rules"
          />
        </div>
      </fade-transition>
    </div>
  </fade-transition>
</template>

<script setup lang="ts">
import { computed, inject, reactive, Ref } from "vue";

import { FuturePlanOption, FuturePlans, FuturePlansSurvey } from "../types";
import { Rules } from "../useValidations";
import ForwardingAddress from "./ForwardingAddress.vue";
import PlanOptions from "./PlanOptions.vue";

export interface Props {
  states: { name: string; value: number }[];
  surveyOptions: FuturePlanOption[];
  survey: FuturePlansSurvey;
  currentBuilding: string;
  formName: string;
  rules: Rules;
}

defineProps<Props>();

const form = inject("form") as Ref<FuturePlans.EndOfTerm>;

const show = reactive({
  forwardingAddress: computed(
    () =>
      form.value.futurePlanOption &&
      form.value.futurePlanOption?.planCategory !== "Resident"
  ),
});
</script>
