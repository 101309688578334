<template>
  <roommate-search-modal />
  <leave-group-dialog />
  <join-group-dialog />

  <group-tabs v-model="currentTab" :tabs="tabs">
    <template #current>
      <current-roommate-group :group="store.chooseRoommate.currentGroup" />
    </template>
    <template #pending>
      <pending-invitations-list
        :invitations="store.chooseRoommate.invitations.pending"
      />
    </template>
    <template #declined>
      <declined-invitations-list
        :declines="store.chooseRoommate.invitations.declined"
      />
    </template>
    <template #footer>
      <div
        v-if="
          store.chooseRoommate.currentGroup == null || currentTab === 'current'
        "
        class="card-footer"
      >
        <template v-if="currentTab === 'current'">
          <button
            v-if="!store?.chooseRoommate?.currentGroup?.isLocked"
            class="btn btn-primary me-2"
            @click="store.chooseRoommate.dialogs.invite = true"
          >
            Invite Roommate
          </button>
          <button
            v-if="
              store.chooseRoommate.currentGroup &&
              !store.chooseRoommate.currentGroup.isLocked
            "
            class="btn btn-primary me-2"
            @click="leaveGroup"
          >
            Leave Group
          </button>
          <template v-if="store.chooseRoommate.currentGroup === null">
            <button
              class="btn btn-primary me-2"
              @click="store.chooseRoommate.dialogs.joinGroup = true"
            >
              Join Group
            </button>
          </template>
        </template>
        <button
          v-if="store.chooseRoommate.currentGroup === null"
          class="btn btn-secondary"
          @click="store.updateFormType(null)"
        >
          Go Back
        </button>
      </div>
    </template>
  </group-tabs>
</template>

<script setup lang="ts">
import {
  BIconPersonCircle,
  BIconQuestionCircle,
  BIconXCircle,
} from "bootstrap-icons-vue";
import { Ref, ref } from "vue";

import { useStore } from "../../store";
import CurrentRoommateGroup from "./components/CurrentRoommateGroup.vue";
import DeclinedInvitationsList from "./components/DeclinedInvitationsList.vue";
import GroupTabs from "./components/GroupTabs.vue";
import JoinGroupDialog from "./components/JoinGroupDialog.vue";
import LeaveGroupDialog from "./components/LeaveGroupDialog.vue";
import PendingInvitationsList from "./components/PendingInvitationsList/Index.vue";
import RoommateSearchModal from "./components/RoommateSearchModal/Index.vue";

const store = useStore();

const tabs = [
  { name: "current", title: "Current Roommates", icon: BIconPersonCircle },
  { name: "pending", title: "Pending Invitations", icon: BIconQuestionCircle },
  { name: "declined", title: "Declined Invitations", icon: BIconXCircle },
] as const;

const currentTab: Ref<typeof tabs[number]["name"]> = ref("current");

if (
  store.chooseRoommate.currentGroup == null &&
  store.chooseRoommate.invitations.pending.length > 0
) {
  currentTab.value = "pending";
}

const leaveGroup = () => {
  if (store.chooseRoommate.currentGroup?.members?.length === 1) {
    store.leaveGroup();
  } else {
    store.chooseRoommate.dialogs.leaveGroup = true;
  }
};
</script>
