<template>
  <tr
    v-bind="$attrs"
    :class="['checks-table__row', rowClass(check)]"
    :style="{ cursor: hasDetails ? 'pointer' : 'auto' }"
    :aria-expanded="!collapsed"
    @click="toggleCollapse"
  >
    <th
      class="d-lg-none checks-table__column more-info text-center"
      scope="row"
    >
      <i v-show="hasDetails" class="bi bi-chevron-right"></i>
    </th>
    <td class="checks-table__column night-of">{{ check.nightOf }}</td>
    <td class="checks-table__column time">{{ check.checkInTime }}</td>
    <td class="checks-table__column type">{{ check.typeName }}</td>
    <td class="checks-table__column reason">
      {{ check.excusedReason }}
    </td>
    <td class="checks-table__column excused d-none d-lg-table-cell">
      {{ check.status }}
    </td>
    <td
      :class="{
        'checks-table__column d-none d-lg-table-cell room-state': true,
        'table-success': check.roomState === 'Clean',
        'table-danger': check.roomState === 'Dirty',
      }"
    >
      {{ check.roomState }}
    </td>
    <td
      v-if="showCharges"
      class="checks-table__column points d-none d-lg-table-cell"
    >
      {{ check.charge == "$0" ? null : check.charge }}
    </td>
  </tr>
  <tr>
    <td colspan="20" class="p-0 border-0">
      <collapse-transition>
        <div
          v-if="!collapsed"
          :class="{
            'container-fluid': true,
            collapsed: collapsed,
          }"
        >
          <div class="p-3">
            <dl>
              <template v-for="[desc, value] in details">
                <template v-if="value">
                  <dt class="col-md-3">{{ desc }}</dt>
                  <dd class="col-md-9">{{ value }}</dd>
                </template>
              </template>
            </dl>
          </div>
        </div>
      </collapse-transition>
    </td>
  </tr>
</template>

<script setup lang="ts">
import { ref } from "vue";

import { useBreakpoints } from "@/utilities/breakpoints";

import { Check } from "../types";

interface Props {
  showCharges: boolean;
  check: Check;
}

const { isGreaterOrEqual } = useBreakpoints();

const props = defineProps<Props>();

const collapsed = ref(true);
let details = [
  ["Excused", props.check.status],
  ["Excused Until", props.check.excusedUntil],
  ["Excused By", props.check.excusedBy],
  ["Excused Reason", props.check.excusedReason],
  ["Comments", props.check.comments],
  ["Room State", props.check.roomState],
] as Array<string[] | [string, null | string]>;

if (props.showCharges) {
  details = [
    ...details,
    ["Charge", props.check.charge == "$0" ? null : props.check.charge],
  ];
}

const hasDetails = details.some((v) => !!v[1]);

const toggleCollapse = () => {
  if (isGreaterOrEqual("lg") || !hasDetails) {
    return;
  }

  collapsed.value = !collapsed.value;
};

const rowClass = (check: Check) => {
  switch (check.type) {
    case "no_contact":
    case "no_return":
      return "table-danger";

    case "holiday_leave":
    case "nightly_leave":
    case "late_leave":
      return "table-success";

    case "violation":
      return "table-warning";

    case "excused_no_contact":
    case "excused_violation":
    case "excused_no_return":
      return "table-info";

    default:
      null;
  }
};
</script>

<style lang="scss" scoped>
.bi-chevron-right::before {
  transition: 200ms;
}

tr[aria-expanded="true"] .bi-chevron-right::before {
  transform: rotate(90deg);
}
</style>
