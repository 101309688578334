<template>
  <base-textfield type="email" label="Email" name="email">
    <template #append="{ errors }">
      <span v-if="searching" class="input-group-text">
        <b-icon-arrow-clockwise class="spinning" />
      </span>
      <button
        v-else
        class="btn btn-primary"
        :disabled="searching && !!errors"
        @click.prevent="$emit('search')"
      >
        Find Student
      </button>
    </template>
  </base-textfield>
</template>

<script lang="ts">
export default {
  name: "FindStudentField",
};
</script>

<script setup lang="ts">
import { BIconArrowClockwise } from "bootstrap-icons-vue";

interface Props {
  searching: boolean;
}

interface Emits {
  (e: "search"): void;
}

withDefaults(defineProps<Props>(), {
  searching: false,
});

defineEmits<Emits>();
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.spinning {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
