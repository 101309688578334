<template>
  <div>
    <p class="mb-3">
      <strong>A roommate will be assigned to you</strong>
    </p>

    <button
      class="btn btn-secondary d-block"
      @click="store.updateFormType(null)"
    >
      Go Back
    </button>
  </div>
</template>

<script setup lang="ts">
import { useStore } from "../store";

const store = useStore();
</script>
