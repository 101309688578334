<template>
  <div>
    <h2>History</h2>
    <table class="table">
      <thead>
        <tr>
          <th>Application</th>
          <th>Submitted At</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="app in applications" :key="app.id">
          <td>{{ app.housingPoolName }}</td>
          <td>{{ app.displayDate }}</td>
          <td>
            {{ app.displayState }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { PropType, defineComponent } from "vue";
import { Application } from "../types";

export default defineComponent({
  name: "HistoryList",
  props: {
    applications: {
      type: Array as PropType<Array<Application>>,
      default: () => [],
    },
  },
  setup() {
    return {};
  },
});
</script>
