<template>
  <strong
    v-show="errorMessage && touched"
    v-bind="$attrs"
    class="invalid-feedback"
    :style="{
      'font-weight': 'normal',
      'margin-bottom': 0,
      'font-size': '0.9rem',
    }"
    role="alert"
  >
    {{ errorMessage }}
  </strong>
  <p
    v-if="helpText"
    v-show="!(errorMessage && touched)"
    v-bind="$attrs"
    class="text-muted mb-0 mt-2"
    style="font-size: 0.9rem"
  >
    {{ helpText }}
  </p>
  <p v-else-if="$slots.default" class="mt-2 text-muted">
    <slot></slot>
  </p>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
export interface Props {
  helpText?: string;
  errorMessage?: string;
  touched?: boolean;
}

withDefaults(defineProps<Props>(), {
  helpText: "",
  errorMessage: "",
  touched: false,
});
</script>

<style lang="scss" scoped>
.invalid-feedback {
  display: block;
}
</style>
