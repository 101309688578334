<template>
  <div>
    <p :class="{ 'is-invalid text-primary': showError }">
      Drag the three labels below to the form above to indicate your 1st, 2nd,
      and 3rd priorities.
    </p>
    <priority-draggable
      v-if="selected[1]"
      style="touch-action: none"
      class="me-2"
      :value="1"
    />
    <priority-draggable
      v-if="selected[2]"
      style="touch-action: none"
      class="me-2"
      :value="2"
    />
    <priority-draggable
      v-if="selected[3]"
      style="touch-action: none"
      :value="3"
    />
    <help-text :error-message="errorMessage" :touched="touched" />
  </div>
</template>

<script setup lang="ts">
import { computed, Ref, toRefs, watch } from "vue";

import { safeInject } from "@/utilities";
import { useField, Validators } from "@/utilities/validations";

import { ApplicationPriority } from "../types";
import { PriorityHandlerKey } from "../usePriorities";
import PriorityDraggable from "./PriorityDraggable.vue";

type Model = [
  ApplicationPriority | undefined,
  ApplicationPriority | undefined,
  ApplicationPriority | undefined
];

interface Props {
  formName: string;
  rules?: Validators<Model>;

  priority1?: ApplicationPriority;
  priority2?: ApplicationPriority;
  priority3?: ApplicationPriority;
}

const props = defineProps<Props>();

const value = computed<Model>(() => [
  props.priority1,
  props.priority2,
  props.priority3,
]);

const { rules } = toRefs(props);

const { validate, touched, errorMessage, showError } = useField(
  "priorities",
  value,
  rules as Ref<Validators<Model>>,
  {
    formName: props.formName,
  }
);

watch(value, () => {
  if (touched.value) {
    validate();
  }
});

const priorityHandler = safeInject(PriorityHandlerKey);

const selected = computed(() => {
  const s = [null, true, true, true];

  priorityHandler.dropzone.all.value.forEach((zone) => {
    if (zone.value) {
      s[zone.value] = false;
    }
  });

  return s;
});
</script>
