import { App } from "vue";

import FuturePlansModal from "@/modals/FuturePlansModal.vue";

const pages = import.meta.glob("../views/**/Index.vue", { eager: true });

const sharedCompsA = import.meta.glob("../shared/*.vue", { eager: true });
const sharedCompsB = import.meta.glob("../shared/*/Index.vue", { eager: true });
const sharedComps = {
  ...sharedCompsA,
  ...sharedCompsB,
};

function loadPages(app: App<Element>) {
  Object.entries(pages)
    .filter(([filename]) => filename.search("/components/") === -1)
    .forEach(([filename, comp]) => {
      const componentName = filename.split("/").slice(2, -1).join("");

      app.component(componentName, (comp as { default: any }).default || comp);
    });
}

function loadGlobalComponents(app: App<Element>) {
  Object.entries(sharedComps).forEach(([fileName, comp]) => {
    const fpath = fileName.split("/");

    let file = fpath.pop();
    if (file === "Index.vue") {
      file = fpath.pop();
    }
    if (file == undefined) {
      return;
    }
    const componentName = file.replace(/\.\w+$/, "");
    app.component(componentName, (comp as { default: any }).default || comp);
  });
}

export const globals = {
  install: (app: App<Element>) => {
    loadPages(app);
    loadGlobalComponents(app);

    app.component("FuturePlansModal", FuturePlansModal);
  },
};
