<template>
  <div
    ref="dropzone"
    :class="{
      'dropzone d-flex ': true,
      'hovering border-primary': hovering,
      'active border rounded py-2': active,
      'border-secondary': hovering && !active,
    }"
  >
    <div class="dropzone__content">
      <slot />
    </div>
    <div style="touch-action: none" class="ms-2 d-flex align-items-center">
      <Badge
        v-if="priorityHandler.selected.value != null && hovering"
        :value="priorityHandler.selected.value"
        size="xlarge"
        severity="info"
        class="user-select-none"
        :style="{ opacity: '0.5' }"
      />
      <priority-draggable v-if="value !== null" :value="value" />
    </div>
  </div>
</template>

<script setup lang="ts">
import Badge from "primevue/badge";
import { computed, inject, onMounted, reactive, ref, watch } from "vue";

import { ApplicationPriority } from "../types";
import { Dropzone, PriorityHandlerKey } from "../usePriorities";
import PriorityDraggable from "./PriorityDraggable.vue";

interface Props {
  name: string;
  priority1?: ApplicationPriority;
  priority2?: ApplicationPriority;
  priority3?: ApplicationPriority;
}

const props = withDefaults(defineProps<Props>(), {
  priority1: null,
  priority2: null,
  priority3: null,
});

const emit = defineEmits<{
  (e: "update:priority1", payload: ApplicationPriority): void;
  (e: "update:priority2", payload: ApplicationPriority): void;
  (e: "update:priority3", payload: ApplicationPriority): void;
}>();

const value = computed({
  get() {
    if (props.priority1 === props.name) {
      return 1;
    } else if (props.priority2 === props.name) {
      return 2;
    } else if (props.priority3 === props.name) {
      return 3;
    } else {
      return null;
    }
  },
  set(val: number | null) {
    switch (val) {
      case 1:
        emit("update:priority1", props.name);
        break;
      case 2:
        emit("update:priority2", props.name);
        break;
      case 3:
        emit("update:priority3", props.name);
        break;
    }
  },
});

const dropzone = ref(null as unknown as HTMLDivElement);

const priorityHandler = inject(PriorityHandlerKey);
if (priorityHandler === undefined) {
  throw "Failed to get priority handler";
}

const hovering = computed(() => {
  const v = priorityHandler.dropzone.hovering.value;

  return v !== null && v === dropzone.value;
});

const active = computed(() => priorityHandler.selected.value !== null);

const rect: Dropzone = reactive({
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  value,
});

const timer = ref(0);

watch(active, async (val) => {
  if (val) {
    update();
    timer.value = setInterval(update, 200) as unknown as number;
  } else {
    clearInterval(timer.value);
  }
});

const update = () => {
  const { top, left, right, bottom } = dropzone.value.getBoundingClientRect();

  rect.top = top;
  rect.bottom = bottom;
  rect.right = right;
  rect.left = left;
};

onMounted(() => {
  priorityHandler.dropzone.add(dropzone.value, rect);
});
</script>

<style scoped lang="scss">
.dropzone {
  transition: 200ms;
}

.dropzone__content {
  flex: 1;
}

.border-transparent {
  border-color: transparent !important;
}
</style>
