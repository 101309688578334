<template>
  <fieldset>
    <legend class="fs-6">{{ title }}</legend>
    <div class="row mb-3">
      <div class="p-inputgroup mb-2">
        <p-dropdown
          v-model="phoneType"
          :class="{
            'p-invalid':
              phoneField.showError.value || phoneTypeField.showError.value,
          }"
          :options="phoneOptions"
          placeholder="Type"
          @change="onChange"
        />
        <p-textfield
          v-model="phone"
          :class="{
            'p-invalid':
              phoneField.showError.value || phoneTypeField.showError.value,
          }"
          autocomplete="off"
          type="phonenumber"
          @blur="onBlur"
          @change="onChange"
        />
      </div>
      <help-text
        help-text="Ex: +12222425, (404) 888-9999, etc"
        :touched="phoneField.touched.value"
        :error-message="
          phoneField.errorMessage.value + phoneTypeField.errorMessage.value
        "
      />
    </div>
  </fieldset>
</template>

<script setup lang="ts">
import PDropdown from "primevue/dropdown";
import PTextfield from "primevue/inputtext";
import { computed, nextTick, Ref, watch } from "vue";

import { useField } from "@/utilities/validations";

import { PhoneType, PhoneValidator } from "../types";

interface Props {
  title: string;
  name: string;
  phone: string | null;
  phoneType: PhoneType | null;
  rules: PhoneValidator;
  formName: string;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:phone", payload: string | null): void;
  (e: "update:phoneType", payload: PhoneType | null): void;
}

const emit = defineEmits<Emits>();

const phone = computed<string | undefined>({
  get() {
    return props.phone || undefined;
  },
  set(v: string | undefined) {
    emit("update:phone", v || null);
  },
});

const phoneType = computed({
  get() {
    return props.phoneType;
  },
  set(v: PhoneType | null) {
    emit("update:phoneType", v);
  },
});

const phoneOptions = ["Home", "Work", "Pager", "Cell", "Other"];

const phoneRules = computed(() => props.rules.phone);
const phoneTypeRules = computed(() => props.rules.phoneType);

const phoneField = useField(props.name, phone as Ref<string | null>, phoneRules, {
  formName: props.formName,
});

const phoneTypeField = useField(
  props.name + "Type",
  phoneType,
  phoneTypeRules,
  {
    formName: props.formName,
  }
);

const onChange = async () => {
  phoneField.touched.value = true;
  await nextTick();
  phoneField.validate();
  phoneTypeField.validate();
};

const onBlur = () => {
  phoneField.touched.value = true;
};

watch(phone, (p) => {
  if (p == null || p.length == 0) {
    phoneType.value = null;
  }
});

watch(
  () => props.rules,
  () => {
    phoneField.validate();
    phoneTypeField.validate();
  }
);
</script>

<style scoped lang="scss">
.p-inputwrapper {
  border-right: 0;
  width: 15ch;
  flex: unset !important;
}
</style>
