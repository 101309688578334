<template>
  <article :key="student.id" class="card">
    <section class="card-body">
      <h3 class="card-title">{{ fullname(student) }}</h3>
      <dl>
        <template v-if="student.email.length">
          <dt>email</dt>
          <dd>{{ student.email }}</dd>
        </template>
        <template v-if="student.classification.length">
          <dt>class</dt>
          <dd>{{ student.classification }}</dd>
        </template>
        <template v-if="student.major.length">
          <dt>major</dt>
          <dd>{{ student.major }}</dd>
        </template>
      </dl>
    </section>
    <section class="card__footer">
      <p @click="$emit('show-more', student)">
        Click here for more information
      </p>
      <button class="btn btn-primary" @click="$emit('invite', student)">
        Invite to roommate group
      </button>
    </section>
  </article>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

import { Student } from "../types";

export default defineComponent({
  name: "StudentCard",
  props: {
    student: {
      type: Object as PropType<Student>,
      required: true,
    },
  },
  emits: ["show-more", "invite"],
  setup() {
    const fullname = (student: Student) =>
      `${student.firstName} ${student.lastName}`;

    return {
      fullname,
    };
  },
});
</script>

<style lang="scss" scoped>
.card {
  background: white;
  border: 1px solid #efefef;
  padding: 1rem;
  border-radius: 0.25rem;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
    0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: column;
  transition: all 200ms ease-out;
  &:hover {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.4),
      0 2px 4px -1px rgba(0, 0, 0, 0.24);
  }
  justify-content: space-between;
}

.card-body {
  margin-bottom: 0.5rem;

  dt {
    text-transform: capitalize;
    font-size: 14px;
  }

  dd {
    margin-bottom: 1rem;
    color: grey;
    font-size: 14px;
    text-transform: capitalize;
  }
}

.card__footer {
  p {
    cursor: pointer;
    color: grey;
    &:hover {
      color: black;
      text-decoration: underline;
    }
  }
}
</style>
