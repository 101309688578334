<template>
  <div>
    <h3 class="d-flex">
      <b-icon-calendar class="text-primary" />
      <span class="ms-2">Select Date</span>
    </h3>
    <p-calendar
      v-model="selectedDateJs"
      :disabled-dates="disabledDates.dates.map((x) => fromRailsDateTime(x).toJSDate())"
      :max-date="fromRailsDateTime(disabledDates.max).toJSDate()"
      :min-date="fromRailsDateTime(disabledDates.min).toJSDate()"
      class="w-100"
      inline
    >
    </p-calendar>
  </div>
</template>

<script setup lang="ts">
import { BIconCalendar } from "bootstrap-icons-vue";
import { DateTime } from "luxon";
import PCalendar from "primevue/calendar";
import { computed } from "vue";
import { fromRailsDateTime } from "@/utilities";

interface Props {
  modelValue: DateTime;
  disabledDates: { min: string; max: string; dates: string[] };
}

interface Emits {
  (e: "update:modelValue", value: DateTime): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const selectedDateJs = computed({
  get() {
    return props.modelValue.toJSDate();
  },
  set(date: Date) {
    emit("update:modelValue", DateTime.fromJSDate(date));
  },
});

</script>
