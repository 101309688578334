<template>
  <section>
    <Toast />
    <h1 class="border-bottom pb-3 mb-3">
      {{ name }}
    </h1>

    <fade-transition v-if="!store.loading" mode="out-in">
      <div v-if="formType === null" class="row mb-3">
        <div v-for="tab in formTabs" style="min-width: 250px" class="col">
          <div class="card text-center mb-3">
            <div
              style="display: grid; grid-template-columns: 1fr 1fr 1fr"
              class="card-header"
            >
              <div class="d-flex align-items-center">
                <span v-if="tab.default" class="badge bg-secondary">
                  Default
                </span>
              </div>
              <div class="align-middle text-nowrap">
                {{ tab.title }}
              </div>
              <div></div>
            </div>
            <div class="card-body">
              <div
                class="d-flex align-items-center justify-content-center mb-3"
              >
                <component :is="tab.icon" width="4rem" height="4rem" />
              </div>
              <div v-if="tab.infoText" v-html="tab.infoText"></div>
              <button class="btn btn-primary" @click="tab.go">Choose</button>
            </div>
          </div>
        </div>
        <div v-if="showRoomAlone" class="col-12">
          <a class="link-secondary" @click="store.updateFormType('single')">
            Request to Room Alone
          </a>
        </div>
      </div>
      <div v-else>
        <component
          :is="store.activeFormComponent"
          v-if="store.activeFormComponent"
        />
      </div>
    </fade-transition>
  </section>
</template>

<script setup lang="ts">
import { BIconPeopleFill, BIconPersonLinesFill } from "bootstrap-icons-vue";
import { storeToRefs } from "pinia";
import Toast from "primevue/toast";

import { useStore } from "./store";

interface Props {
  housingPoolMemberId: number;
  name: string;
  schoolId: string;
  showRoomAlone: boolean;
  showChooseRoommate: boolean;
  showAssignedRoommate: boolean;
  chooseRoommateText: string;
  assignedRoommateText: string;
}

const props = defineProps<Props>();

const store = useStore();
const { fetchGroupInfo } = store;
const { formType } = storeToRefs(store);

store.$patch((state) => {
  state.housingPoolMemberId = props.housingPoolMemberId;
  state.schoolId = props.schoolId;
});

store.loading = true;
fetchGroupInfo().finally(() => {
  store.loading = false;
});

const formTabs = [
  {
    title: "Choose Roommate",
    icon: BIconPeopleFill,
    go: () => {
      store.updateFormType("choose");
    },
    infoText: props.chooseRoommateText,
    show: props.showChooseRoommate,
  },
  {
    title: "Assigned Roommate",
    icon: BIconPersonLinesFill,
    go: () => {
      store.updateFormType("assigned");
    },
    infoText: props.assignedRoommateText,
    show: props.showAssignedRoommate,
    default: true,
  },
].filter((t) => t.show);

store.$subscribe(async ({ events }) => {
  if (!Array.isArray(events) && events.key === "formType") {
    const { newValue, oldValue } = events;

    if (oldValue !== "assigned" && newValue === "assigned") {
      store.updateAutoAssigned(true);
    } else if (oldValue === "assigned" && newValue !== "assigned") {
      store.updateAutoAssigned(false);
    }
  }
});
</script>
