<template>
  <div class="page-header">
    <h1>Leave Details</h1>
    <hr />
  </div>
  <div id="leave_form_block">
    <leave-form v-bind="$attrs" form-type="edit" />
  </div>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import LeaveForm from "../components/LeaveForm/Index.vue";
</script>
