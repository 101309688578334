import { InjectionKey, provide, Ref, ref } from "vue";

export interface Dropzone {
  top: number;
  bottom: number;
  left: number;
  right: number;
  value: number | null;
}

interface PriorityHandler {
  dropzone: {
    add: (el: HTMLDivElement, rect: Dropzone) => void;
    hovering: Ref<HTMLDivElement | null>;
    all: Ref<Map<HTMLDivElement, Dropzone>>;
  };
  drag: {
    start: (priority: number) => Promise<void>;
    move: (x: number, y: number) => Promise<void>;
    drop: () => Promise<void>;
  };
  selected: Ref<number | null>;
}

export const PriorityHandlerKey: InjectionKey<PriorityHandler> =
  Symbol("PriorityHandler");

export function usePriorities() {
  const selected = ref(null as number | null);
  const dropzones = ref(new Map() as Map<HTMLDivElement, Dropzone>);

  const scrollSpeed = 10;
  const scrollMargin = 150;
  const interval = 10;
  let scrolling = false;
  let scrollTimer: undefined | number;

  const scroll = (step: number) => {
    clearTimeout(scrollTimer);

    window.scrollTo({
      top: window.scrollY + step,
      behavior: "instant" as unknown as ScrollBehavior,
    });
    // NOTE: This type change is due to node type being used instead of browser type for setTimeout
    // need to find a better solution
    scrollTimer = setTimeout(() => scroll(step), interval) as unknown as number | undefined;
  };

  const checkScroll = (y: number) => {
    if (y < scrollMargin) {
      if (!scrolling) {
        scrolling = true;
        scroll(-scrollSpeed);
      }
    } else if (y > window.innerHeight - scrollMargin) {
      if (!scrolling) {
        scrolling = true;
        scroll(scrollSpeed);
      }
    } else {
      scrolling = false;
      clearTimeout(scrollTimer);
    }
  };

  const dropzone = {
    add(el: HTMLDivElement, dropzone: Dropzone) {
      dropzones.value.set(el, dropzone);
    },
    hovering: ref(null as HTMLDivElement | null),
    all: dropzones,
  };

  const drag = {
    async start(priority: number) {
      if (selected.value !== null) {
        return;
      }
      selected.value = priority;
    },

    async move(x: number, y: number) {
      checkScroll(y);

      const zone = Array.from(dropzones.value.entries()).find(
        ([_, c]) => y >= c.top && y <= c.bottom && x >= c.left && x <= c.right
      );

      dropzone.hovering.value = zone?.[0] || null;
    },

    async drop() {
      if (dropzone.hovering.value) {
        const t = dropzones.value.get(dropzone.hovering.value);
        if (t) {
          const oldZone = Array.from(dropzones.value.entries()).find(
            ([_, c]) => c.value === selected.value
          );

          if (oldZone) {
            oldZone[1].value = null;
          }

          t.value = selected.value;
        }
      }

      scrolling = false;
      clearTimeout(scrollTimer);
      selected.value = null;
      dropzone.hovering.value = null;
    },
  };

  const priorityHandler = {
    drag,
    dropzone,
    selected,
  };

  provide(PriorityHandlerKey, priorityHandler);
}
