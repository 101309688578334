import { nextTick, Ref, watch } from "vue";

import { FuturePlans, isEndOfTerm, isEndOfYear } from "./types";

export function useReset(form: Ref<FuturePlans.Form>, resetForm: () => void) {
  if (isEndOfTerm(form)) {
    watch(
      () => form.value.stayingInRoom,
      (val) => {
        if (val) {
          form.value.changeHousing = null;
          form.value.housingChangeRequests = "";
          form.value.futurePlanOption = null;
          form.value.expectedCheckoutDt = null;
          form.value.planDetail = "";
        } else {
          form.value.forwardingAddress = {
            addressType: null,
            address1: "",
            city: "",
            state: "",
            postal: "",
            phone: "",
            email: "",
          };
        }

        resetForm();
      }
    );

    watch(
      () => form.value.changeHousing,
      () => {
        form.value.housingChangeRequests = "";
      }
    );
  }

  if (isEndOfYear(form)) {
    watch(
      () => form.value.summerPlans,
      () => {
        form.value.summerStartDt = null;
        form.value.summerEndDt = null;
        form.value.summerReason = null;
      }
    );
  }

  watch(
    () => form.value.futurePlanOption?.value,
    () => {
      form.value.forwardingAddress = {
        addressType: isEndOfTerm(form) ? null : "Summer",
        address1: "",
        city: "",
        state: "",
        postal: "",
        phone: "",
        email: "",
      };
      nextTick(() => {
        form.value.planDetail = "";
      });
    }
  );
}
