// JsFromRoutes CacheKey 2d4bb92834b42d3fdfe51b8b5aca3d9e
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const leaves = {
  list: definePathHelper("get", "/leave_requests"),
  create: definePathHelper("post", "/leave_requests"),
  new: definePathHelper("get", "/leave_requests/new"),
  edit: definePathHelper("get", "/leave_requests/:id/edit"),
  update: definePathHelper("patch", "/leave_requests/:id"),
  destroy: definePathHelper("delete", "/leave_requests/:id"),
};
