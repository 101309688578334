import { DateTime } from "luxon";

export function fromRailsDateTime(date: string): DateTime {
  return DateTime.fromISO(date, { setZone: true }).setZone("local", {
    keepLocalTime: true,
  });
}

export function toRailsDateTime(date: DateTime): string {
  return date.toISO({ includeOffset: false });
}

function isRecord<T>(
  value: unknown | { [str: string]: T }
): value is { [str: string]: T } {
  return value !== null && typeof value === "object" && !Array.isArray(value);
}

function isArray<T>(value: unknown | T[]): value is T[] {
  return value !== null && typeof value === "object" && Array.isArray(value);
}

type NestedRecord =
  | { [str: string]: NestedRecord }
  | Array<NestedRecord>
  | unknown;

type StackItem = {
  container: { [str: string]: NestedRecord } | Array<NestedRecord>;
  key: string | number;
  value: NestedRecord;
};

type TransformationFn = (str: string) => string;

/**
 * Transforms the keys of an object to a different format: i.e. snake_case to camelCase
 *
 * @param   obj - An object to have it's keys transformed by transform
 * @param   transform - A function to transform a key from one format to another
 * @returns An object that has had it's keys transformed by transform
 */
export function transformKeys(
  obj: { [str: string]: NestedRecord },
  transform: TransformationFn
): { [str: string]: NestedRecord } {
  const stack: StackItem[] = [];

  let current: StackItem | undefined;
  const result: { [str: string]: NestedRecord } = {};

  const addToContainer = (item: StackItem, value: NestedRecord) => {
    if (isRecord(item.container)) {
      item.container[transform(item.key as string)] = value;
    } else {
      item.container[item.key as number] = value;
    }
  };

  for (const key of Object.keys(obj)) {
    stack.push({ container: result, key, value: obj[key] });
  }

  while (stack.length > 0) {
    current = stack.pop();
    if (current === undefined) continue;

    if (isArray(current.value)) {
      const newContainer: Array<NestedRecord> = [];

      addToContainer(current, newContainer);

      current.value.forEach((value, key) => {
        stack.push({ container: newContainer, key, value });
      });
    } else if (isRecord(current.value)) {
      const newContainer: { [str: string]: NestedRecord } = {};

      addToContainer(current, newContainer);

      for (const [key, value] of Object.entries(current.value)) {
        stack.push({ container: newContainer, key, value });
      }
    } else {
      addToContainer(current, current.value);
    }
  }

  return result;
}

export const stripPhoneNumber = (
  phone: string | null | undefined
): string | null | undefined =>
  phone == null || typeof phone != "string"
    ? phone
    : phone.replace(/[()\-\s]/g, "");
