import { computed, Ref, ref } from "vue";

import { Student } from "./types";

export function useFiltering(props: { students: Student[] }) {
  const sortingOptions = [
    { name: "Last Name (A-Z)", value: "lastname asc" },
    { name: "Last Name (Z-A)", value: "lastname desc" },
  ];

  const selectedSort = ref(null as null | string);

  const filters: Ref<Record<string, null | string>> = ref({
    classification: null,
    lifestyle: null,
    classLoad: null,
    neatness: null,
  });

  const filteredStudents = computed(() =>
    props.students.filter((student: Student) => {
      return Object.entries(filters.value).reduce<boolean>(
        (acc, [filterName, filterValue]) => {
          if (filterValue == null) return acc;

          return acc && student[filterName] === filterValue;
        },
        true
      );
    })
  );

  const studentList = computed(() => {
    if (selectedSort.value == null) {
      return filteredStudents.value;
    }

    const n = [...filteredStudents.value];
    n.sort(sortLastName(selectedSort.value === "lastname asc"));
    return n;
  });

  return {
    sortingOptions,
    selectedSort,
    studentList,
    filters,
  };
}

const sortLastName = (asc: boolean) => (a: Student, b: Student) => {
  const aLastName = a.lastName.toLowerCase();
  const bLastName = b.lastName.toLowerCase();

  if (aLastName < bLastName) {
    return asc ? -1 : 1;
  }
  if (aLastName > bLastName) {
    return asc ? 1 : -1;
  }

  return 0;
};
