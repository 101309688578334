<template>
  <section aria-labelledby="forwarding-address" class="card">
    <header id="forwarding-address" class="card-header">
      Forwarding Address
    </header>
    <section class="card-body">
      <base-dropdown
        v-test="'forwardingAddress.addressType'"
        v-model="form.forwardingAddress.addressType"
        label="Address Type"
        name="forwardingAddress.addressType"
        :disabled="survey.type === 'End of Year'"
        :options="['Home', 'Summer', 'Other']"
        :form-name="formName"
        :rules="rules.forwardingAddress.addressType"
      />
      <base-textfield
        v-model="form.forwardingAddress.address1"
        label="Address 1"
        name="forwardingAddress.address1"
        :form-name="formName"
        :rules="rules.forwardingAddress.address1"
      />
      <base-textfield
        v-model="form.forwardingAddress.address2"
        optional
        label="Address 2"
        name="forwardingAddress.address2"
        :form-name="formName"
        :rules="rules.forwardingAddress.address2"
      />
      <base-textfield
        v-model="form.forwardingAddress.city"
        label="City"
        name="forwardingAddress.city"
        :form-name="formName"
        :rules="rules.forwardingAddress.city"
      />
      <base-dropdown
        v-model="form.forwardingAddress.state"
        label="State"
        name="forwardingAddress.state"
        v-test="'forwardingAddress.state'"
        :options="states"
        option-label="name"
        option-value="value"
        placeholder="Select State"
        :form-name="formName"
        :rules="rules.forwardingAddress.state"
      />
      <base-textfield
        v-model="form.forwardingAddress.postal"
        label="Postal/ZIP code"
        name="forwardingAddress.postal"
        :form-name="formName"
        :rules="rules.forwardingAddress.postal"
      />
      <base-textfield
        v-model="form.forwardingAddress.phone"
        label="Phone"
        name="forwardingAddress.phone"
        :form-name="formName"
        :rules="rules.forwardingAddress.phone"
      />
      <base-textfield
        v-model="form.forwardingAddress.email"
        label="Email"
        name="forwardingAddress.email"
        :form-name="formName"
        :rules="rules.forwardingAddress.email"
      />
    </section>
  </section>
</template>

<script setup lang="ts">
import { inject, Ref } from "vue";

import { FuturePlans, FuturePlansSurvey } from "../types";
import { Rules } from "../useValidations";

interface Props {
  survey: FuturePlansSurvey;
  states: { name: string; value: number }[];
  formName: string;
  rules: Rules;
}

defineProps<Props>();

const form = inject("form") as Ref<
  FuturePlans.EndOfTerm | FuturePlans.EndOfYear
>;
</script>

<style lang="scss" scoped>
section.card-body > div:not(:last-child) {
  margin-bottom: 1rem;
}
</style>
