<template>
  <div class="btn-group btn-group-sm btn-group-toggle">
    <label
      :class="{ active: chose === 'Current', btn: true, 'btn-primary': true }"
    >
      <input v-model="chose" type="radio" name="options" value="Current" />
      Current Student
    </label>
    <label
      :class="{ active: chose === 'Transfer', btn: true, 'btn-primary': true }"
    >
      <input v-model="chose" type="radio" name="options" value="Transfer" />
      Transfer Student
    </label>
    <label :class="{ active: chose === 'New', btn: true, 'btn-primary': true }">
      <input v-model="chose" type="radio" name="options" value="New" />
      Entering Freshman
    </label>
  </div>
</template>

<script lang="ts">
export default {
  name: "StudentTypeTabs",
};
</script>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  modelValue: "Current" | "Transfer" | "New";
}

interface Emits {
  (e: "update:modelValue", payload: string): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const chose = computed({
  get(): string {
    return props.modelValue;
  },
  set(v: string) {
    emit("update:modelValue", v);
  },
});
</script>

<style lang="scss" scoped>
input[type="radio"] {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
</style>
