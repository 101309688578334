<template>
  <Toast />
  <p>Room: {{ roomName }}</p>
  <p>Date: {{ today }}</p>
  <p>
    Mark each item as in "good" (green check) or "bad" (red x) condition. If you
    mark an item "bad", please leave a comment including problem details. If
    your room has a major maintenance issue, please contact your dean.
  </p>
  <form @submit.prevent="submit">
    <table class="table table-striped">
      <thead>
        <tr>
          <th>Item</th>
          <th>Good/Bad</th>
          <th>Comments</th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(item, i) in form.details"
          :key="item.inspectionDefaultId"
        >
          <inspection-row
            :model-value="item"
            :name="`details[${i}]`"
            :form-name="formName"
            @update:model-value="form.details[i] = $event"
          />
        </template>
      </tbody>
    </table>
    <p>
      I, {{ studentName }}, agree that this information is correct. Type your
      full name below to agree:
    </p>
    <base-textfield
      v-model="form.signature"
      name="signature"
      placeholder="Signature"
      :rules="rules.signature"
      div-style="max-width: 50ch"
      :form-name="formName"
    />
    <base-submit-button :loading="loading" class="btn btn-primary my-3" />
  </form>
</template>

<script setup lang="ts">
import { DateTime } from "luxon";
import Toast from "primevue/toast";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";
import { string } from "yup";

import { inspections } from "@/api";
import { useForm } from "@/utilities/validations";

import InspectionRow from "./components/InspectionRow.vue";
import { InspectionDefault } from "./types";

interface Props {
  studentName: string;
  roomName: string;
  details: InspectionDefault[];
}

const props = defineProps<Props>();

const toast = useToast();

const loading = ref(false);
const form = ref({
  details: props.details.map((x) => {
    x.inState = null;
    x.itemNotes = null;
    return x;
  }),
  signature: "",
});

const today = DateTime.now().toLocaleString(DateTime.DATE_SHORT);

const formName = "inspections-form";
const { submit: handleSubmit } = useForm(formName);

const submit = handleSubmit(
  async () => {
    try {
      loading.value = true;
      const data = await inspections.create({
        data: {
          inspection: {
            studentSignatureConfirmation: form.value.signature,
            inspectionDetailsAttributes: form.value.details,
          },
        },
      });

      window.location.assign(data.location);
    } finally {
      loading.value = false;
    }
  },
  () => {
    toast.add({
      severity: "error",
      summary: "Error",
      detail: "There is an error in your form",
      life: 3000,
    });
  }
);

const rules = {
  signature: string()
    .required()
    .equals([props.studentName], "Your signature does not match"),
};
</script>
