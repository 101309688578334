// JsFromRoutes CacheKey 704ac13ce9b381dca90b908c6d75c601
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const roommateFinder = {
  invite: definePathHelper("post", "/roommate_finder/:roommate_finder_id/invite"),
  list: definePathHelper("get", "/roommate_finder"),
  get: definePathHelper("get", "/roommate_finder/:id"),
};
