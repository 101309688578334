import { string } from "yup";

import { phoneNumber } from "@/utilities/validators";

import { ContactInformation, PhoneType } from "./types";

export function rules(form: ContactInformation) {
  const phoneValidator =
    (phoneNumber: string | null) => (phoneType: PhoneType | null) =>
      phoneNumber
        ? !!phoneType || "You must specify what type of number this is"
        : true;

  const contactName = () =>
    string().matches(/\w+(\s+\w+)+/, {
      message: "provide first and last name",
      excludeEmptyString: true,
    });

  const contact2Required =
    form.contact2.contactName ||
    form.contact2.contactRelation ||
    form.contact2.address1 ||
    form.contact2.address2 ||
    form.contact2.city ||
    form.contact2.state ||
    form.contact2.country ||
    form.contact2.postal ||
    form.contact2.phone1 ||
    form.contact2.phone1Type ||
    form.contact2.phone2 ||
    form.contact2.phone2Type ||
    form.contact2.phone3 ||
    form.contact2.phone3Type;

  return {
    medicalNotes: string().nullable(),
    contact1: {
      contactName: contactName().required("required"),
      contactRelation: string().required("required"),
      address1: string().required("required"),
      address2: string(),
      city: string().required("required"),
      state: string().required("required"),
      country: string().required("required"),
      postal: string().required("required"),
      phone1: {
        phone: phoneNumber().required("required"),
        phoneType: [phoneValidator(form.contact1.phone1)],
      },
      phone2: {
        phone: phoneNumber(),
        phoneType: [phoneValidator(form.contact1.phone2)],
      },
      phone3: {
        phone: phoneNumber(),
        phoneType: [phoneValidator(form.contact1.phone3)],
      },
    },
    contact2: {
      contactName: contact2Required
        ? contactName().required("required")
        : contactName(),
      contactRelation: contact2Required
        ? string().required("required")
        : string(),
      address1: contact2Required ? string().required("required") : string(),
      address2: string(),
      city: contact2Required ? string().required("required") : string(),
      state: contact2Required ? string().required("required") : string(),
      country: contact2Required ? string().required("required") : string(),
      postal: contact2Required ? string().required("required") : string(),
      phone1: {
        phone: contact2Required
          ? phoneNumber().required("required")
          : phoneNumber(),
        phoneType: [phoneValidator(form.contact2.phone1)],
      },
      phone2: {
        phone: phoneNumber(),
        phoneType: [phoneValidator(form.contact2.phone2)],
      },
      phone3: {
        phone: phoneNumber(),
        phoneType: [phoneValidator(form.contact2.phone3)],
      },
    },
  };
}
