<template>
  <card-to-mobile-layout v-if="formFields.length" title="About Me">
    <div class="row d-md-grid mb-3">
      <template v-for="(field, i) in formFields">
        <priority-dropzone
          v-if="formOptions.applicationType.hasPriorities"
          :key="field.attrs.name + '-p'"
          v-model:priority1="form.priority1"
          v-model:priority2="form.priority2"
          v-model:priority3="form.priority3"
          :name="field.attrs.name"
          :style="{
            'grid-column': i < formFields.length / 2 ? 1 : 2,
            'grid-row': (i % (formFields.length / 2)) + 1,
          }"
        >
          <component
            :is="field.comp"
            :model-value="form[field.attrs.name]"
            v-bind="field.attrs"
            :rules="field.rules"
            :form-name="formName"
            @update:model-value="form[field.attrs.name] = $event"
          />
        </priority-dropzone>
        <component
          :is="field.comp"
          v-else
          :key="field.attrs.name"
          :rules="field.rules"
          v-bind="field.attrs"
          :form-name="formName"
          :style="{
            'grid-column': i < formFields.length / 2 ? 1 : 2,
            'grid-row': (i % (formFields.length / 2)) + 1,
          }"
          @update:model-value="form[field.attrs.name] = $event"
        />
      </template>
    </div>
    <priorities
      v-if="formOptions.applicationType.hasPriorities"
      :priority1="form.priority1"
      :priority2="form.priority2"
      :priority3="form.priority3"
      :rules="rules['priorities']"
      :form-name="formName"
      class="mb-3"
    />
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { inject, UnwrapRef } from "vue";

import { Application, FormOptions } from "../types";
import { GeneratedField } from "../useFieldGenerator";
import { usePriorities } from "../usePriorities";
import { useValidations } from "../useValidations";
import Priorities from "./Priorities.vue";
import PriorityDropzone from "./PriorityDropzone.vue";

interface Props {
  formOptions: FormOptions;
  rules: UnwrapRef<ReturnType<typeof useValidations>["rules"]>;
  formName: string;
  formFields: GeneratedField[];
}

defineProps<Props>();

const form = inject("form") as Application;

usePriorities();
</script>

<style lang="scss" scoped>
.dropzone__content > * {
  width: 100%;
}

.draggable-source--is-dragging {
  opacity: 0.5;
}

.row {
  grid-template-columns: 50% 50%;
  gap: 1rem 0;
}
</style>
