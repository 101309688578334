<template>
  <ul class="nav nav-tabs d-none d-md-flex">
    <li
      v-for="tab in tabs"
      style="cursor: pointer; font-size: 1.2rem"
      class="nav-item"
    >
      <div
        :class="{
          'nav-link d-flex align-items-center': true,
          active: currentTab === tab.name,
        }"
        @click="currentTab = tab.name"
      >
        <component :is="tab.icon" height="1.5rem" width="1.5rem" />
        <span
          :class="{
            'd-md-inline-block ms-2': true,
            'd-none': currentTab !== tab.name,
          }"
        >
          {{ tab.title }}
        </span>
      </div>
    </li>
  </ul>
  <div class="card mb-3">
    <div class="card-body px-0 py-0">
      <fade-transition mode="out-in">
        <template v-for="tab in tabs">
          <slot v-if="currentTab === tab.name" :name="tab.name" />
        </template>
      </fade-transition>
    </div>
    <slot name="footer" />
  </div>
  <div class="d-md-none bottom-nav border-top d-flex bg-white">
    <div
      v-for="tab in tabs"
      :class="[
        'd-flex flex-column align-items-center flex-fill',
        currentTab === tab.name ? 'text-primary' : 'text-muted',
      ]"
      @click="currentTab = tab.name"
    >
      <component :is="tab.icon" class="mb-2" height="1.5rem" width="1.5rem" />
      <span class="text-center">
        {{ tab.title }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface Props {
  modelValue: string;
  tabs: Readonly<{ name: string; title: string; icon: unknown }[]>;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:model-value", payload: string): void;
}

const emit = defineEmits<Emits>();

const currentTab = computed({
  get() {
    return props.modelValue;
  },
  set(v: string) {
    emit("update:model-value", v);
  },
});
</script>

<style scoped lang="scss">
@media only screen and (min-width: 768px) {
  .card {
    border-top: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }
}

.bottom-nav {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  div {
    padding: 1rem;

    &:not(:last-child) {
      border-right: var(--bs-border-width) var(--bs-border-style)
        var(--bs-border-color);
    }
  }
}
</style>
