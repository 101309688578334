<template>
  <v-dialog
    v-model:visible="store.chooseRoommate.dialogs.leaveGroup"
    :show-header="false"
    position="top"
    modal
    style="max-width: 500px"
  >
    <form @submit.prevent="submit">
      <p class="fw-bolder">
        Leaving this group will permanently remove you from it's membership. You
        will need to re-invited to id rejoin. If you wish to proceed please
        enter your student below.
      </p>
      <base-textfield
        v-model="studentId"
        name="studentId"
        label="Student ID"
        type="text"
        :rules="rules"
        :form-name="formName"
      />
    </form>
    <template #footer>
      <button type="button" class="btn btn-primary" @click="submit">
        Leave Group
      </button>
      <button type="button" class="btn btn-secondary" @click="cancel">
        Cancel
      </button>
    </template>
  </v-dialog>
</template>

<script lang="ts">
export default {
  name: "LeaveGroupConfirmationDialog",
};
</script>

<script setup lang="ts">
import VDialog from "primevue/dialog";
import { ref, watch } from "vue";
import { string } from "yup";

import { useForm } from "@/utilities/validations";

import { useStore } from "../../../store";

const store = useStore();

const studentId = ref("");
const rules = string()
  .required("required")
  .equals([store.schoolId], "Your school id does not match");
const formName = "leave-group-form";
const { reset, submit: handleSubmit } = useForm(formName);

const submit = handleSubmit(async () => {
  await store.leaveGroup(studentId.value);
});

const cancel = () => {
  store.chooseRoommate.dialogs.leaveGroup = false;
};

watch(
  () => store.chooseRoommate.dialogs.leaveGroup,
  () => reset()
);
</script>
