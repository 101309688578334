// JsFromRoutes CacheKey 24ace079da0da531cf438f4030dd1dab
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const moveAppointments = {
  create: definePathHelper("post", "/move_appointments"),
  new: definePathHelper("get", "/move_appointments/new"),
  edit: definePathHelper("get", "/move_appointments/:id/edit"),
  get: definePathHelper("get", "/move_appointments/:id"),
  update: definePathHelper("patch", "/move_appointments/:id"),
};
