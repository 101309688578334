<template>
  <div v-if="isMobile" key="mobile">
    <div v-if="title" class="d-md-none">
      <h2>{{ title }}</h2>
      <hr />
    </div>
    <slot />
  </div>
  <div v-else key="desktop" class="card">
    <div class="card-body">
      <h2 v-if="title" class="card-title">
        {{ title }}
      </h2>
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useBreakpoints } from "@/utilities/breakpoints";

interface Props {
  title?: string;
}

defineProps<Props>();

const { smaller } = useBreakpoints();
const isMobile = smaller("md");
</script>
