import { number, string } from "yup";

export function useValidation() {
  const rules = {
    categoryId: number().nullable().required("required"),
    summary: string().required("required"),
    comments: string(),
  };

  return { rules };
}
