<template>
  <p v-if="checks.length === 0" class="no_results">
    There were no checks in this term for this student
  </p>
  <div v-else id="checks-index-card" class="card mb-4">
    <checks-table :show-charges="showCharges" :checks="checks" />
    <checks-list :show-charges="showCharges" :checks="checks" />
    <div class="card-footer">
      <h3 class="card-title">Term Totals</h3>
      <div
        style="white-space: nowrap; overlflow-hidden"
        class="d-flex flex-md-row flex-column flex-wrap"
      >
        <div
          v-for="[label, value] in totalsList"
          style="max-width: fit-content"
          class="
            me-3
            mb-3
            d-flex
            flex-column
            align-items-md-center
            aling-items-start
          "
        >
          <div class="text-muted fw-bold">{{ label }}</div>
          <div style="font-size: x-large; font-weight: bold">{{ value }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import ChecksList from "./components/ChecksList.vue";
import ChecksTable from "./components/ChecksTable.vue";
import type { Check } from "./types";

interface Props {
  showCharges: boolean;
  checks: Check[];
  totals: {
    checks: number;
    noContacts: number;
    excusedNoContacts: number;
    violations: number;
    excusedViolations: number;
    leaves: number;
  };
}

const props = defineProps<Props>();

const totalsList = [
  ["Checks", props.totals.checks],
  ["No Contacts", props.totals.noContacts],
  ["Excused No Contacts", props.totals.excusedNoContacts],
  ["Violations", props.totals.violations],
  ["Excused Violations", props.totals.excusedViolations],
  ["Leaves", props.totals.leaves],
];
</script>

<style scoped lang="scss">
:deep(.data-table__table) .data-table__table {
  &__heading {
    white-space: nowrap;
  }

  &__column {
    &--more-info {
      width: 40px;
    }

    &--night-of {
      width: 5rem;
    }

    &--check-in-time {
      width: 6rem;
    }

    &--type-name {
      width: 8rem;
    }

    &--status {
      width: 4rem;
    }

    &--room-state {
      width: 5rem;
    }
  }
}
</style>
