import { Ref, ref, watch } from "vue";

import { roommateGroups } from "@/api";
import { useForm } from "@/utilities/validations";

import { MemberInviteForm } from "../../../../types";

export function useStudentSearch(form: Ref<MemberInviteForm>) {
  const searching = ref(false);
  const searched = ref(false);
  const found = ref(null) as Ref<boolean | null>;

  watch(
    () => form.value.email,
    () => {
      searched.value = false;
      found.value = null;
    }
  );

  const searchSubmit =
    (validate: ReturnType<typeof useForm>["validateField"]) => async () => {
      if (!(await validate("email"))) {
        return;
      }

      try {
        searching.value = true;
        const res = await roommateGroups.findStudentFromEmail({
          query: {
            email: form.value.email || "",
          },
        });

        form.value.firstName = res.student.firstName;
        form.value.lastName = res.student.lastName;
        found.value = true;
      } catch (e) {
        found.value = false;
      } finally {
        searched.value = true;
        searching.value = false;
        // This ensures that a invalid not found is shown
        await validate("email");
      }
    };

  return {
    searchSubmit,
    searching,
    searched,
    found,
  };
}
