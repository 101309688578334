// JsFromRoutes CacheKey 356c20b7a1200a698cb22d16c87613d4
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const moveInWizard = {
  create: definePathHelper("post", "/move_in"),
  currentStep: definePathHelper("get", "/MoveIn/current_step"),
  new: definePathHelper("get", "/MoveIn/new"),
};
