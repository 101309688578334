<template>
  <div class="card">
    <div class="card-header">Chapel Checks</div>
    <div class="card-body">
      <h3 class="text-muted text-center" v-if="!checks.length">No Chapel Checks</h3>
      <table v-else id="chapel_list" class="table">
        <thead>
          <tr>
            <th>Date</th>
            <th>Check In</th>
            <th>Check Out</th>
            <th>Fine</th>
            <th>Excused?</th>
            <th>Counts as Skip?</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="check in checks"
            :class="['chapel_row', checkColor(check)]"
          >
            <td class="chapel_date_column">{{ check.chapelDate }}</td>
            <td class="chapel_checkin_column">{{ check.checkIn }}</td>
            <td class="chapel_checkout_column">{{ check.checkOut }}</td>
            <td class="chapel_fine_column">
              {{ filters.dollars(check.fineAmount) }}
            </td>
            <td class="chapel_excused_column">{{ check.excused }}</td>
            <td class="chapel_skip_column">
              {{ check.skip }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup lang="ts">
import { filters } from "@/utilities";

import { ChapelCheck } from "../types";

interface Props {
  checks: ChapelCheck[];
}

defineProps<Props>();

const checkColor = (check: ChapelCheck) => {
  switch (check.resultType) {
    case "fine":
      return "table-danger";
    case "skip":
      return "table-warning";
    case "excused":
      return "table-info";
    default:
      return "";
  }
};
</script>

<style lang="scss" scoped>
.chapel_row:nth-child(2n) {
  background-color: lightgrey;
}
</style>
