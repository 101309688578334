<template>
  <div>
    <template v-if="isNotRequested">
      <p>
        <strong>Room alone request status:</strong>
      </p>
      <p>{{ store.roomAlone.status }}</p>
      <button v-if="isRequested" class="btn btn-primary" @click="cancel">
        Cancel Request
      </button>
    </template>
    <template v-else>
      <p>
        <strong>
          During the Fall term single occupancy is rarely available. Rent is 1.5
          times the regular room rate and requires parent approval. Preference
          is given to residents with the most credit hours completed. Housing
          will communicate with you regarding your request.
        </strong>
      </p>
      <form @submit.prevent="submit">
        <base-check-box
          v-model="form.agreed"
          name="agreement"
          :form-name="formName"
          :rules="rules.agreement"
          label="I understand and agree to the terms above"
          div-class="mb-3"
        />
        <button type="submit" class="btn btn-primary mb-3">
          Request Single Occupancy
        </button>
      </form>
      <button
        class="btn btn-secondary d-block"
        @click="store.updateFormType(null)"
      >
        Go Back
      </button>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive, watch } from "vue";
import { mixed } from "yup";

import { useForm } from "@/utilities/validations";

import { useStore } from "../store";

const store = useStore();

const isRequested = computed(() => store.roomAlone.status === "Requested");
const isNotRequested = computed(
  () => store.roomAlone.status !== "Not Requested"
);

const formName = "room-alone-form";
const { submit: submitFn, reset } = useForm(formName);
const rules = {
  agreement: mixed().oneOf([true], "required"),
};

const form = reactive({ agreed: false });

const submit = submitFn(async () => {
  await store.requestRoomAlone();
});

const cancel = () => {
  store.cancelRoomAlone();
  form.agreed = false;
  reset();
};

watch(
  () => form.agreed,
  () => reset()
);
</script>
