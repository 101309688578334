import { computed } from "vue";
import { array, boolean, number, string } from "yup";

import { phoneNumber } from "@/utilities/validators";

import { ApplicationPriority, FieldSpec } from "./types";

export function useValidations(
  fieldSpecs: Record<string, FieldSpec>,
  hasPriorities: boolean
) {
  const rules = computed(() => {
    const possibleRules = {
      schoolid: string().nullable(),
      firstName: string().nullable(),
      middleName: string().nullable(),
      lastName: string().nullable(),
      gender: string().nullable(),
      dateOfBirth: string().nullable(),
      highschool: string().nullable(),
      classification: string().nullable(),
      email: string().email().nullable(),
      homephone: phoneNumber(),
      cellphone: phoneNumber(),
      allowSms: boolean().nullable(),
      summerphone: phoneNumber(),
      arrivalDt: string().nullable(),
      hasTransport: boolean().nullable(),

      major: string().nullable(),
      classLoad: string().nullable(),
      study: string().nullable(),
      neatness: string().nullable(),
      bedtime: string().nullable(),
      waketime: string().nullable(),
      lifestyle: string().nullable(),
      religious: string().nullable(),
      hobbyIds: array().of(number()).nullable(),
      likedMusicIds: array().of(number()).nullable(),
      musicTime: string().nullable(),
      possessedQualityIds: array().of(number()).nullable(),

      otherPrefs: string().nullable(),

      homeAddress1: string().nullable(),
      homeAddress2: string().nullable(),
      homeCity: string().nullable(),
      homeState: string().nullable(),
      homePostal: string().nullable(),
      homeCountry: string().nullable(),
    };

    const innerRules = [];
    Object.entries(fieldSpecs).forEach(([name, spec]) => {
      let rule = possibleRules[name as keyof typeof possibleRules];

      if (spec.formState === "Required") {
        rule = rule.required("required");
      }

      innerRules.push([name, rule]);
    });

    type Priorities = [
      ApplicationPriority | undefined,
      ApplicationPriority | undefined,
      ApplicationPriority | undefined
    ];

    if (hasPriorities) {
      innerRules.push([
        "priorities",
        [
          (val: Priorities) =>
            (!val.includes(null) && !val.includes(undefined)) || "required",
        ],
      ]);
    }

    return Object.fromEntries(innerRules);
  });

  return { rules };
}
