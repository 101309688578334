<template>
  <div class="card">
    <div class="card-body">
      <form @submit.prevent="submit">
        <student-info-form
          v-model="form"
          :email="email"
          :form-name="formName"
          :rules="studentInfoRules"
        />
        <base-submit-button text="Submit" class="btn btn-primary mt-3" />
      </form>
    </div>
  </div>
</template>

<script setup lang="ts">
import { update } from "lodash/fp";
import { ref } from "vue";

import { ApiInjectionKey, safeInject, stripPhoneNumber } from "@/utilities";
import { useForm } from "@/utilities/validations";

import { StudentInfo } from "../components/StudentInfoForm";
import StudentInfoForm from "../components/StudentInfoForm/Index.vue";
import { rules } from "../components/StudentInfoForm/rules";

interface Props {
  email: string;
  studentId: number;
  modelValue: StudentInfo;
}

const props = defineProps<Props>();

const api = safeInject(ApiInjectionKey);

const form = ref({
  ...props.modelValue,
  email2: props.modelValue.email2 || "",
  cellphone: props.modelValue.cellphone || "",
});

const { formName, submit: submitFn } = useForm("student-info");

const studentInfoRules = rules();

const sanatize = update("cellphone", stripPhoneNumber);

const submit = submitFn(async () => {
  await api.students.update({
    params: { id: props.studentId },
    data: sanatize(form.value),
  });

  location.assign(api.students.list.path());
});
</script>
