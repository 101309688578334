// JsFromRoutes CacheKey 8040a0d49fe309d6b8358d953bdedb91
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const students = {
  list: definePathHelper("get", "/students"),
  edit: definePathHelper("get", "/students/:id/edit"),
  update: definePathHelper("patch", "/students/:id"),
};
