<template>
  <card-to-mobile-layout title="Transportation">
    <div class="row">
      <base-textfield
        v-model="transport"
        div-class="col-md-6 col-12 mb-3"
        label="Method of Transportation"
        name="transport"
        :rules="rules.transport"
        :form-name="formName"
      />
      <base-textfield
        v-model="driver"
        optional
        div-class="col-md-6 col-12 mb-3"
        label="Driver"
        name="driver"
      />
      <base-textfield
        v-model="carOwner"
        optional
        div-class="col-md-6 col-12 mb-3"
        label="Car Owner"
        name="carOwner"
      />
      <base-textfield
        v-model="companions"
        optional
        div-class="col-md-6 ol-12 mb-3"
        label="Traveling Companions"
        name="companions"
      />
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { toRefs } from "vue";

import { computedModels } from "@/utilities";

import * as HolidayLeave from "../types/holidayLeave";
import { Rules } from "../useValidations";

interface Props {
  modelValue: HolidayLeave.Other;
  rules: Rules;
  formName: string;
}

interface Emits {
  (e: "update:modelValue", value: HolidayLeave.Other): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const modelValue = toRefs(props).modelValue;
const { transport, driver, carOwner, companions } = computedModels(
  modelValue,
  emit
);
</script>
