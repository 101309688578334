import { DateTime } from "luxon";
import { computed, reactive } from "vue";

import { Props } from "./Index.vue";
import { Timeslot } from "./types";

export function useTimeslotSelector(
  props: Props,
  timeslots: Record<number, Record<number, Timeslot>>,
  initialDate: DateTime
) {
  const selected = reactive({
    building: props.initialBuilding,
    date: initialDate,
  });

  const activeTimeslots = computed(() => {
    const slots = Object.entries(timeslots[selected.building]).filter(
      ([_, value]) =>
        value.startDt.hasSame(selected.date, "day") &&
        value.startDt.hasSame(selected.date, "month") &&
        value.startDt.hasSame(selected.date, "year")
    );

    return Object.fromEntries(slots);
  });

  return {
    selected,
    activeTimeslots,
  };
}
