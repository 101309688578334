<template>
  <v-dialog
    v-model:visible="open"
    modal
    :show-header="false"
    content-class="rounded bg-primary"
  >
    <section class="text-center">
      <p class="text-light h4">What are your plans for</p>
      <h1 class="text-light">{{ name }}?</h1>
      <a id="tell-us" class="btn btn-light" :href="url">Tell Us</a>
    </section>
    <section class="pt-3 text-end">
      <button class="btn btn-link" @click="open = false">
        No thanks, I'll tell you later
      </button>
    </section>
  </v-dialog>
</template>

<script lang="ts">
import Dialog from "primevue/dialog";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FuturePlansModal",
  components: { VDialog: Dialog },
  props: {
    name: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  setup() {
    const open = ref(true);

    return { open };
  },
});
</script>

<style lang="scss" scoped>
#tell-us {
  width: 200px;
  height: 40px;
}

.btn-link {
  font-size: 0.875em;
  &:hover {
    color: white;
  }
  color: white;
  text-decoration: none;
}

.p-dialog {
  text-align: center;
  padding: 0 !important;
}
</style>
