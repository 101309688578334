// JsFromRoutes CacheKey 004febe7247a06e4bce3717f183e5c0b
//
// DO NOT MODIFY: This file was automatically generated by JsFromRoutes.
import { definePathHelper } from "@js-from-routes/axios";

export const roommateGroups = {
  leaveGroup: definePathHelper("post", "/roommate_groups/:roommate_group_id/leave_group"),
  complete: definePathHelper("put", "/roommate_groups/:roommate_group_id/complete"),
  list: definePathHelper("get", "/roommate_groups"),
  new: definePathHelper("get", "/roommate_groups/new"),
  invite: definePathHelper("post", "/roommate_groups/invite"),
  findStudentFromEmail: definePathHelper("get", "/roommate_groups/find_student_from_email"),
  groupsForStudent: definePathHelper("get", "/roommate_groups/groups_for_student"),
  acceptInvitation: definePathHelper("put", "/roommate_groups/accept_invitation"),
  declineInvitation: definePathHelper("put", "/roommate_groups/decline_invitation"),
  resendEmail: definePathHelper("put", "/roommate_groups/resend_email"),
  updateAutoAssigned: definePathHelper("put", "/roommate_groups/update_auto_assigned"),
  requestRoomAlone: definePathHelper("put", "/roommate_groups/request_room_alone"),
  cancelRoomAlone: definePathHelper("put", "/roommate_groups/cancel_room_alone"),
  connect: definePathHelper("get", "/roommate_groups/connect/:id"),
};
