<template>
  <toast :base-z-index="1032" />
  <div class="page-header">
    <h1>Holiday Plans for {{ holidayName }}</h1>
  </div>
  <p>{{ holidayComments }}</p>
  <form @submit.prevent="submit">
    <div class="row">
      <div class="col-12 mb-4">
        <leave-choice
          v-model="form.holidayPlans"
          :form-name="formName"
          :rules="rules"
        />
      </div>
      <fade-transition>
        <div v-if="isHomeLeave(form) || isOtherLeave(form)" class="col-12 mb-4">
          <leave-dates-form
            v-model:leaveDepart="form.leaveDepart"
            v-model:leaveReturn="form.leaveReturn"
            :min-date="minDate"
            :max-date="maxDate"
            :rules="rules"
            :form-name="formName"
          />
        </div>
      </fade-transition>
      <fade-transition>
        <div v-if="isOtherLeave(form)">
          <destination-form
            v-model="form"
            class="col-12 mb-4"
            :states="states"
            :rules="rules"
            :form-name="formName"
          />
          <transportation-form
            v-model="form"
            class="col-12 mb-4"
            :rules="rules"
            :form-name="formName"
          />
          <other-form
            v-model="form"
            class="col-12 mb-4"
            :rules="rules"
            :form-name="formName"
          />
        </div>
      </fade-transition>
      <div class="col-12 mb-4 d-flex">
        <base-submit-button
          class="btn btn-primary btn-lg flex-grow-1 flex-md-grow-0"
          :loading="loading"
        />
      </div>
    </div>
  </form>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
import { Ref, ref } from "vue";

import { fromRailsDateTime } from "@/utilities";
import { useForm } from "@/utilities/validations";

import DestinationForm from "./components/DestinationForm.vue";
import LeaveChoice from "./components/LeaveChoice.vue";
import LeaveDatesForm from "./components/LeaveDatesForm.vue";
import OtherForm from "./components/OtherForm.vue";
import TransportationForm from "./components/TransportationForm.vue";
import { Leave } from "./types";
import { HolidayLeave, isHomeLeave, isOtherLeave } from "./types/holidayLeave";
import { useReset } from "./useReset";
import { useSubmission } from "./useSubmission";
import { useValidations } from "./useValidations";

export interface Props {
  states: { label: string; value: string }[];
  minDate: string;
  maxDate: string;
  prevLeave?: Leave | null;
  holidayName?: string;
  holidayComments?: string;
  holidayId: number;
}

const props = withDefaults(defineProps<Props>(), {
  prevLeave: null,
  holidayName: "",
  holidayComments: "",
});

const minDate = fromRailsDateTime(props.minDate);
const maxDate = fromRailsDateTime(props.maxDate);

const form: Ref<HolidayLeave> = ref({ holidayPlans: null });

const { rules } = useValidations(form);
const formName = "holiday-leave-form";
const {
  reset: resetForm,
  submit: submitForm,
  setFieldErrorMessage,
} = useForm(formName);

useReset(form, props.holidayId, resetForm);

const { submit, loading } = useSubmission(
  form,
  submitForm,
  setFieldErrorMessage
);
</script>
