<template>
  <article
    style="max-width: 1000px; max-height: 1000px"
    class="card room-selector"
  >
    <div class="floor-selector">
      <template v-for="building in buildings" :key="building.id">
        <h2 class="floor-selector__title">{{ building.name }}</h2>
        <ul class="options-list">
          <li
            v-for="option in building.floorPlans.map((x) => x.floor)"
            :key="option"
            :class="[
              {
                selected:
                  selected != null &&
                  selected.floor === option &&
                  selected.building === building.id,
              },
              'options-list__item',
            ]"
            @click="selected = { building: building.id, floor: option }"
          >
            {{ option }}
          </li>
        </ul>
      </template>
    </div>
    <div class="mobile-selectors">
      <div class="mb-2">
        <label class="form-label">Building</label>
        <select
          :value="selected ? selected.building : null"
          class="form-control"
          @input="changeBuilding(($event.target as HTMLSelectElement).value)"
        >
          <option
            v-for="building in buildings"
            :key="building.id"
            :value="building.id"
          >
            {{ building.name }}
          </option>
        </select>
      </div>
      <div>
        <label class="form-label">Floor</label>
        <select v-if="selected" v-model="selected.floor" class="form-control">
          <option
            v-for="option in currentFloorPlans"
            :key="option"
            :value="option"
          >
            {{ option }}
          </option>
        </select>
      </div>
    </div>
    <div class="canvas-container" style="position: relative">
      <room-canvas v-model="selectedRoom" :floor-plan="selectedFloor" />
    </div>
  </article>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";

import { Building } from "../../types";
import RoomCanvas from "./components/RoomCanvas";
import { AbodeFloorDiagram } from "./components/RoomCanvas/types";

interface Props {
  buildings?: Building[];
  modelValue?: number | null;
}

const props = withDefaults(defineProps<Props>(), {
  buildings: () => [],
  modelValue: null,
});

interface Emits {
  (e: "update:modelValue", payload: number | null): void;
}

const emit = defineEmits<Emits>();

const selectedRoom = computed({
  get: () => props.modelValue,
  set: (val) => emit("update:modelValue", val),
});

const selected = ref(
  props.buildings.length !== 0
    ? {
        building: props.buildings[0].id,
        floor: props.buildings[0].floorPlans[0].floor,
      }
    : null
);

const selectedFloor = computed(
  () =>
    props.buildings
      .find((x) => x.id === selected.value?.building)
      ?.floorPlans.find(
        (x) => x.floor === selected.value?.floor
      ) as AbodeFloorDiagram
);

const changeBuilding = (buildingId: string) => {
  const building = props.buildings.find((x) => x.id === parseInt(buildingId));
  selected.value = {
    building: building?.id || 0,
    floor: props.buildings[0].floorPlans[0].floor,
  };
};

const currentFloorPlans = computed(
  () =>
    props.buildings
      .find((x) => x.id === selected.value?.building)
      ?.floorPlans.map((x) => x.floor) || []
);
</script>

<style lang="scss" scoped>
h1 {
  font-size: 2rem;
  margin: unset;
}

article {
  padding: unset;
  margin: unset;
}

.room-selector {
  display: flex;
  flex-direction: column;
}

.floor-selector {
  display: none;
  max-width: 16rem;
  overflow: hidden;
  flex-direction: column;

  &__title {
    padding: 1rem;
    margin: unset;
  }
}

.canvas-container {
  flex: 1;
  overflow: hidden;
  padding: 0.25rem;
  border-top: 1px solid #ddd;
  border-left: 0;
}

.options-list {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 10rem;
  padding: 0 1rem 0 1rem;

  &__item {
    font-size: 1.5rem;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid #ddd;
    font-weight: bold;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-content: center;
    min-width: 5rem;
    margin: 0 0 0.5rem 0;

    &.selected,
    &.selected:hover {
      background-color: var(--bs-primary);
      color: white;
    }

    &:hover {
      background-color: #ddd;
    }
  }
}

.mobile-selectors {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
}

@media only screen and (min-width: 768px) {
  .mobile-selectors {
    display: none;
  }

  .room-selector {
    flex-direction: row;
  }

  .canvas-container {
    border-top: 0;
    border-left: 1px solid #ddd;
  }

  .floor-selector {
    display: flex;
  }
}
</style>
