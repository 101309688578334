<template>
  <div v-html="modelValue.contract.html"></div>

  <base-check-box
    v-model="mailbox"
    name="mailbox"
    label="Yes, I confirm that I have received my mailbox key."
    single
    :form-name="formName"
    :rules="rules.mailbox"
    div-class="mb-3"
  />

  <base-check-box
    v-model="inspection"
    name="inspection"
    label="I understand that I must complete a room inspection within 48 hours of moving into my room and report any problems. Otherwise, I understand and acknowledge that my room and all items in my room will be assumed to be in good condition."
    single
    :form-name="formName"
    :rules="rules.inspection"
    div-class="mb-3"
  />

  <base-check-box
    v-model="searchRules"
    name="searchRules"
    label="The University reserves the right for the residence hall dean, his or her representative or a University security officer to enter and inspect the resident’s room at any time, with or without notice to resident, and with or without resident being present for purposes of management, health, safety, maintenance and to investigate and assess resident’s compliance with University rules and regulations. Resident acknowledges that acts by University do not violate resident’s reasonable expectation of privacy. The University reserves the right to seize and remove any items from resident’s room (or anywhere else on University property) that do not comply with school policy. The resident, by accepting a room in one of the University residences, acknowledges, and allows the right of the University administration as specified in this agreement."
    single
    :form-name="formName"
    :rules="rules.searchRules"
    div-class="mb-3"
  />

  <base-check-box
    v-if="age < 18"
    v-model="underage"
    name="underAge"
    label="Resident acknowledges that since the resident is underage, a parent or guardian must also sign this agreement at the residence hall prior to move in."
    single
    :form-name="formName"
    :rules="rules.underage"
    div-class="mb-3"
  />

  <p>
    I, {{ studentName }}, agree to the above. Type your full name
    below to agree to the terms and conditions above.
  </p>

  <base-textfield
    v-model="signature"
    div-class="mt-3 w-100"
    name="signature"
    placeholder="Signature"
    :form-name="formName"
    :rules="rules.signature"
  />
</template>

<script lang="ts">
export default {
  name: "MoveIn",
};
</script>

<script setup lang="ts">
import { toRefs } from "vue";
import { string } from "yup";

import { computedModels } from "@/utilities";
import { Validators } from "@/utilities/validations";

import { MoveInForm } from "../types";

interface Props {
  modelValue: MoveInForm;
  formName: string;
  studentName: string;
}

interface Emits {
  (e: "update:modelValue", payload: MoveInForm): void;
}

const props = defineProps<Props>();
const { modelValue } = toRefs(props);

const emit = defineEmits<Emits>();

const { age, mailbox, inspection, searchRules, underage, signature } =
  computedModels(modelValue, emit);

const mustBeTrue: Validators<unknown> = [
  (v) => !!v || "You must agree to the terms above",
];

const rules = {
  mailbox: mustBeTrue,
  inspection: mustBeTrue,
  searchRules: mustBeTrue,
  underage: mustBeTrue,
  signature: string()
    .nullable()
    .required("required")
    .oneOf([props.studentName], "Your signature does not match"),
};
</script>
