<template>
  <v-dialog
    :style="{
      'max-width': '60ch',
      'font-size': '14px',
      overflow: 'hidden',
    }"
    :show-header="false"
    :closable="false"
    :modal="true"
  >
    <section v-if="student !== null" class="card__body">
      <h1>{{ fullname(student) }}</h1>
      <dl>
        <template v-for="field in fields" :key="field.value">
          <template v-if="student != null && student[field.value] && student[field.value].length">
            <dt>{{ field.name }}</dt>
            <dd v-if="typeof student[field.value] === 'object'">
              <ul>
                <li v-for="item in student[field.value]" :key="item">
                  {{ item }}
                </li>
              </ul>
            </dd>
            <dd v-else>
              {{ student[field.value] }}
            </dd>
          </template>
        </template>
      </dl>
    </section>
    <template #footer>
      <button class="btn btn-primary" @click="$emit('close')">close</button>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { Student } from "../types";
import VDialog from "primevue/dialog";

withDefaults(
  defineProps<{
    student?: Student | null;
  }>(),
  { student: null }
);

defineEmits<{
  (e: "close"): void;
}>();

const fields = [
  { name: "Email", value: "email" },
  { name: "Class", value: "classification" },
  { name: "Major", value: "major" },
  { name: "Status", value: "studentStatus" },
  { name: "I keep my room", value: "neatness" },
  { name: "Expected Course Load", value: "classLoad" },
  { name: "My lifestyle attitude is", value: "lifestyle" },
  { name: "Approximate Bedtime", value: "bedTime" },
  { name: "Approximate Waketime", value: "wakeTime" },
  { name: "Room Tempature", value: "room_temp" },
  { name: "I prefer to sleep with windows", value: "windows" },
  { name: "I tend to sleep", value: "sleep_level" },
  { name: "I enjoy music", value: "musicTime" },
  { name: "Music Likes", value: "likedMusic" },
  { name: "Music Dislikes", value: "dislikedMusic" },
  { name: "Hobbies", value: "hobbies" },
  { name: "Qualities I'm looking for", value: "desiredQualities" },
  { name: "Qualities I have", value: "possessedQualities" },
  { name: "Religious Belief", value: "religious" },
  { name: "Study Habits", value: "study" },
];

const fullname = (student: Student) =>
  `${student.firstName} ${student.firstName}`;
</script>

<style lang="scss" scoped>
dd {
  margin-bottom: 1rem;
}
</style>
