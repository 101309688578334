<template>
  <div class="row">
    <base-textfield
      v-model="address1"
      div-class="mb-3"
      label="Address"
      :name="`${name}.address1`"
      :form-name="formName"
      :rules="rules.address1"
    />
    <base-textfield
      v-model="address2"
      div-class="mb-3"
      optional
      label="Address 2"
      :name="`${name}.address2`"
      :form-name="formName"
      :rules="rules.address2"
    />
    <base-textfield
      v-model="city"
      div-class="mb-3 col-md-6"
      label="City"
      :name="`${name}.city`"
      :form-name="formName"
      :rules="rules.city"
    />
    <base-dropdown
      v-model="state"
      div-class="mb-3 col-md-3"
      label="State"
      :name="`${name}.state`"
      :options="states"
      option-label="label"
      option-value="value"
      placeholder="Select State"
      :form-name="formName"
      :rules="rules.state as Validators<unknown>"
    />
    <base-textfield
      v-model="postal"
      div-class="mb-3 col-md-3"
      label="Postal/ZIP code"
      :name="`${name}.postal`"
      :form-name="formName"
      :rules="rules.postal"
    />
    <base-textfield
      v-model="country"
      div-class="mb-3"
      label="Country"
      :name="`${name}.country`"
      :form-name="formName"
      :rules="rules.country"
    />
  </div>
</template>

<script setup lang="ts">
import { toRefs } from "vue";

import { computedModels } from "@/utilities";
import type { Validators } from "@/utilities/validations";

export interface Address {
  address1: string;
  address2: string;
  city: string;
  state: string;
  country: string;
  postal: string;
}

export interface AddressRules {
  address1: Validators<string | null>;
  address2: Validators<string | null>;
  city: Validators<string | null>;
  state: Validators<string | null>;
  country: Validators<string | null>;
  postal: Validators<string | null>;
}

interface Props {
  name: string;
  modelValue: Address;
  states: { label: string; value: string }[];
  rules?: AddressRules;
  formName?: string;
}

const props = withDefaults(defineProps<Props>(), {
  rules: () => ({
    address1: [],
    address2: [],
    city: [],
    state: [],
    country: [],
    postal: [],
  }),
});

interface Emits {
  (e: "update:modelValue", payload: Address): void;
}

const emit = defineEmits<Emits>();
const { modelValue } = toRefs(props);

const { address1, address2, city, state, country, postal } = computedModels(
  modelValue,
  emit
);
</script>
