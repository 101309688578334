import { Student } from "./types";
import { ref, Ref } from "vue";

export function useShowMore(selectedStudent: Ref<Student | null>) {
  const visible = ref(false);

  const open = (student: Student) => {
    selectedStudent.value = student;
    visible.value = true;
  };

  const close = () => {
    selectedStudent.value = null;
    visible.value = false;
  };

  return { visible, open, close };
}
