import axios from "axios";
import { useToast } from "primevue/usetoast";
import { Ref, ref, watch } from "vue";

import { ApiInjectionKey, safeInject } from "@/utilities";
import { useForm } from "@/utilities/validations";

import { Building, Reservation } from "./types";

export function useSubmission(
  form: { confirmed: boolean; selectedRoom: number | null },
  reservation: Ref<Reservation>,
  buildingsForSelection: Ref<Building[]>
) {
  const api = safeInject(ApiInjectionKey);

  const toast = useToast();

  const loading = ref(false);

  const formName = "room-selection-form";

  const { submit: submitFn, reset } = useForm(formName);

  const submit = submitFn(
    async () => {
      try {
        loading.value = true;

        const res = await api.roomSelections.create({
          data: { roomId: form.selectedRoom },
        });

        reservation.value = res;
      } catch (e) {
        if (axios.isAxiosError(e) && e.response) {
          const errors: string[] = e.response.data.errors;

          toast.add({
            summary: "Error",
            detail: errors[0],
            severity: "error",
            life: 5000,
          });

          buildingsForSelection.value = e.response.data.buildings;
        }
      } finally {
        loading.value = false;
      }
    },
    () => {
      toast.add({
        summary: "Error",
        detail: "You must confirm your selection before submitting",
        severity: "error",
        life: 5000,
      });
    }
  );

  watch(
    () => form.selectedRoom,
    () => {
      form.confirmed = false;
      reset();
    }
  );

  return { submit, loading, formName };
}
