import { useToast } from "primevue/usetoast";
import { ref } from "vue";

import { useForm } from "@/utilities/validations";

import { Props } from "./Index.vue";
import { Leave } from "./types";
import { useRails } from "./useRails";

export function useSubmission(form: Leave, props: Props) {
  const isLoading = ref(false);

  const formName = "leave-form";
  const { setFieldErrorMessage, submit: formSubmit } = useForm(formName);

  const toast = useToast();

  const { sendLeave } = useRails(props.formType);

  const showError = () =>
    toast.add({
      severity: "error",
      summary: "Errors in form",
      detail: "You seem to have a few errors in your form",
      life: 5000,
    });

  const submit = formSubmit(async () => {
    isLoading.value = true;

    const response = await sendLeave(form);

    if (response.kind == "Success") {
      window.location.assign(response.data.location);
    } else {
      Object.entries(response.data).forEach(([key, val]) =>
        setFieldErrorMessage(key, (val as string[]).join(", "))
      );
      showError();
    }

    isLoading.value = false;
  }, showError);

  return { submit, isLoading, formName };
}
