<template>
  <div class="card">
    <div class="card-header">Chapel Checks</div>
    <div v-if="!checks.length" class="card-body">
      <h3 class="text-muted text-center">No Chapel Checks</h3>
    </div>
    <ul v-else class="list-group list-group-flush">
      <li
        v-for="check in checks"
        :class="`
          list-group-item
          list-group-item-${checkColor(check)}
          d-flex
          align-items-center
          justify-content-between
        `"
        @click="currentCheck = check"
      >
        <div>
          <div class="fw-bold">{{ check.chapelDate }}</div>
          {{ check.checkIn }} - {{ check.checkOut }}
        </div>
        <b-icon-chevron-right width="1.5rem" height="1.5rem" />
      </li>
    </ul>
    <Dialog
      modal
      style="width: 80%"
      :visible="!!currentCheck"
      :closable="false"
      header="Chapel Check"
    >
      <dl>
        <dt>Date</dt>
        <dd>{{ currentCheck?.chapelDate }}</dd>
        <dt>Check In</dt>
        <dd>{{ currentCheck?.checkIn }}</dd>
        <dt>Check Out</dt>
        <dd>{{ currentCheck?.checkOut }}</dd>
        <dt>Fine</dt>
        <dd>
          {{
            currentCheck?.fineAmount
              ? filters.dollars(currentCheck?.fineAmount)
              : ""
          }}
        </dd>
        <dt>Excused?</dt>
        <dd>{{ currentCheck?.excused }}</dd>
        <dt>Counts as Skip?</dt>
        <dd>
          {{ currentCheck?.skip }}
        </dd>
      </dl>
      <template #footer>
        <button class="btn btn-secondary" @click="currentCheck = null">
          Close
        </button>
      </template>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import { BIconChevronRight } from "bootstrap-icons-vue";
import Dialog from "primevue/dialog";
import { Ref, ref } from "vue";

import { filters } from "@/utilities";

import { ChapelCheck } from "../types";

interface Props {
  checks: ChapelCheck[];
}

defineProps<Props>();

const currentCheck: Ref<ChapelCheck | null> = ref(null);

const checkColor = (check: ChapelCheck) => {
  switch (check.resultType) {
    case "fine":
      return "danger";
    case "skip":
      return "warning";
    case "excused":
      return "info";
    default:
      return "";
  }
};
</script>
