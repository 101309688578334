<template>
  <fieldset>
    <legend :class="{ optional: optional }">{{ title }}</legend>
    <base-textfield
      v-model="contact.contactName"
      div-class="mb-3"
      label="Name"
      help-text="Include first and last name"
      :form-name="formName"
      :name="`${name}.contactName`"
      :rules="rules.contactName"
    />
    <base-dropdown
      ref="contactRelationDropdown"
      v-model="contact.contactRelation"
      div-class="mb-3"
      label="Relationship"
      :name="`${name}.contactRelation`"
      :options="relations"
      option-label="label"
      option-value="value"
      editable
      :form-name="formName"
      :rules="rules.contactRelation as any"
      @click="openDropdown"
    />
    <base-address
      v-model="address"
      :name="name"
      :states="states"
      :rules="rules"
      :form-name="formName"
    />
    <emergency-contact-phone
      v-model:phone="contact.phone1"
      v-model:phoneType="contact.phone1Type"
      title="Phone Number 1"
      :name="`${name}.phone1`"
      :rules="rules.phone1"
      :form-name="formName"
    />
    <emergency-contact-phone
      v-model:phone="contact.phone2"
      v-model:phoneType="contact.phone2Type"
      title="Phone Number 2"
      :name="`${name}.phone2`"
      :rules="rules.phone2"
      :form-name="formName"
    />
    <emergency-contact-phone
      v-model:phone="contact.phone3"
      v-model:phoneType="contact.phone3Type"
      title="Phone Number 3"
      :name="`${name}.phone3`"
      :rules="rules.phone3"
      :form-name="formName"
    />
  </fieldset>
</template>

<script setup lang="ts">
import { FilterService } from "primevue/api";
import { computed, reactive, ref, toRefs } from "vue";

import { Address } from "@/shared/BaseAddress.vue";
import { computedModels } from "@/utilities";

import { ContactValidator, EmergencyContact } from "../types";
import EmergencyContactPhone from "./EmergencyContactPhone.vue";

const contactRelationDropdown = ref();
const openDropdown = () => {
  contactRelationDropdown.value.openDropdown();
};

interface Props {
  title: string;
  formName: string;
  modelValue: EmergencyContact;
  name: string;
  rules: ContactValidator;
  states: { label: string; value: string }[];
  optional?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  optional: false,
});

interface Emits {
  (e: "update:modelValue", payload: EmergencyContact): void;
}

const emit = defineEmits<Emits>();

const { modelValue } = toRefs(props);
const contact = reactive(computedModels(modelValue, emit));

const address = computed({
  get() {
    return {
      address1: contact.address1,
      address2: contact.address2,
      city: contact.city,
      state: contact.state,
      country: contact.country,
      postal: contact.postal,
    };
  },
  set(v: Address) {
    emit("update:modelValue", {
      ...props.modelValue,
      address1: v.address1,
      address2: v.address2,
      city: v.city,
      state: v.state,
      country: v.country,
      postal: v.postal,
    });
  },
});

const relations = computed(() =>
  ["Uncle", "Aunt", "Parent", "Sibling", "Grand Parent"]
    .filter((v) =>
      //@ts-ignore
      FilterService.filters.contains(v, contact.contactRelation)
    )
    .map((name) => ({ label: name, value: name }))
);
</script>

<style lang="scss" scoped></style>
