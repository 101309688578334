<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :style="divStyle"
    :optional="optional"
    isRadio
    v-bind="fieldOptions"
  >
    <template v-for="option in options" :key="option[optionLabel]">
      <div
        class="base-radio__option"
        :class="[{ 'is-invalid': showError }, 'form-check']"
      >
        <input
          v-bind="$attrs"
          :id="createOptionId(option)"
          :name="name"
          type="radio"
          :checked="
            isEqual(modelValue, returnObject ? option : option[optionValue])
          "
          :class="{
            'form-check-input': true,
            'is-invalid': showError,
          }"
          @input="model = getValue(option)"
        />
        <label :for="createOptionId(option)" class="form-check-label">
          {{ option[optionLabel] }}
        </label>
        <slot
          v-if="isEqual(modelValue, returnObject ? option : option[optionValue])"
          name="optionHelp"
          :option="option"
        />
      </div>
    </template>
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  name: "BaseRadio",
  inheritAttrs: false,
};
</script>

<script lang="ts" setup>
import isEqual from "lodash/isEqual";
import { computed, Ref, toRefs, watch } from "vue";

import { computedModel } from "@/utilities";
import { useField, Validators } from "@/utilities/validations";

type ModelValue =
  | string
  | number
  | boolean
  | { [name: string]: unknown }
  | null;
type Option = { [name: string]: unknown };

interface Props {
  name: string;
  id?: string;
  modelValue?: ModelValue;

  options: Option[];
  optionLabel?: string;
  optionValue?: string;
  optionHelp?: string;
  returnObject?: boolean;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;
  divStyle?: string | object;
  fieldOptions?: Record<string, unknown>;

  // Validation Props
  rules?: Validators<any>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  optionLabel: "label",
  optionValue: "value",
  optionHelp: "help",
  modelValue: null,
  returnObject: false,
  fieldOptions: () => ({}),
  rules: () => [],
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();

const innerId = computed(() => props.id || props.name);

const { modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const getValue = (option: Option): ModelValue =>
  (props.returnObject ? option : option[props.optionValue]) as ModelValue;

const createOptionId = (option: Option) =>
  `${props.name}_${option[props.optionValue]}`.toLowerCase();

const rules = toRefs(props).rules as Ref<Validators<ModelValue>>;

const { errorMessage, touched, showError, validate } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

watch(model, () => {
  validate();
});
</script>
