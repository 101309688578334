import "primeicons/primeicons.css";
import "primevue/resources/primevue.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";

import PrimeVue from "primevue/config";
import ToastService from "primevue/toastservice";
import { createApp } from "vue";

import api from "@/api";
import { ApiInjectionKey, testDirective } from "@/utilities";

import { globals } from "./plugins/globals";
import { http } from "./plugins/http";
import { pinia } from "./plugins/pinia";

function app() {
  return createApp({})
    .provide("http", http)
    .provide(ApiInjectionKey, api)
    .use(PrimeVue)
    .use(ToastService)
    .use(pinia)
    .use(globals)
    .directive("test", testDirective)
    .mount("[data-behavior='vue']");
}

document.addEventListener("DOMContentLoaded", app);
