<template>
  <div>
    <table class="table mb-0 table-flush align-middle d-none d-md-table">
      <thead>
        <tr>
          <th scope="col">Invited By</th>
          <th scope="col">Roommate Code</th>
          <th v-if="includeActions" scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="group in invitations">
          <td>
            {{ group.invitedBy }}
          </td>
          <td>
            {{ group.roommateGroupCode }}
          </td>
          <td v-if="includeActions" style="width: 1%; white-space: nowrap">
            <button class="btn btn-primary" @click="viewGroup(group)">
              <div class="d-flex">
                <b-icon-three-dots-vertical
                  width="1.5rem"
                  height="1.5rem"
                  class="me-3"
                />
                View
              </div>
            </button>
            <button
              class="btn btn-success ms-2"
              @click="$emit('accept-invitation', group.roommateGroupCode)"
            >
              <b-icon-check-lg width="1.5rem" height="1.5rem" class="me-3" />
              Accept
            </button>
            <button
              class="btn btn-secondary ms-2"
              @click="$emit('decline-invitation', group.roommateGroupCode)"
            >
              <b-icon-x-lg width="1.5rem" height="1.5rem" class="me-3" />
              Decline
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <ul class="list-group list-group-flush d-block d-md-none">
      <li
        v-for="group in invitations"
        class="
          list-group-item
          d-flex
          align-items-center
          justify-content-between
        "
        @click="viewGroup(group)"
      >
        <div>
          <h5 class="mb-2">
            {{ group.invitedBy }}
          </h5>
          <p class="mb-3 text-muted">
            Roommate Code: {{ group.roommateGroupCode }}
          </p>
        </div>
        <div v-if="includeActions">
          <b-icon-chevron-right width="1.5rem" height="1.5rem" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
import {
  BIconCheckLg,
  BIconChevronRight,
  BIconThreeDotsVertical,
  BIconXLg,
} from "bootstrap-icons-vue";

import { RoommateGroupType } from "@/views/RoommateGroups/New/types";

interface Props {
  invitations: (RoommateGroupType & { invitedBy: string })[];
  includeActions?: boolean;
}

interface Emits {
  (e: "accept-invitation", value: string): void;
  (e: "decline-invitation", value: string): void;
  (e: "view-group", value: RoommateGroupType & { invitedBy: string }): void;
}

const props = withDefaults(defineProps<Props>(), {
  invitations: () => [],
  includeActions: false,
});

const emit = defineEmits<Emits>();

const viewGroup = (group: RoommateGroupType & { invitedBy: string }) => {
  if (props.includeActions) {
    emit("view-group", group);
  }
};
</script>
