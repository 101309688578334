import { DateTime } from "luxon";
import { isRef } from "vue";
import { Ref } from "vue";

export interface NotChosen {
  holidayPlans: null;
}

export interface Staying {
  holidayPlans: "S";
  holidayLeaveId: number;
}

export interface Home {
  holidayPlans: "H";
  holidayLeaveId: number;
  leaveDepart: DateTime | null;
  leaveReturn: DateTime | null;
}

export interface Other {
  holidayPlans: "E";
  holidayLeaveId: number;
  leaveDepart: DateTime | null;
  leaveReturn: DateTime | null;
  destination: string;
  hostRelationship: string;
  address: string;
  city: string;
  state: string | null;
  postal: string;
  transport: string;
  driver: string;
  carOwner: string;
  companions: string;
  hostphone: string;
  cellphone: string;
  reason: string;
  ccParent: string;
  otherphone: string;
}

export type HolidayLeave = NotChosen | Staying | Home | Other;

export function isStaying(
  holidayLeave: Ref<HolidayLeave>
): holidayLeave is Ref<Staying>;
export function isStaying(holidayLeave: HolidayLeave): holidayLeave is Staying;
export function isStaying(
  holidayLeave: Ref<HolidayLeave> | HolidayLeave
): holidayLeave is Ref<Staying> {
  if (isRef(holidayLeave)) {
    return holidayLeave.value.holidayPlans === "S";
  }

  return holidayLeave.holidayPlans === "S";
}

export function isHomeLeave(
  holidayLeave: Ref<HolidayLeave>
): holidayLeave is Ref<Home>;
export function isHomeLeave(holidayLeave: HolidayLeave): holidayLeave is Home;
export function isHomeLeave(
  holidayLeave: Ref<HolidayLeave> | HolidayLeave
): holidayLeave is Ref<Home> {
  if (isRef(holidayLeave)) {
    return holidayLeave.value.holidayPlans === "H";
  }

  return holidayLeave.holidayPlans === "H";
}

export function isOtherLeave(
  holidayLeave: Ref<HolidayLeave>
): holidayLeave is Ref<Other>;
export function isOtherLeave(holidayLeave: HolidayLeave): holidayLeave is Other;
export function isOtherLeave(
  holidayLeave: Ref<HolidayLeave> | HolidayLeave
): holidayLeave is Ref<Other> {
  if (isRef(holidayLeave)) {
    return holidayLeave.value.holidayPlans === "E";
  }

  return holidayLeave.holidayPlans === "E";
}
