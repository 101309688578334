<template>
  <Badge
    v-bind="$attrs"
    ref="originalBadge"
    :value="value"
    size="xlarge"
    severity="info"
    class="user-select-none"
    :style="{ opacity: dragging ? '0.5' : '1', cursor: 'grab' }"
    @mousedown="start"
    @touchstart="start"
  ></Badge>
  <teleport to="body">
    <Badge
      v-show="showing"
      ref="mirrorBadge"
      :value="value"
      size="xlarge"
      severity="info"
      class="user-select-none"
      :style="{
        position: 'fixed',
        left: 0,
        top: 0,
        'z-index': 1000,
        cursor: 'grabbing',
      }"
      @mouseup="drop"
    ></Badge>
  </teleport>
</template>

<script lang="ts">
export default { inheritAttrs: false };
</script>

<script setup lang="ts">
import Badge from "primevue/badge";
import { inject, onMounted, ref } from "vue";

import { PriorityHandlerKey } from "../usePriorities";

const props = defineProps<{ value: number }>();

const priorityHandler = inject(PriorityHandlerKey);
if (priorityHandler == null) {
  throw "Failed to inject priority handler";
}

const originalBadge = ref(null as { $el: HTMLSpanElement } | null);
const mirrorBadge = ref(null as { $el: HTMLSpanElement } | null);

const showing = ref(false);
const dragging = ref(false);

const width = ref(0);
const height = ref(0);

const onMousemove = (event: MouseEvent) => {
  move(event.clientX, event.clientY);
};

const onTouchmove = (event: TouchEvent) => {
  move(event.touches[0].clientX, event.touches[0].clientY);
};

const start = async (event: MouseEvent | TouchEvent) => {
  document.body.addEventListener("mousemove", onMousemove);
  document.body.addEventListener("touchmove", onTouchmove);
  document.body.addEventListener("touchend", drop);
  document.body.addEventListener("touchcancel", drop);
  document.body.addEventListener("mouseleave", drop);

  await priorityHandler.drag.start(props.value);
  let x = 0;
  let y = 0;

  if ((event as TouchEvent).touches !== undefined) {
    x = (event as TouchEvent).touches[0].clientX;
    y = (event as TouchEvent).touches[0].clientY;
  } else {
    x = (event as MouseEvent).clientX;
    y = (event as MouseEvent).clientY;
  }
  const tx = x - width.value / 2;
  const ty = y - height.value / 2;
  mirrorBadge.value!.$el.style.transform = `translate(${tx}px,${ty}px)`;
  dragging.value = true;
  showing.value = true;
};

const drop = async () => {
  document.body.removeEventListener("mousemove", onMousemove);
  document.body.removeEventListener("touchmove", onTouchmove);
  document.body.removeEventListener("touchend", drop);
  document.body.removeEventListener("touchcancel", drop);
  document.body.removeEventListener("mouseleave", drop);

  await priorityHandler.drag.drop();
  dragging.value = false;
  showing.value = false;
};

const move = (clientX: number, clientY: number) => {
  if (showing.value) {
    const x = clientX - width.value / 2;
    const y = clientY - height.value / 2;
    mirrorBadge.value!.$el.style.transform = `translate(${x}px,${y}px)`;
    priorityHandler.drag.move(clientX, clientY);
  }
};

onMounted(() => {
  const rect = originalBadge.value!.$el.getBoundingClientRect();
  width.value = rect.width;
  height.value = rect.height;
});
</script>
