import { DateTime } from "luxon";
import { string } from "yup";

import { phoneNumber } from "@/utilities/validators";

import { Leave } from "./types";

export function useValidations(form: Leave) {
  const rules = {
    destination: string().required("required"),
    hostRelationship: string().required("required"),
    address: string().required("required"),
    city: string().required("required"),
    state: string().nullable().required("required"),
    transport: string().required("required"),
    ccParent: string().email("must be a valid email"),
    hostphone: phoneNumber().required("required"),
    cellphone: phoneNumber().required("required"),
    reason: string().required("required"),
    leaveDepart: [(v: DateTime) => v !== null || "leave date required"],
    leaveReturn: [
      (v: DateTime) =>
        !form.leaveDepart || v !== null || "return date required",
    ],
  };

  return { rules };
}
