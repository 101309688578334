import axios from "axios";
import { camelCase } from "lodash";

import { leaves } from "@/api";
import { stripPhoneNumber, toRailsDateTime, transformKeys } from "@/utilities";

import { FormType, Leave } from "./types";

const sanatizeData = (leave: Leave) => ({
  ...leave,
  leaveDepart: leave.leaveDepart ? toRailsDateTime(leave.leaveDepart) : "",
  leaveReturn: leave.leaveReturn ? toRailsDateTime(leave.leaveReturn) : "",
  hostphone: stripPhoneNumber(leave.hostphone),
  cellphone: stripPhoneNumber(leave.cellphone),
});

type ApiResponse<Success, Error> =
  | {
      data: Success;
      kind: "Success";
    }
  | {
      data: Error;
      kind: "Error";
    };

interface SuccessData {
  location: string;
}

type ErrorData = Record<
  "leaveReturn" | "leaveDepart" | "homephone" | "cellphone",
  string[]
>;

export function useRails(formType: FormType) {
  return {
    async sendLeave(form: Leave): Promise<ApiResponse<SuccessData, ErrorData>> {
      const data = sanatizeData(form);
      try {
        return formType === "new"
          ? {
              data: await leaves.create({ data }),
              kind: "Success",
            }
          : {
              data: await leaves.update({
                params: { id: data.leaveId },
                data,
              }),
              kind: "Success",
            };
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          return {
            data: transformKeys(err.response.data, camelCase) as ErrorData,
            kind: "Error",
          };
        }

        return {
          data: {} as ErrorData,
          kind: "Error",
        };
      }
    },
  };
}
