<template>
  <div style="font-size: 1.8rem; font-weight: bold">Sorting</div>
  <base-dropdown
    v-model="selectedSort"
    name="sorting"
    :options="sortingOptions"
    show-clear
    div-class="mb-3"
    option-label="name"
    option-value="value"
  />
  <div style="font-size: 1.8rem; font-weight: bold">Filtering</div>
  <base-dropdown
    v-model="classification"
    name="classification"
    label="Class"
    :options="classificationOptions"
    show-clear
    div-class="mb-3"
  />
  <base-dropdown
    v-model="lifestyle"
    name="lifestyle"
    label="Lifestyle"
    :options="lifestyleOptions"
    show-clear
    div-class="mb-3"
  />
  <base-dropdown
    v-model="neatness"
    name="neatness"
    label="Neatness"
    :options="neatnessOptions"
    show-clear
    div-class="mb-3"
  />
  <base-dropdown
    v-model="classLoad"
    name="neatness"
    label="Expected Course Load"
    :options="classloadOptions"
    show-clear
    div-class="mb-3"
  />
</template>

<script lang="ts">
export default {
  name: "StudentControls",
};
</script>

<script setup lang="ts">
import { cloneDeep } from "lodash";
import { computed } from "vue";

interface Props {
  neatnessOptions: string[];
  lifestyleOptions: string[];
  classloadOptions: string[];
  classificationOptions: string[];
  sortingOptions: { name: string; value: string }[];
  filters: Record<string, string | null>;
  selectedSort: string | null;
}

interface Emits {
  (e: "update:filters", payload: Record<string, string | null>): void;
  (e: "update:selected-sort", payload: string | null): void;
}

const props = defineProps<Props>();
const emit = defineEmits<Emits>();

const selectedSort = computed({
  get() {
    return props.selectedSort;
  },
  set(v: string | null) {
    emit("update:selected-sort", v);
  },
});

const classification = computed({
  get() {
    return props.filters.classification;
  },
  set(v: string | null) {
    updateFilter(v, "classification");
  },
});

const lifestyle = computed({
  get() {
    return props.filters.lifestyle;
  },
  set(v: string | null) {
    updateFilter(v, "lifestyle");
  },
});

const neatness = computed({
  get() {
    return props.filters.neatness;
  },
  set(v: string | null) {
    updateFilter(v, "neatness");
  },
});

const classLoad = computed({
  get() {
    return props.filters.classLoad;
  },
  set(v: string | null) {
    updateFilter(v, "classLoad");
  },
});

const updateFilter = (value: string | null, filter: string) => {
  const f = cloneDeep(props.filters);

  f[filter] = value;

  emit("update:filters", f);
};
</script>
