<template>
  <v-dialog
    v-model:visible="open"
    modal
    :show-header="false"
    content-class="rounded bg-primary"
    style="max-width: 50ch"
    position="center"
  >
    <section class="text-center" v-html="name"></section>
    <section class="pt-3 text-start">
      <a id="tell-us" class="btn btn-light" :href="url">{{ accept }}</a>
      <button class="btn btn-link" @click="open = false">
        {{ deny }}
      </button>
    </section>
  </v-dialog>
</template>

<script setup lang="ts">
import VDialog from "primevue/dialog";
import { ref } from "vue";

interface Props {
  name: string;
  url: string;
  accept: string;
  deny: string;
}
defineProps<Props>();

const open = ref(true);
</script>

<style lang="scss" scoped>
#tell-us {
  width: 200px;
  height: 40px;
}

.btn-link {
  font-size: 0.875em;
  &:hover {
    color: white;
  }
  color: white;
  text-decoration: none;
}

.p-dialog {
  text-align: center;
  padding: 0 !important;
}
</style>
