import { useBreakpoints as vueUseBreakpoints } from "@vueuse/core";

export function useBreakpoints() {
  return vueUseBreakpoints({
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  });
}
