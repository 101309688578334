<template>
  <ul class="roommate-group-mobile list-group list-group-flush">
    <li
      v-for="member in filteredMembers"
      :key="member.id"
      class="list-group-item"
    >
      <span :style="statusColor(member.status)" class="badge mb-2">
        {{ member.status }}
      </span>
      <h4 class="list-group-item-heading">
        {{ member.name }}
      </h4>
      <div style="display: flex; justify-content: space-between">
        <div>
          <p class="roommate-group-mobile__field list-group-item-text">
            {{ member.email }}
          </p>
          <p class="roommate-group-mobile__field list-group-item-text">
            {{ formatPhone(member.cell) }}
          </p>
        </div>
        <p
          v-if="hasActions(member)"
          class="resend link-primary"
          @click="store.resendEmail(member.id)"
        >
          Resend
        </p>
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { formatPhone } from "@/utilities";

import { useStore } from "../../../store";
import { RoommateGroupMember } from "../../../types";

interface Props {
  roommateGroupCode: string;
  approvalStatus: string;
  completeStatus: boolean;
  members?: RoommateGroupMember[];
  type?: "current" | "invited" | "declined";
}

interface Emits {
  (e: "resend-email", value: number): void;
  (e: "accept-invitation", value: string): void;
  (e: "decline-invitation", value: string): void;
  (e: "invite-new-member"): void;
}

const props = withDefaults(defineProps<Props>(), {
  members: () => [],
  type: "current",
});

defineEmits<Emits>();

const store = useStore();

const statusColor = (status: string) => {
  let bg;
  if (status === "Member") {
    bg = "#468847";
  } else if (status === "Invited") {
    bg = "#3a87ad";
  } else {
    bg = "#b94a48";
  }

  return {
    "background-color": bg,
    color: "#ffffff",
  };
};

const hasActions = (member: RoommateGroupMember) => {
  return props.type === "current" && member.status === "Invited";
};

const filteredMembers = computed((): RoommateGroupMember[] => {
  if (props.type === "invited") {
    return props.members.filter((member) => member.status !== "Declined");
  } else {
    return props.members;
  }
});
</script>

<style lang="scss" scoped>
.roommate-group-mobile {
  &__field {
    font-size: small;
    opacity: 75%;
  }
}

.resend {
  margin: 0;
  cursor: pointer;
}
</style>
