<template>
  <base-field
    :name="innerId"
    :label="label"
    :class="divClass"
    :optional="optional"
  >
    <p-multi-select
      v-model="model"
      style="width: 100%"
      :class="{ 'p-invalid is-invalid': showError }"
      v-bind="$attrs"
      :input-id="innerId"
      @change="onChange"
      @blur="onBlur"
    />
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, toRefs, Ref, watch } from "vue";
import PMultiSelect from "primevue/multiselect";
import { useField, Validators } from "@/utilities/validations";
import { computedModel } from "@/utilities";

type ModelValue = unknown[];

interface Props {
  name: string;
  id?: string;
  modelValue: ModelValue;

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;

  // Validation Props
  rules?: Validators<ModelValue>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  rules: () => [],
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();

const innerId = computed(() => props.id || props.name);

const { modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const rules = toRefs(props).rules as Ref<Validators<ModelValue>>;
const { errorMessage, touched, validate, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

watch(rules, () => {
  validate();
});

const onChange = () => {
  touched.value = true;
  validate();
};

const onBlur = () => {
  touched.value = true;
};
</script>
