<template>
  <Dialog
    v-model:visible="visible"
    class="view-group-dialog"
    :modal="true"
    :closable="false"
  >
    <template #header>
      <roommate-group-header
        v-if="roommateGroup"
        :is-invite="true"
        v-bind="roommateGroup"
      />
    </template>
    <template #default>
      <roommate-group-member-list
        v-if="roommateGroup"
        style="margin: -1rem"
        type="invited"
        v-bind="roommateGroup"
      />
    </template>
    <template #footer>
      <div style="text-align: left">
        <button
          class="btn btn-primary"
          @click="$emit('accept-invitation',roommateGroup!.roommateGroupCode)"
        >
          Accept
        </button>
        <button
          class="btn btn-secondary"
          @click="$emit('decline-invitation',roommateGroup!.roommateGroupCode)"
        >
          Decline
        </button>
        <button class="btn btn-secondary" @click="visible = false">
          Close
        </button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import { computed } from "vue";

import { RoommateGroupType } from "@/views/RoommateGroups/New/types";

import RoommateGroupHeader from "../../RoommateGroupHeader.vue";
import RoommateGroupMemberList from "../../RoommateGroupMemberList.vue";

interface Props {
  modelValue: boolean;
  roommateGroup: RoommateGroupType | null;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:model-value", payload: boolean): void;
  (e: "accept-invitation", value: string): void;
  (e: "decline-invitation", value: string): void;
}

const emit = defineEmits<Emits>();

const visible = computed({
  get() {
    return props.modelValue;
  },
  set(v: boolean) {
    emit("update:model-value", v);
  },
});
</script>

<style scoped lang="scss"></style>
