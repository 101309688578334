<template>
  <transition
    name="collapse"
    @enter="onEnter"
    @after-enter="onAfterEnter"
    @leave="onLeave"
  >
    <slot />
  </transition>
</template>

<script setup lang="ts">
const onEnter = async (el: HTMLElement) => {
  el.style.height = `0px`;
  requestAnimationFrame(() => {
    el.style.height = `${el.scrollHeight}px`;
  });
};

const onAfterEnter = (el: HTMLElement) => {
  el.style.height = "";
};

const onLeave = async (el: HTMLElement) => {
  el.style.height = `${el.scrollHeight}px`;

  requestAnimationFrame(() => {
    el.style.height = `0px`;
  });
};
</script>

<style lang="scss" scoped>
.collapse {
  &-enter-active,
  &-leave-active {
    transition: height 0.35s ease;
    overflow: hidden;
  }

  &-enter-from {
    position: absolute;
    visibility: hidden;
  }
}
</style>
