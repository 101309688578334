<template>
  <Toast :base-z-index="1031" />
  <form @submit.prevent="submit">
    <base-dropdown
      v-if="buildingOptions.length !== 0"
      v-model="selected.building"
      label="Building"
      name="building"
      :disabled="locked"
      :options="buildingOptions"
      option-label="label"
      option-value="value"
      div-class="mb-3"
      div-style="width: fit-content"
    />
    <div v-if="introText" class="intro-html">
      <div v-html="introText"></div>
    </div>
    <div class="d-flex flex-column flex-md-row mb-3">
      <date-selector
        v-model="selected.date"
        class="mb-3 mb-md-0 me-md-3"
        :disabled-dates="disabledDates[selected?.building || 0]"
      />
      <timeslot-selector
        v-model="form.timeslot"
        :timeslots="activeTimeslots"
        class="flex-grow-1"
      />
    </div>
    <note-confirmation
      v-if="activeTimeslots[form.timeslot!]?.note"
      v-model="form.noteConfirmation"
      class=""
      :confirmation-text="activeTimeslots[form.timeslot!]?.note || ''"
    />
    <div class="w-100 mb-3 d-flex">
      <base-submit-button class="btn btn-primary flex-grow-1 flex-md-grow-0" />
    </div>
  </form>
</template>

<script setup lang="ts">
import Toast from "primevue/toast";
import { computed, reactive, watch } from "vue";

import DateSelector from "./components/DateSelector.vue";
import NoteConfirmation from "./components/NoteConfirmation.vue";
import TimeslotSelector from "./components/TimeslotSelector.vue";
import { Timeslot } from "./types";
import { useFromRails } from "./useFromRails";
import { useSubmission } from "./useSubmission";
import { useTimeslotSelector } from "./useTimeslotSelector";

export interface Props {
  timeslots: Record<
    number,
    Record<number, Timeslot & { startDt: string; endDt: string }>
  >;
  buildingOptions: Array<[string, number]>;
  locked?: boolean;
  initialBuilding: number;
  initialDate: string;
  introTexts: Record<number, string | null>;
  type: "New" | "Edit";
  disabledDates: Record<number, { min: string; max: string; dates: string[] }>;

  initialTimeslot?: null | number;
  prevAppointmentId?: null | number;
}

const props = withDefaults(defineProps<Props>(), {
  locked: false,
  initialTimeslot: null,
});

const { timeslots, initialDate } = useFromRails(props);

const { selected, activeTimeslots } = useTimeslotSelector(
  props,
  timeslots,
  initialDate
);

const introText = computed(() => props.introTexts[selected.building]);

watch(
  () => selected.date,
  () => (form.timeslot = null)
);

const form = reactive({
  prevAppointmentId: props.prevAppointmentId,
  timeslot: props.initialTimeslot,
  noteConfirmation: false,
});

const { submit } = useSubmission(props, form, activeTimeslots);
</script>
