<template>
  <toast :base-z-index="1031" />
  <h1>{{ formOptions.applicationType.description }}</h1>
  <h2 class="text-muted">{{ formOptions.term.name }}</h2>

  <form class="application-form" @submit.prevent="submit">
    <p
      v-if="formOptions.applicationType.headerText"
      v-html="formOptions.applicationType.headerText"
    ></p>
    <div class="row">
      <div class="col-12 mb-4">
        <student-info-form
          class="mb-4"
          :rules="rules"
          :form-name="formName"
          :form-fields="studentInfoFields"
        />
        <application-address
          class="mb-4"
          :rules="rules"
          :form-name="formName"
          :form-fields="addressFields"
        />
        <emergency-contact-info-form
          class="mb-4"
          :rules="rules"
          :form-name="formName"
          :form-fields="emergencyContactFields"
        />
        <about-me-form
          class="mb-4"
          :rules="rules"
          :form-options="formOptions"
          :form-name="formName"
          :form-fields="aboutMeFields"
        />
        <room-preference
          :rules="rules"
          :form-name="formName"
          :form-fields="roomPreferenceFields"
        />
      </div>
      <p
        v-if="formOptions.applicationType.footerText"
        v-html="formOptions.applicationType.footerText"
      ></p>
      <div class="col-12 mb-4 d-flex justify-content-md-center">
        <base-submit-button
          data-testid="application-submit-button"
          :loading="loading"
          class="btn btn-primary btn-lg flex-grow-1 flex-md-grow-0"
        />
      </div>
    </div>
  </form>
</template>

<script lang="ts">
export default {
  name: "ApplicationForm",
};
</script>

<script setup lang="ts">
import Toast from "primevue/toast";
import { provide, reactive } from "vue";

import { fromRailsDateTime } from "@/utilities";

import AboutMeForm from "./components/AboutMeForm.vue";
import ApplicationAddress from "./components/Address.vue";
import EmergencyContactInfoForm from "./components/EmergencyContactInfoForm.vue";
import RoomPreference from "./components/RoomPreference.vue";
import StudentInfoForm from "./components/StudentInfoForm.vue";
import { Application, FormOptions } from "./types";
import { useFieldGenerator } from "./useFieldGenerator";
import { useSubmission } from "./useSubmission";
import { useValidations } from "./useValidations";

export interface Props {
  studentId: number;
  prevApplication: Application;
  formOptions: FormOptions;
  formType: "new" | "edit";
}

const props = defineProps<Props>();

const form = reactive({
  ...props.prevApplication,
  dateOfBirth: props.prevApplication.dateOfBirth
    ? fromRailsDateTime(props.prevApplication.dateOfBirth).toFormat(
        "MM/dd/yyyy"
      )
    : "",
  arrivalDt: props.prevApplication.arrivalDt
    ? fromRailsDateTime(props.prevApplication.arrivalDt as unknown as string)
    : null,
  hasTransport:
    props.prevApplication.hasTransport === null
      ? false
      : props.prevApplication.hasTransport,
});

provide("form", form);

const { rules } = useValidations(
  props.formOptions.fieldSpecs,
  props.formOptions.applicationType.hasPriorities
);

const {
  studentInfoFields,
  addressFields,
  aboutMeFields,
  roomPreferenceFields,
  emergencyContactFields,
} = useFieldGenerator(form, props.formOptions);

const { submit, loading, formName } = useSubmission(form, props);
</script>
