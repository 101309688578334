<template>
  <card-to-mobile-layout title="Student Info">
    <div class="row d-md-grid">
      <template v-for="(field, i) in formFields" :key="field.attrs.name">
        <component
          :is="field.comp"
          :model-value="form[field.attrs.name]"
          v-bind="field.attrs"
          :rules="field.rules"
          :form-name="formName"
          :div-style="{
            'grid-column': i < formFields.length / 2 ? 1 : 2,
            'grid-row': (i % (formFields.length / 2)) + 1,
          }"
          @update:model-value="form[field.attrs.name] = $event"
        />
      </template>
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { inject, UnwrapRef } from "vue";

import { Application } from "../types";
import { GeneratedField } from "../useFieldGenerator";
import { useValidations } from "../useValidations";

interface Props {
  rules: UnwrapRef<ReturnType<typeof useValidations>["rules"]>;
  formName: string;
  formFields: GeneratedField[];
}

defineProps<Props>();

const form = inject("form") as Application;
</script>

<style lang="scss" scoped>
.row {
  grid-template-columns: 50% 50%;
  gap: 1rem 0;
}
</style>
