<template>
  <tr :class="{ 'table-danger': hasErrors }">
    <td>{{ modelValue.itemName }}</td>
    <td>
      <state-radio v-model="inState" :name="`${name}.inState`" />
    </td>
    <td>
      <input
        v-model="itemNotes"
        class="form-control"
        style="width: 100%"
        :name="`${name}.itemNotes`"
        :disabled="inState !== 'Problem'"
      />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { computed, Ref, ref, toRefs, watch } from "vue";
import { mixed, string } from "yup";

import { computedModels } from "@/utilities";
import { useField } from "@/utilities/validations";
import { Validators } from "@/utilities/validations";

import { InspectionDefault } from "../types";
import StateRadio from "./StateRadio.vue";

interface Props {
  modelValue: InspectionDefault;
  name: string;
  formName: string;
}

const props = defineProps<Props>();

interface Emits {
  (e: "update:modelValue", payload: InspectionDefault): void;
}

const emit = defineEmits<Emits>();

const { modelValue } = toRefs(props);
const { itemNotes, inState } = computedModels(modelValue, emit);

const itemNotesField = useField(
  props.name + ".itemNotes",
  itemNotes,
  computed(() =>
    inState.value === "Problem"
      ? string().nullable().required()
      : string().nullable()
  ),
  { formName: props.formName }
);

const inStateField = useField(
  props.name + ".inState",
  inState,
  ref(mixed().nullable().oneOf(["Good", "Problem"]).required()) as Ref<
    Validators<string | null>
  >,
  { formName: props.formName }
);

const hasErrors = computed(
  () => inStateField.errorMessage.value || itemNotesField.errorMessage.value
);

watch(itemNotes, () => {
  itemNotesField.validate();
});

watch(inState, (v) => {
  inStateField.validate();
  if (v === "Good") {
    itemNotes.value = null;
    itemNotesField.validate();
  }
});
</script>

<style scoped lang="scss">
.checkbox-icon {
  height: 2rem;
  width: 2rem;
  transition: 300ms;

  &.good-icon {
    &.active {
      color: green;
    }
  }
  &.bad-icon {
    &.active {
      color: red;
    }
  }
}

td {
  vertical-align: middle;
}
</style>
