import axios from "axios";
import { useToast } from "primevue/usetoast";
import { ComputedRef } from "vue";

import { moveAppointments } from "@/api";

import { Props } from "./Index.vue";
import { Timeslot } from "./types";

export function useSubmission(
  props: Props,
  form: {
    prevAppointmentId: number | null | undefined;
    timeslot: number | null;
    noteConfirmation: boolean;
  },
  activeTimeslots: ComputedRef<Record<number, Timeslot>>
) {
  const toast = useToast();

  const submit = async () => {
    if (form.timeslot == null) {
      toast.add({
        severity: "error",
        detail: "You must select a time slot",
      });
      return;
    }

    if (
      activeTimeslots.value[form.timeslot].note != null &&
      !form.noteConfirmation
    ) {
      toast.add({
        severity: "error",
        detail: "You must agree to the terms below",
      });
      return;
    }

    try {
      const res =
        props.type === "New"
          ? await moveAppointments.create({
              data: {
                move_appointment: {
                  move_timeslot_id: form.timeslot,
                },
              },
            })
          : await moveAppointments.update({
              params: { id: form.prevAppointmentId },
              data: {
                move_appointment: {
                  move_timeslot_id: form.timeslot,
                },
              },
            });

      window.location.assign(res.location);
    } catch (e) {
      if (axios.isAxiosError(e) && e.response) {
        window.location = e.response.data.location;
      }
    }
  };

  return { submit };
}
