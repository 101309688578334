import { Ref } from "vue";

import apiModule from "@/api";
import { ApiInjectionKey, safeInject } from "@/utilities";
import { EmergencyContact } from "@/views/ContactInfo/components/ContactInfoForm";

import { MoveInWizardForm, Step } from "./types";

export function useFetchData(
  studentId: number,
  form: Ref<MoveInWizardForm>,
  steps: Step[],
  loading: { general: boolean; initial: boolean },
  currentIndex: Ref<number>
) {
  const api = safeInject(ApiInjectionKey);

  const fetchData = async (step: Step) => {
    switch (step.name) {
      case "Contact": {
        form.value = await fetchContactData(api, studentId);
        break;
      }
      case "MoveIn": {
        const data = await api.moveInWizard.new();

        form.value = { ...data, signature: null };
        break;
      }
    }
  };

  const fetchCurrentStep = async () => {
    try {
      const data = await api.moveInWizard.currentStep();
      let finished = false;
      let i = 0;
      while (!finished && i < steps.length) {
        if (data === steps[i].name) {
          finished = true;
          currentIndex.value = i;
          await fetchData(steps[i]);
        } else {
          steps[i].completed = true;
        }
        i += 1;
      }
    } finally {
      loading.initial = false;
    }
  };

  return { fetchData, fetchCurrentStep };
}

function defaultContact(): EmergencyContact {
  return {
    contactName: "",
    contactRelation: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postal: "",
    phone1Type: null,
    phone1: "",
    phone2Type: null,
    phone2: "",
    phone3Type: null,
    phone3: "",
  };
}

async function fetchContactData(api: typeof apiModule, studentId: number) {
  const [studentInfo, contactInfo] = await Promise.all([
    api.students.edit({ id: studentId }),
    api.contactInfo.edit({ id: studentId }),
  ]);

  const data = { ...studentInfo, ...contactInfo };

  if (data.contact1 == null) {
    data.contact1 = defaultContact();
  }

  if (data.contact2 == null) {
    data.contact2 = defaultContact();
  }

  return data;
}
