import { Ref, watch } from "vue";

import type { HolidayLeave, Home, Other } from "./types/holidayLeave";

export function useReset(
  form: Ref<HolidayLeave>,
  holidayLeaveId: number,
  resetForm: () => void
) {
  watch(
    () => form.value.holidayPlans,
    async (val, prev) => {
      switch (val) {
        case "S":
          form.value = { holidayPlans: "S", holidayLeaveId };
          break;
        case "H":
          form.value = {
            holidayPlans: "H",

            holidayLeaveId,
            ...(prev === "E"
              ? {
                  leaveReturn: (form.value as Other).leaveReturn,
                  leaveDepart: (form.value as Other).leaveDepart,
                }
              : {}),
          } as unknown as Home;
          break;
        case "E":
          form.value = {
            holidayPlans: "E",
            holidayLeaveId,
            ...(prev === "H"
              ? {
                  leaveReturn: (form.value as Home).leaveReturn,
                  leaveDepart: (form.value as Home).leaveDepart,
                }
              : {}),
            destination: "",
            hostRelationship: "",
            address: "",
            city: "",
            state: null,
            postal: "",
            transport: "",
            driver: "",
            carOwner: "",
            companions: "",
            hostphone: "",
            cellphone: "",
            ccParent: "",
            reason: "",
            otherphone: "",
          } as unknown as Other;
          break;
      }

      resetForm();
    }
  );
}
