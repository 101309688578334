<template>
  <span
    @click="onClick"
    @mouseover="mouseover($event)"
    @mouseleave="mouseleave($event)"
  >
    <span
      style="cursor: pointer"
      class="link-primary text-decoration-underline"
    >
      {{ code }}
    </span>
  </span>
  <Dialog v-model:visible="dialogState" :modal="true" :show-header="false">
    <tooltip-text :code="code" />
    <template #footer>
      <div style="text-align: left">
        <button
          class="btn btn-primary"
          @click="
            copyToClipboard();
            dialogState = false;
          "
        >
          Copy Code
        </button>
        <button class="btn btn-secondary" @click="dialogState = false">
          Close
        </button>
      </div>
    </template>
  </Dialog>
  <OverlayPanel ref="op" append-to="body">
    <tooltip-text :code="code" />
  </OverlayPanel>
</template>
<script setup lang="ts">
import Dialog from "primevue/dialog";
import OverlayPanel from "primevue/overlaypanel";
import { useToast } from "primevue/usetoast";
import { ref } from "vue";

import { copyToClipboard as copyToClipboardFn } from "@/utilities";
import { useBreakpoints } from "@/utilities/breakpoints";

import TooltipText from "./components/TooltipText.vue";

interface Props {
  code: string;
}

const breakpoints = useBreakpoints();

const props = defineProps<Props>();

const toast = useToast();

const dialogState = ref(false);

const op = ref();
const overlay = {
  open(event: unknown) {
    op.value.show(event);
  },
  close(event: unknown) {
    op.value.hide(event);
  },
};

const copyToClipboard = async () => {
  await copyToClipboardFn(props.code);
  toast.add({
    summary: "Roommate code copied to your clipboard",
    severity: "info",
    life: 1000,
  });
};

const onClick = (event: unknown) => {
  if (breakpoints.xs.value) {
    dialogState.value = true;

    return;
  }

  overlay.close(event);
  copyToClipboard();
};

const mouseover = (event: unknown) => {
  if (breakpoints.xs.value) {
    return;
  }
  overlay.open(event);
};

const mouseleave = (event: unknown) => {
  if (breakpoints.xs.value) {
    return;
  }
  overlay.close(event);
};
</script>
