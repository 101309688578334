import Konva from "konva";
import { Ref, watch } from "vue";

import { AbodeFloorDiagram, AbodeFloorDiagramRoomItem } from "./types";

// Point relative to the page
interface PagePoint {
  kind: "Page";
  x: number;
  y: number;
}

// Point relative to the Stage
interface StagePoint {
  kind: "Stage";
  x: number;
  y: number;
}

// Point relative to the Canvas
interface CanvasPoint {
  kind: "Canvas";
  x: number;
  y: number;
}

type Point = PagePoint | CanvasPoint | StagePoint;

function toLocalCoors(p: CanvasPoint, stage: Konva.Stage): StagePoint {
  return {
    kind: "Stage",
    x: (p.x - stage.x()) / stage.scaleX(),
    y: (p.y - stage.y()) / stage.scaleX(),
  };
}

export function zoom(stage: Konva.Stage, direction: 1 | -1) {
  const scaleBy = 1.1;
  const oldScale = stage.scaleX();

  const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

  const center: CanvasPoint = {
    kind: "Canvas",
    x: stage.width() / 2,
    y: stage.height() / 2,
  };

  const mousePointTo = toLocalCoors(center, stage);
  stage.scale({ x: newScale, y: newScale });

  const newPos = {
    x: center.x - mousePointTo.x * newScale,
    y: center.y - mousePointTo.y * newScale,
  };
  stage.position(newPos);
  stage.batchDraw();
}

export function pinchZoom(stage: Konva.Stage) {
  Konva.hitOnDragEnabled = true;

  function getDistance(p1: Point, p2: Point) {
    return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
  }

  function getCenter(p1: Point, p2: Point) {
    return {
      x: (p1.x + p2.x) / 2,
      y: (p1.y + p2.y) / 2,
    };
  }

  let center: CanvasPoint | null = null;
  let lastDist = 0;

  stage.on("touchmove", function (e) {
    e.evt.preventDefault();
    const touch1 = e.evt.touches[0];
    const touch2 = e.evt.touches[1];

    if (!(touch1 && touch2)) {
      return;
    }

    stage.draggable(false);

    const p1: PagePoint = {
      kind: "Page",
      x: touch1.clientX,
      y: touch1.clientY,
    };

    const p2: PagePoint = {
      kind: "Page",
      x: touch2.clientX,
      y: touch2.clientY,
    };

    if (!center) {
      const centerCoors = getCenter(p1, p2);
      center = {
        kind: "Canvas",
        x: centerCoors.x - stage.container().getBoundingClientRect().x,
        y: centerCoors.y - stage.container().getBoundingClientRect().y,
      };
      return;
    }

    const dist = getDistance(p1, p2);

    if (!lastDist) {
      lastDist = dist;
    }

    const pointTo = toLocalCoors(center, stage);

    const scale = stage.scaleX() * (dist / lastDist);

    stage.scale({ x: scale, y: scale });

    const newPos = {
      x: center.x - pointTo.x * scale,
      y: center.y - pointTo.y * scale,
    };

    stage.position(newPos);
    stage.batchDraw();

    lastDist = dist;
  });

  stage.on("touchend", function () {
    lastDist = 0;
    center = null;
    stage.draggable(true);
  });
}

function addScaling(stage: Konva.Stage) {
  const scaleBy = 1.1;
  stage.on("wheel", (e) => {
    e.evt.preventDefault();

    const pointer = {
      ...stage.getPointerPosition(),
      kind: "Canvas",
    } as CanvasPoint;

    if (pointer == null) {
      return;
    }

    const mousePointTo = toLocalCoors(pointer, stage);

    const oldScale = stage.scaleX();
    const newScale = e.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;

    stage.scale({ x: newScale, y: newScale });

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };
    stage.position(newPos);
    stage.batchDraw();
  });

  return stage;
}

function fitToParent(
  stage: Konva.Stage,
  ele: HTMLDivElement,
  diagram: AbodeFloorDiagram
) {
  function fitStageIntoParentContainer() {
    const vWidth =
      diagram.width > diagram.height ? diagram.width : diagram.height;
    const vHeight = vWidth;
    const container = ele;

    const containerWidth = container.offsetWidth;
    const scale = containerWidth / vWidth;

    stage.width(vWidth * scale);
    stage.height(vHeight * scale);
    stage.scale({ x: scale, y: scale });
    stage.draw();
  }

  fitStageIntoParentContainer();
  window.addEventListener("resize", fitStageIntoParentContainer);
}

export function stageFromDiagram(
  ele: HTMLDivElement,
  diagram: AbodeFloorDiagram
) {
  const stage = new Konva.Stage({
    container: ele,
    width: diagram.width,
    height: diagram.height,
    draggable: true,
  });

  pinchZoom(stage);
  addScaling(stage);
  fitToParent(stage, ele, diagram);

  return stage;
}

export function diagramLayer(diagram: AbodeFloorDiagram) {
  const layer = new Konva.Layer();
  const imageObj = new Image();
  imageObj.onload = function () {
    layer.add(
      new Konva.Image({
        x: 0,
        y: 0,
        image: imageObj,
        width: diagram.width,
        height: diagram.height,
      })
    );
    layer.batchDraw();
  };
  imageObj.src = diagram.backgroundImage;
  return layer;
}

const baseColor = "#b0e7c4";
const hoverColor = "#bfbfbf";
const selectedColor = "#184f2c";
const lockedColor = "#e87b86";

const roomGroup =
  (layer: Konva.Layer, selected: Ref<number | null>) =>
  (room: AbodeFloorDiagramRoomItem) => {
    const group = new Konva.Group({
      x: room.x,
      y: room.y,
      width: room.width,
      height: room.height,
    });

    const rect = new Konva.Rect({
      width: room.width,
      height: room.height,
      stroke: "black",
      fill: room.locked ? lockedColor : baseColor,
      strokeWidth: 3,
    });

    group.on("mouseenter", function () {
      if (selected.value === room.roomId || room.locked) {
        return;
      }

      rect.fill(hoverColor);
      layer.draw();
    });

    group.on("mouseleave", function () {
      if (selected.value === room.roomId || room.locked) {
        return;
      }
      rect.fill(baseColor);
      layer.draw();
    });

    const onSelect = function (this: Konva.Group) {
      if (room.locked) return;
      selected.value = room.roomId;
    };

    group.on("click", onSelect);
    group.on("tap", onSelect);

    const labelText = room.locked ? `${room.roomName}` : room.roomName;
    const text = new Konva.Text({
      text: labelText,
      x: room.width / 2,
      y: room.height / 2,
      fontSize: 30,
      fill: room.locked ? "white" : "black",
    });
    text.offsetX(text.width() / 2);
    text.offsetY(text.height() / 2);

    group.add(rect);

    group.add(text);

    return { group, room };
  };

export function roomsLayer(
  diagram: AbodeFloorDiagram,
  selected: Ref<number | null>
) {
  const layer = new Konva.Layer();
  const groups = diagram.roomItems.map(roomGroup(layer, selected));

  const updateGroup = (
    group: Konva.Group,
    room: AbodeFloorDiagramRoomItem,
    id: number | null
  ) => {
    const rect = group.findOne("Rect") as Konva.Rect;
    const text = group.findOne("Text") as Konva.Text;
    if (room.roomId === id) {
      rect.fill(selectedColor);
      text.fill("white");
    } else {
      rect.fill(baseColor);
      text.fill("black");
    }
  };

  const endWatch = watch(
    selected,
    (next: number | null, prev: number | null) => {
      const nextGroup = groups.find((x) => x.room.roomId === next);
      const prevGroup = groups.find((x) => x.room.roomId === prev);

      if (nextGroup != null) {
        updateGroup(nextGroup.group, nextGroup.room, next);
      }
      if (prevGroup != null) {
        updateGroup(prevGroup.group, prevGroup.room, next);
      }
    }
  );

  groups.forEach((r) => layer.add(r.group));
  return { layer, endWatch };
}
