<template>
  <div class="checks-list list-group list-group-flush d-md-none">
    <checks-list-row
      v-for="check in checks"
      :show-charges="showCharges"
      :class="rowClass(check)"
      :check="check"
    />
  </div>
</template>

<script setup lang="ts">
import { Check } from "../types";
import ChecksListRow from "./ChecksListRow.vue";

interface Props {
  showCharges: boolean;
  checks: Check[];
}

defineProps<Props>();

const rowClass = (check: Check) => {
  switch (check.type) {
    case "no_contact":
    case "no_return":
      return "list-group-item-danger";

    case "holiday_leave":
    case "nightly_leave":
    case "late_leave":
      return "list-group-item-success";

    case "violation":
      return "list-group-item-warning";

    case "excused_no_contact":
    case "excused_violation":
    case "excused_no_return":
      return "list-group-item-info";

    default:
      null;
  }
};
</script>

<style lang="scss">
:deep(.checks-list__row) {
  &:not(.collapsed) .checks-list__row__icon {
    transform: rotate(-90deg);
  }

  .checks-list__row__icon {
    transition: var(--bs-accordion-transition);
  }
}
</style>
