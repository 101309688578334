import axios from "axios";
import { camelCase } from "lodash";

import { transformKeys } from "@/utilities";

const snakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter: string) => `_${letter.toLowerCase()}`);

const toCamelCase = (str: string) =>
  str.replace(/_\w/, (s) => s[1].toUpperCase());

export const http = axios.create({
  headers: {
    "X-CSRF-TOKEN": document
      ?.querySelector('meta[name="csrf-token"]')
      ?.getAttribute("content") as string,
    "content-type": "application/json",
  },
});

http.interceptors.request.use((request) => {
  if (typeof request.data === "object" && !Array.isArray(request.data)) {
    request.data = transformKeys(request.data, snakeCase);
  }
  return request;
});

http.interceptors.response.use(
  (response) => {
    if (typeof response.data === "object" && !Array.isArray(response.data)) {
      response.data = transformKeys(response.data, camelCase);
    }
    return response;
  },
  (error) => {
    if (
      typeof error.response.data === "object" &&
      !Array.isArray(error.response.data)
    ) {
      error.response.data = transformKeys(error.response.data, toCamelCase);
    }
    return Promise.reject(error);
  }
);
