import { createPinia } from "pinia";
import { useToast } from "primevue/usetoast";
import { markRaw } from "vue";

import api from "@/api";

export const pinia = createPinia();
pinia.use(({ store }) => {
  store.toast = markRaw(useToast());
  store.api = api;
});
