<template>
  <card-to-mobile-layout v-if="formFields.length" title="Room Preferences">
    <div class="row">
      <template v-for="field in formFields" :key="field.attrs.name">
        <component
          :is="field.comp"
          :model-value="form[field.attrs.name]"
          v-bind="field.attrs"
          :rules="field.rules"
          :form-name="formName"
          @update:model-value="form[field.attrs.name] = $event"
        />
      </template>
    </div>
  </card-to-mobile-layout>
</template>

<script setup lang="ts">
import { inject, UnwrapRef } from "vue";

import { Application } from "../types";
import { GeneratedField } from "../useFieldGenerator";
import { useValidations } from "../useValidations";

interface Props {
  rules: UnwrapRef<ReturnType<typeof useValidations>["rules"]>;
  formName: string;
  formFields: GeneratedField[];
}

defineProps<Props>();
const form = inject("form") as Application;
</script>
