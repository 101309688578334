import { computed, Ref } from "vue";
import { mixed, string, StringSchema } from "yup";

import { phoneNumber, schoolEmail } from "@/utilities/validators";

import { MemberInviteForm } from "../../../../types";

export function useValidations(
  form: Ref<MemberInviteForm>,
  searched: Ref<boolean>,
  found: Ref<boolean | null>
) {
  const baseEmailSchema = string().required("Required").email();
  const rules = computed(() => {
    const r = {
      inviteType: mixed().oneOf(["Current", "Transfer", "New"]),
      email:
        form.value.inviteType === "Current"
          ? isFound(schoolEmail(baseEmailSchema), searched, found)
          : baseEmailSchema,
      firstName: string().required("Required"),
      lastName: string().required("Required"),
      phone: phoneNumber(),
    };

    return r;
  });

  return { rules };
}

function isFound(
  str: StringSchema<
    string | undefined,
    Record<string, unknown>,
    string | undefined
  >,
  searched: Ref<boolean>,
  found: Ref<boolean | null>
) {
  return str.test("is-found", "Student not found", () => {
    if (searched.value) {
      return found.value === true || found.value === null;
    }

    return true;
  });
}
