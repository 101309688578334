<template>
  <base-field :name="innerId" :class="divClass" :optional="optional">
    <fieldset
      :class="{
        'align-items-start align-items-md-center flex-column flex-md-row': true,
      }"
    >
      <legend class="mb-2 mb-md-0 ms-0 me-md-2">
        {{ label }}
      </legend>
      <div v-bind="$attrs" class="btn-group">
        <template v-for="item in options" :key="item.name">
          <input
            :id="innerId + '_' + item.name"
            v-model="model"
            :name="innerId"
            type="radio"
            :value="item.value"
            class="btn-check"
          />
          <label
            :class="[
              {
                'btn-outline-danger': showError,
                'btn-outline-secondary': !showError,
              },
              'btn',
            ]"
            class="btn-outline"
            :for="innerId + '_' + item.name"
            >{{ item.name }}</label
          >
        </template>
      </div>
    </fieldset>
    <help-text
      :help-text="helpText"
      :error-message="errorMessage"
      :touched="touched"
    />
  </base-field>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { computed, Ref, toRefs, watch } from "vue";

import { computedModel } from "@/utilities";
import { useField, Validators } from "@/utilities/validations";
import HelpText from "./HelpText.vue"
import BaseField from "./BaseField.vue"

type ModelValue = NonNullable<unknown> | null;

interface Props {
  name: string;
  id?: string;
  modelValue: ModelValue;
  options: { name: string; value: unknown }[];

  // Field Props
  label?: string;
  optional?: boolean;
  divClass?: string;

  // Validation Props
  rules?: Validators<unknown>;
  formName?: string;
  helpText?: string;
}

const props = withDefaults(defineProps<Props>(), {
  id: "",
  rules: () => [],
});

const emit =
  defineEmits<{ (e: "update:modelValue", value: ModelValue): void }>();

const innerId = computed(() => props.id || props.name);

const { modelValue } = toRefs(props);
const model = computedModel(modelValue, emit);

const rules = toRefs(props).rules as Ref<Validators<ModelValue>>;
const { validate, touched, errorMessage, showError } = useField(
  props.name,
  model,
  rules,
  {
    formName: props.formName,
  }
);

watch(model, () => {
  validate();
});
</script>

<style lang="scss" scoped>
fieldset {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

legend {
  margin-bottom: 0;
  width: unset;
  display: inline-block;
  font-size: unset;
}
</style>
