<template>
  <tr
    v-bind="$attrs"
    class="charge_row"
    :aria-expanded="!collapsed"
    @click="collapsed = !collapsed"
  >
    <th scope="row" class="more_info_column">
      <i class="bi bi-chevron-right"></i>
    </th>
    <td class="charge_date_column">
      {{ value.chargeDate }}
    </td>
    <td class="charge_type_column">
      {{ value.chargeType }}
    </td>
    <td class="amount_column">
      {{ filters.dollars(value.amount) }}
    </td>
  </tr>
  <tr>
    <td class="border-0 p-0" colspan="4">
      <collapse-transition>
        <div
          v-if="!collapsed"
          :class="{
            'charge-details container-fluid': true,
            collapsed: collapsed,
          }"
        >
          <dl class="mb-0 row py-2 border-bottom">
            <template v-for="[desc, value] in details">
              <template v-if="value">
                <dt class="col-md-3">{{ desc }}</dt>
                <dd class="col-md-9">{{ value }}</dd>
              </template>
            </template>
          </dl>
        </div>
      </collapse-transition>
    </td>
  </tr>
</template>

<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import { ref } from "vue";

import { filters } from "@/utilities";

import { Charge } from "../types";

interface Props {
  value: Charge;
}

const maybeApply = <T, V>(fn: (arg: T) => V, value: T | null | undefined) =>
  value != null ? fn(value) : value;

const props = defineProps<Props>();
const details = [
  ["Description", props.value.description],
  ["Checked By", props.value.checkedBy],
  ["Cash Paid", maybeApply(filters.dollars, props.value.cashPaid)],
  ["Cash Paid Date", props.value.cashPaidDate],
  ["Account Charged", maybeApply(filters.dollars, props.value.accountPaid)],
  ["Account Charge Date", props.value.appliedDate],
  ["Charge Submitted Date", props.value.submittedDate],
];

const collapsed = ref(true);
</script>

<style lang="scss" scoped>
.bi-chevron-right::before {
  transition: 200ms;
}

tr[aria-expanded="true"] .bi-chevron-right::before {
  transform: rotate(90deg);
}

.charge_date_column {
  width: 5em;
}

.charge_type_column {
  width: 15em;
}

.more_info_column {
  width: 40px;
  text-align: center;
  vertical-align: middle;
}
</style>
