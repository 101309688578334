<template>
  <div class="timeslot-selector">
    <h3 class="d-flex">
      <b-icon-clock class="text-primary" />
      <span class="ms-2">Select Time</span>
    </h3>
    <div class="timeslot-selector__grid w-100">
      <div
        v-for="timeslot in timeslots"
        :key="timeslot.id"
        :class="[
          'btn',
          model === timeslot.id ? 'btn-primary' : 'btn-secondary',
        ]"
        @click="model = timeslot.id"
      >
        {{ formatTimeslot(timeslot) }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BIconClock } from "bootstrap-icons-vue";
import { DateTime } from "luxon";
import { toRefs } from "vue";

import { computedModel } from "@/utilities";

import { Timeslot } from "../types";

type Model = number | null;

interface Props {
  modelValue: Model;
  timeslots: Record<number, Timeslot & { startDt: DateTime; endDt: DateTime }>;
}

interface Emits {
  (e: "update:modelValue", value: Model): void;
}

const props = defineProps<Props>();

const emit = defineEmits<Emits>();

const modelValue = toRefs(props).modelValue;
const model = computedModel(modelValue, emit);

const formatTimeslot = (
  timeslot: Timeslot & { startDt: DateTime; endDt: DateTime }
) => {
  const start = timeslot.startDt.toFormat("hh:mm a");
  const end = timeslot.endDt.toFormat("hh:mm a");

  return `${start} - ${end}`;
};
</script>

<style scoped lang="scss">
.timeslot-selector__grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20ch, 1fr));
  gap: 0.5rem 0.5rem;
}
</style>
