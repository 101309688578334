import { BaseSchema, StringSchema, string } from "yup";
import { intersection, transform } from "lodash";
import { config, School } from "@/config";

export const phoneNumber = () =>
  string()
    .nullable()
    .matches(/^\+?[\d\s()-]+$/, {
      message:
        "Must be a phone number in the form of +12222425, (404) 888-9999, etc",
      excludeEmptyString: true,
    });

export const isDisjoint = <T>(setA?: Array<T>, setB?: Array<T>) => {
  if (setA == null || setB == null) {
    return true;
  }

  if (!setA.length || !setB.length) {
    return true;
  }

  return intersection(setA, setB).length === 0;
};

export function groupRequireIf<T, K extends Record<string, BaseSchema>>(
  field: string,
  condition: ((value: T) => boolean) | T,
  obj: K
): K {
  const res: Record<string, BaseSchema> = {};

  Object.entries(obj).forEach(([key, value]) => {
    res[key] = value.when(field, {
      is: condition,
      then: value.required("field required"),
      otherwise: value,
    });
  });

  return res as K;
}

export function groupWhen<T extends Record<string, BaseSchema>>(
  key: string,
  condition: (x: unknown) => boolean,
  schemas: T
): T {
  return transform<T, T>(
    schemas,
    (acc, value, s_key) => {
      acc[s_key] = value.when(key, (value, schema) =>
        condition(value) ? schema.required("required field") : schema
      );
      return acc;
    },
    {} as T
  );
}

const schoolEmailFormats: Record<School, { format: RegExp; message: string }> =
  {
    bur: {
      format: /[\d\w]+@burmanu.ca$/,
      message:
        "Must be a valid Burman University email address: etc sarah@burmanu.ca",
    },
    sau: {
      format: /[\d\w]+@southern.edu$/,
      message:
        "Must be a valid Southern University email address: etc sarah@southern.edu",
    },
  };

export function schoolEmail(
  str: StringSchema<
    string | undefined,
    Record<string, unknown>,
    string | undefined
  >
) {
  return str.matches(
    schoolEmailFormats[config.school].format,
    schoolEmailFormats[config.school].message
  );
}
