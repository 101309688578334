<template>
  <toast />
  <prior-request-dialog
    v-model:visible="recentRequest"
    @cancel="recentRequest = false"
    @confirm="
      recentRequest = false;
      dialogs.newForm = true;
    "
  />
  <template v-if="hasRoom">
    <maintenance-form-dialog
      v-model="dialogs.newForm"
      :categories="categories"
      :building="building"
      :room="room || undefined"
      @submit="submit"
    />

    <button
      :class="[{ 'mb-3': requests.length }, 'btn btn-primary ']"
      @click="newRequest"
    >
      Create Room Maintenance Request
    </button>

    <template v-if="requests.length">
      <maintenance-card
        v-for="request in requests"
        :key="request.id"
        class="mb-3"
        :request="request"
      />
    </template>
    <p v-else class="no_results">
      No maintenance requests found for this student
    </p>
  </template>

  <p v-else class="no_results">
    You cannot fill out a room maintenance request until you are assigned to a
    room.
  </p>
</template>

<script lang="ts">
export default {
  name: "Maintenance",
};
</script>

<script setup lang="ts">
import { reactive, ref, Ref } from "vue";

import PriorRequestDialog from "./components/PriorRequestDiaglog.vue";
import MaintenanceFormDialog from "./components/MaintenanceFormDialog.vue";
import MaintenanceCard from "./components/MaintenanceCard.vue";
import { MaintenanceRequest, MaintenanceRequestCategory } from "./types";
import Toast from "primevue/toast";
import { useSubmission } from "./useSubmission";

export interface Props {
  hasRoom: boolean;
  hasRecentRequest: boolean;
  building: string;
  room?: string | null;
  categories?: MaintenanceRequestCategory[];
  requests: MaintenanceRequest[];
}

const props = withDefaults(defineProps<Props>(), {
  room: null,
  categories: () => [],
  requests: () => [],
});

const recentRequest = ref(false);
const requests: Ref<MaintenanceRequest[]> = ref(props.requests);
const hasRecentRequest = ref(props.hasRecentRequest);

const dialogs = reactive({
  recentRequest: false,
  newForm: false,
});

const { submit } = useSubmission(hasRecentRequest, requests, dialogs);

const newRequest = () => {
  if (hasRecentRequest.value) {
    recentRequest.value = true;
    return;
  }

  dialogs.newForm = true;
};
</script>
