<template>
  <article class="card">
    <header class="card-header steps">
      <template v-for="step in steps" :key="step.name">
        <div v-if="step.name !== 'Complete'" :class="activeStep(step)">
          <div class="step__icons">
            <div class="step__icons__dash me-3"></div>
            <b-icon-dash-circle
              v-if="!step.completed"
              class="step__icons__icon"
            />
            <b-icon-check-circle
              v-else
              class="step__icons__icon step__icons__icon--complete"
            />
            <div class="step__icons__dash ms-3"></div>
          </div>
          <small>{{ step.infoText }}</small>
        </div>
      </template>
    </header>
    <section class="card-body">
      <div v-show="loading" id="loading-text">
        <h1>Loading...</h1>
      </div>
      <slot />
    </section>
    <footer class="card-footer">
      <button
        form="stepper-form"
        class="btn btn-primary ms-auto"
        @click.prevent="$emit('submit')"
      >
        Next
      </button>
    </footer>
  </article>
</template>

<script setup lang="ts">
import { BIconCheckCircle, BIconDashCircle } from "bootstrap-icons-vue";

import { Step } from "../types";

interface Props {
  loading: boolean;
  steps: Step[];
  currentStep: Step;
}

interface Emits {
  (e: "submit"): void;
}

const props = defineProps<Props>();
defineEmits<Emits>()

const activeStep = (step: Step) => [
  { active: step.name === props.currentStep.name },
  "step",
];
</script>

<style lang="scss" scoped>
$step-inacive-color: #ccc;

.step-icon {
  height: 1.5rem;
  width: 1.5rem;

  &__complete {
    color: green;
  }
}

.card {
  max-height: calc(100vh - 61px - 2rem);
  /* height: 700px; */
  overflow: hidden;
}

.card-body {
  overflow: auto;
}

.card-footer {
  display: flex;
  justify-content: space-between;
}

.steps {
  display: flex;
}

.step {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $step-inacive-color;

  &.active {
    color: black;
  }

  &__icons {
    display: flex;
    width: 100%;

    &__icon {
      height: 1.5rem;
      width: 1.5rem;
      &--complete {
        color: green;
      }
    }

    &__dash {
      content: " ";
      height: 50%;
      border-bottom: 1px solid $step-inacive-color;
      flex: 1;
    }
  }
}

#loading-text {
  color: #bbb;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
