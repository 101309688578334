<template>
  <Toast />
  <stepper
    :current-step="currentStep"
    :loading="loading.initial || loading.general"
    :steps="steps"
    @submit="submit"
  >
    <form
      v-if="!loading.initial && form != null"
      v-show="!loading.general"
      id="stepper-form"
      @submit.prevent="submit"
    >
      <component
        :is="currentStep.component"
        v-model="form"
        :form-name="formName"
        :states="states"
        :email="email"
        :student-name="studentName"
      />
    </form>
  </stepper>
</template>

<script lang="ts">
export default {
  name: "MoveInWizard",
};
</script>

<script setup lang="ts">
import Toast from "primevue/toast";
import { reactive, ref } from "vue";

import Stepper from "./components/Stepper.vue";
import { useFetchData } from "./useFetchData";
import { useSteps } from "./useSteps";
import { useSubmission } from "./useSubmission";

interface Props {
  studentName: string;
  email: string;
  states: { label: string; value: string }[];
  studentId: number;
}

const props = defineProps<Props>();

const loading = reactive({
  general: false,
  initial: true,
});

const form = ref(null);

const { steps, currentIndex, currentStep, next } = useSteps();

const { fetchData, fetchCurrentStep } = useFetchData(
  props.studentId,
  form,
  steps,
  loading,
  currentIndex
);

const { submit, formName } = useSubmission(
  props.studentId,
  form,
  currentStep,
  next,
  loading,
  fetchData
);

fetchCurrentStep();
</script>
