import { DirectiveBinding, inject, InjectionKey } from "vue";

import api from "@/api";

export * as filters from "./filters";
export * from "./model";
export * from "./rails";

export const formatPhone = (phone: string): string =>
  phone != null
    ? phone
        .replace(/[^0-9]/g, "")
        .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")
    : "";

export async function copyToClipboard(textToCopy: string): Promise<void> {
  if (navigator.clipboard) {
    return await navigator.clipboard.writeText(textToCopy);
  }

  let textArea: HTMLTextAreaElement;

  function isOS() {
    //can use a better detection logic here
    return navigator.userAgent.match(/ipad|iphone/i);
  }

  function createTextArea(text: string) {
    textArea = document.createElement("textArea") as HTMLTextAreaElement;
    textArea.readOnly = true;
    textArea.contentEditable = "true";
    textArea.value = text;
    document.body.appendChild(textArea);
  }

  function selectText() {
    let range: Range, selection: Selection | null;

    if (isOS()) {
      range = document.createRange();
      range.selectNodeContents(textArea);
      selection = window.getSelection();
      selection?.removeAllRanges();
      selection?.addRange(range);
      textArea.setSelectionRange(0, 999999);
    } else {
      textArea.select();
    }
  }

  function copyTo() {
    document.execCommand("copy");
    document.body.removeChild(textArea);
  }

  createTextArea(textToCopy);
  selectText();
  copyTo();
}

export function onControllerLoad(selector: string, callback: () => void) {
  window.addEventListener("load", () => {
    const body = document.querySelector(selector);

    if (body) {
      callback();
    }
  });
}

export function safeInject<T>(key: InjectionKey<T>): T {
  const value = inject(key);
  if (value == null) {
    throw `Could not inject ${key.toString()}`;
  }

  return value;
}

export const testDirective = (el: HTMLElement, binding: DirectiveBinding) => {
  if (process.env.NODE_ENV === "test") {
    el.dataset["testid"] = binding.value;
  }
};

export const ApiInjectionKey: InjectionKey<typeof api> =
  Symbol("ApiInjectionKey");

export const maybeApply = <T, V extends NonNullable<T>, R>(
  v: T,
  fn: (v: V) => R
) => (v ? fn(v as V) : v);

export const isEven = (i: number) => i % 2 === 0;
